export default {
    persianBlue: '#2143AB',
    persianBlueRGB: '48,94,236',
    ultramarineBlue: '#305EEC',
    ultramarineBlueRGB: '48,94,236',
    amber: '#FF0000',
    white: '#ffffff',
    whiteRGB: '255,255,255',
    antiflashWhite: '#EDF1F5',
    lavenderGray: '#C1C9D2',
    lavenderGrayRGB: '193,201,210',
    darkElectricBlue: '#5C6E81',
    darkElectricBlueRGB: '92,110,129',
    darkGrey: '#191919',
    darkGrey2: '#4D4C4C',
    electricBlue: '#6AFDEE',
    electricBlueRGB: '106,253,238',
    pastelRed: '#FF5a66',
    pastelRedRGB: '255,90,102',
    carnelian: '#BA1824',
    carnelianRGB: '186,24,36',
    error: '#BA1824',
    info: '#FFC000',
    infoRGB: '255,192,0',
    whiteDark: '#F4F4F5',
    black: '#2F3135',
    grey: '#4B5966',
    greyDark: '#6C6E71',
    greyDarker: '#0F2032',
    greyLight: '#CFDAE6',
    greyLightest: '#F0F4F7',
    regalBlue: '#1D2E4B',
    tAndPink: '#E60073',
    brightPink: '#FF0080',
    midnight: '#16324D',
    midnightLight: '#162F4A',
    errorRGB: '224, 14, 14',
    blue: '#0080FF',
    blueRGB: '0, 128, 255',
    success: '#02FF7F',
    successRGB: '2, 255, 127',
};
