import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import MultiSelect from '../../../../components/multi-select';
import Styles from './styles';

const SitesFilter = ({ onChange, sites, classes }) => {
    const [municipalities, setMunicipalities] = useState([]);

    useEffect(() => {
        const newMunicipalities = new Set();
        sites.forEach((site) => {
            if (site.municipality) {
                newMunicipalities.add(site.municipality);
            }
        });
        setMunicipalities(Array.from(newMunicipalities).sort());
    }, [sites]);

    return (
        <div className={classes.municipalityFilter}>
            <MultiSelect
                label="Filter testers by municipality values"
                defaultValue="All Municipalities"
                options={municipalities}
                onChange={onChange}
            />
        </div>
    );
};

SitesFilter.propTypes = {
    sites: PropTypes.arrayOf(
        PropTypes.shape({
            municipality: PropTypes.string,
        }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(SitesFilter, Styles);
