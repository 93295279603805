import { useLayoutEffect, useState, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Navigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import PublicLayout from '../layouts/public';
import { SentryRoutes } from '../utils/sentry';
import { fetchCurrentUser, logout } from '../redux/auth';
import useSnackbar from '../utils/use-snackbar';
import NotificationSnackbar from '../components/notification-snackbar';
import ProgressSpinner from '../components/progress-spinner';
import getAPIStatus from '../redux/app/selectors';
import { APIReset } from '../redux/app';
import { getIsAuthenticated, getUserColorScheme } from '../redux/auth/selectors';
import WebClient from '../utils/web-client';
import DashboardLayout from '../layouts/dashboard';
import themer from '../styles/material-theme';
import withRoot from '../wiring/with-root';
import ReportRouter from './report/report-router';
import { updateColorScheme, useThemeDispatch, useThemeState } from '../wiring/theme-context';

const App = () => {
    const dispatch = useDispatch();
    const themeDispatch = useThemeDispatch();
    const themeOverride = useThemeState();
    const isAuthenticated = useSelector(getIsAuthenticated);
    const apiStatus = useSelector(getAPIStatus);
    const userColorScheme = useSelector(getUserColorScheme);
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();
    const [themeLoaded, setThemeLoaded] = useState(false);

    useEffect(() => {
        if (apiStatus.completed) {
            if (apiStatus.error && apiStatus.errorMsg)
                handleOpenSnackbar({
                    text: apiStatus.errorMsg,
                    severity: 'error',
                    autoHideDisabled: apiStatus.autoHideDisabled,
                });
            else if (!apiStatus.error && apiStatus.successMsg)
                handleOpenSnackbar({
                    text: apiStatus.successMsg,
                    severity: 'success',
                    autoHideDisabled: apiStatus.autoHideDisabled,
                });
            dispatch(APIReset());
        }
    }, [apiStatus, handleOpenSnackbar, dispatch]);

    useLayoutEffect(() => {
        const localstorage = localStorage.getItem('persist:root');

        if (userColorScheme) {
            themeDispatch(updateColorScheme(userColorScheme));
        } else if (localstorage) {
            let parsedData = JSON.parse(localstorage);
            parsedData = JSON.parse(parsedData.auth);
            const colorScheme = parsedData?.currentUser?.organization.colorScheme || null;

            if (colorScheme) {
                themeDispatch(updateColorScheme(colorScheme));
            } else {
                themeDispatch(updateColorScheme('red'));
            }
        } else {
            themeDispatch(updateColorScheme('red'));
        }
    }, [dispatch, userColorScheme]);

    // TODO: this could be unnecessary routine
    let intervalTimer;
    useEffect(() => {
        // check token every second
        const delay = 1 * 1000;
        if (!intervalTimer) {
            intervalTimer = setInterval(() => {
                if (themeOverride) setThemeLoaded(true);
                clearInterval(intervalTimer);
                intervalTimer = null;
            }, delay);
        }

        return () => {
            clearInterval(intervalTimer);
            intervalTimer = null;
        };
    }, [themeOverride]);

    useEffect(() => {
        // Intention is to redirect to login page if user's authenticating fails for whatever (presumably operational) reason e.g. token expired
        // while trying to access a protected route.
        // Potential concerns to watch out for:
        // - can users still visit public views without being redirected to login? As in: they have a token, but it's expired, and try
        // to visit a public view e.g. forgot password. We don't want to kick them to login
        // - are there 401 responses that should not redirect to login? do we need to check errors more specifically?

        const interceptor = WebClient.interceptors.response.use(null, async (error) => {
            if (error.response?.status === 401 && localStorage.getItem('authToken')) {
                dispatch(logout());

                // Auth protections must be enforced at render in whichever layouts are protected i.e. redirecting to login
                // in reaction to logout reduction
            }
            return Promise.reject(error);
        });

        if (localStorage.getItem('authToken')) {
            // reauthenticate user if token is still valid
            dispatch(fetchCurrentUser());
        }

        return () => {
            WebClient.interceptors.response.eject(interceptor);
        };
    }, []);

    if (!themeLoaded) {
        return (
            <Box
                style={{
                    height: '100vh',
                    width: '100vw',
                    scroll: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ProgressSpinner
                    id="themeLoader"
                    isLoading
                    ariaLabel="Loading application theme"
                />
            </Box>
        );
    }

    return (
        <Suspense fallback={<div />}>
            <NotificationSnackbar {...snackbarProps} />
            <SentryRoutes>
                <Route
                    path="/report/*"
                    element={isAuthenticated ? <ReportRouter /> : <Navigate replace to="/login" />}
                />
                <Route
                    path="/dashboard/*"
                    element={isAuthenticated ? <DashboardLayout /> : <Navigate replace to="/login" />}
                />
                <Route path="/*" element={<PublicLayout />} />
            </SentryRoutes>
        </Suspense>
    );
};

export default withRoot(App, themer);
