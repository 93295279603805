export default [
    {
        key: 'blue',
        primary: {
            main: '#305EEC',
            mainRGB: '48, 94, 236',
            light: '#305EEC',
        },
    },
    {
        key: 'green',
        primary: {
            main: '#025A1F',
            mainRGB: '2, 90, 31',
            light: '#14842E',
        },
    },
    {
        key: 'red',
        primary: {
            main: '#8D1C25',
            mainRGB: '141, 28, 37',
            light: '#C62230',
        },
    },
    {
        key: 'grey',
        primary: {
            main: '#3B4754',
            mainRGB: '59, 71, 84',
            light: '#5C6E81',
        },
    },
];
