import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export default styled(Box)((props) => ({
    borderRadius: 20,
    minWidth: 500,
    width: '100%',
    maxWidth: 1000,
    backgroundColor: 'white',
    maxHeight: '90vh',
    overflow: 'scroll',
    padding: 60,
    paddingTop: 40,
    [props.theme.breakpoints.down('md')]: {
        minWidth: '100%',
        width: '100%',
    },
}));
