import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import ConditionalQuestions from '../conditional-questions';

const CheckboxItem = ({ value, name, label, inputRef, inputProps, onChange, isChecked, conditional, disabled }) => (
    <>
        <FormControlLabel
            className={`Checkbox-label ${isChecked ? 'Checkbox-label-checked' : ''}`} // targeted in material-theme
            label={label}
            value={value}
            inputRef={inputRef}
            onChange={onChange}
            checked={isChecked}
            disabled={disabled}
            control={<Checkbox color="default" checked={isChecked} name={name} inputProps={inputProps} />}
        />

        {conditional && (
            <div>
                <ConditionalQuestions hideBorder hideContainer condition={isChecked}>
                    {conditional}
                </ConditionalQuestions>
            </div>
        )}
    </>
);

CheckboxItem.defaultProps = {
    label: null,
    inputRef: null,
    inputProps: null,
    onChange: null,
    isChecked: false,
    conditional: null,
    disabled: false,
};

CheckboxItem.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    inputRef: PropTypes.func,
    inputProps: PropTypes.object,
    onChange: PropTypes.func,
    isChecked: PropTypes.bool,
    conditional: PropTypes.node,
    disabled: PropTypes.bool,
};

export default CheckboxItem;
