/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import noop from 'lodash/noop';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import prepareContentType from '../../utils/prepare-content-type';
import API from '../../api';
import { APIError, resetRequestState } from '../app';

const fetchOrganizations = createAsyncThunk('organizations/getOrganizations', async (payload = {}) => {
    const data = await API.fetchOrganizations(payload);

    return data;
});

const createOrganization = createAsyncThunk(
    'organizations/createOrganization',
    async ({ org, onSuccess = noop, onError = noop }, thunkApi) => {
        try {
            const [formData, config] = prepareContentType('form-data', org);
            const data = await API.createOrganization(formData, config);

            onSuccess(data.id);
            return data;
        } catch (error) {
            thunkApi.dispatch(APIError({ text: 'Error creating Organization.' }));
            onError();
        }
    },
);

const updateOrganization = createAsyncThunk(
    'organizations/updateOrganization',
    async ({ orgId, org, onSuccess = noop, onError = noop }, thunkApi) => {
        try {
            const [formData, config] = prepareContentType('form-data', org);
            const data = await API.updateOrganization(orgId, formData, config);

            onSuccess(orgId);
            return data;
        } catch (error) {
            thunkApi.dispatch(APIError({ text: 'Error updating Organization.' }));
            onError();
        }
    },
);

const deleteOrganization = createAsyncThunk(
    'organizations/deleteOrganization',
    async ({ id, onSuccess = noop, onError = noop }, thunkApi) => {
    try {
        await API.deleteOrganization(id);
        onSuccess();
    } catch (error) {
        thunkApi.dispatch(APIError({ text: 'Error deleting Organization.' }));
        onError();
    }
});

const initialState = {
    data: [],
    currentRequestId: null,
    errorMsg: null,
    loading: 'idle',
    total: 0,
};

const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetRequestState, (state) => {
                state.errorMsg = initialState.errorMsg;
                state.loading = initialState.loading;
                state.currentRequestId = initialState.currentRequestId;
            })
            .addCase(fetchOrganizations.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.results = action.payload.results;
                    state.total = action.payload.total;
                }
            })
            .addCase(updateOrganization.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = state.data.map((org) => {
                        if (org.id === action.payload.id) {
                            return action.payload;
                        }
                        return org;
                    });
                }
            })
            .addCase(deleteOrganization.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = state.data.filter((site) => site.id !== action.meta.arg.id);
                }
            })
            .addCase(createOrganization.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = [...state.data, action.payload];
                }
            })
            .addMatcher(
                isAnyOf(updateOrganization.pending, deleteOrganization.pending, createOrganization.pending, fetchOrganizations.pending),
                (state, action) => {
                    if (state.loading === 'idle') {
                        state.loading = 'pending';
                        state.currentRequestId = action.meta.requestId;
                    }
                },
            )
            .addMatcher(
                isAnyOf(deleteOrganization.rejected, createOrganization.rejected, fetchOrganizations.rejected, updateOrganization.rejected),
                (state, action) => {
                    const { requestId } = action.meta;
                    if (state.loading === 'pending' && state.currentRequestId === requestId) {
                        state.loading = 'idle';
                        state.error = action.error;
                        state.currentRequestId = undefined;
                    }
                },
            );
    },
});

const { reducer } = organizationsSlice;

export { fetchOrganizations, createOrganization, updateOrganization, deleteOrganization };
export default reducer;
