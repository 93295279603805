import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from 'tss-react/mui';

import { SECTION_PROGRESS } from '../../../../redux/current-report/constants';

import Styles from './styles';

const ReportBlockButton = ({ classes, section }) => {
    const { id, progress, name, Icon } = section;
    const navigate = useNavigate();

    const handleClick = useCallback(() => navigate(`/report/section/${id}`), [navigate, id]);

    return (
        <Card
            variant="outlined"
            className={`
                ${classes.wrapper}
                ${progress === SECTION_PROGRESS.NOT_STARTED && classes.progressNotStarted}
                ${progress === SECTION_PROGRESS.IN_PROGRESS && classes.progressInProgress}
                ${progress === SECTION_PROGRESS.COMPLETED && classes.progressCompleted}
                ${progress === SECTION_PROGRESS.FLAGGED && classes.questionsFlagged}
            `}
        >
            <CardActionArea className={classes.actionButton} onClick={handleClick}>
                <CardHeader
                    classes={{
                        root: `header ${classes.header}`,
                        title: `headerText ${classes.headerText}`,
                    }}
                    title={
                        progress !== SECTION_PROGRESS.COMPLETED ? (
                            progress
                        ) : (
                            <CheckIcon className={`headerIcon ${classes.headerIcon}`} />
                        )
                    }
                />
                <CardContent className={`body ${classes.body}`}>
                    <div className={`bodyIcon ${classes.bodyIcon}`}>
                        <Icon />
                    </div>
                    <Typography variant="h3" className={`bodyText ${classes.bodyText}`}>
                        {name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

ReportBlockButton.propTypes = {
    classes: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
};

export default withStyles(ReportBlockButton, Styles);
