import { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import { withStyles } from 'tss-react/mui';

import DisplayOptionsControlPanel from '../../../../components/display-options-control-panel';

import Styles from './styles';

const DisplayOptionsPopover = ({ classes }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleToggle = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);

    const handleClose = useCallback(
        (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }

            setOpen(false);
        },
        [anchorRef, setOpen],
    );

    return (
        <>
            <Button
                className={classes.displayOptionsButton}
                aria-label={open ? 'close display options' : 'open display options'}
                aria-describedby={open ? 'display-options-control-panel' : null}
                ref={anchorRef}
                aria-controls={open ? 'display-options-control-panel' : null}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                Display options
                <ExpandMoreIcon />
            </Button>
            <Popover
                id="display-options-control-panel"
                classes={{
                    paper: classes.controlPanel,
                }}
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <DisplayOptionsControlPanel />
            </Popover>
        </>
    );
};

DisplayOptionsPopover.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(DisplayOptionsPopover, Styles);
