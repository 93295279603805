import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

export const init = () => {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_DEPLOY_ENV,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
            new CaptureConsoleIntegration({
                // array of methods that should be captured
                levels: ['error', 'warn'],
            }),
        ],
        tracesSampleRate: 1.0,
    });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
