import Colors from '../../styles/colors';

const styles = () => ({
    '@keyframes loading': {
        from: {
            width: '0%',
        },
        to: {
            width: '75%',
        },
    },
    '@keyframes loaded': {
        from: {
            width: '75%',
        },
        to: {
            width: '100%',
        },
    },
    meter: {
        height: 2,
        marginTop: 5,
        background: Colors.ultramarineBlue,
        '& span': {
            display: 'block',
            height: '100%',
            backgroundColor: Colors.persianBlue,
        },
    },
    meterLoading: {
        animationName: '$loading',
        animationTimingFunction: 'easeIn',
        animationDuration: '3s',
        animationFillMode: 'forwards', // hold finishing state
    },
    meterLoaded: {
        width: '75%',
        animationName: '$loaded',
        animationTimingFunction: 'easeOut',
        animationDuration: '.6s',
        animationFillMode: 'forwards',
    },
    button: {
        maxHeight: '40px',
        fontWeight: 500,
        fontSize: '1rem',
    },
    uploadButtonLabel: {
        fontSize: '0.9375rem',
        fontWeight: 500,
        zIndex: 1,
        padding: '0 2px',
    },
    deleteButton: {
        color: Colors.carnelian,

        '&:hover, &:focus': {
            backgroundColor: `rgba(${Colors.carnelianRGB}, 0.3)`,
            outline: 0,
        },
    },
    documentIcon: {
        color: `rgba(${Colors.persianBlueRGB}, 0.3)`,
        marginRight: 6,
        fontSize: '2em',
    },
});

export default styles;
