import Colors from '../../../../styles/colors';

const styles = () => ({
    pill: {
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        padding: '2px 15px 2px 6px',
        maxWidth: 'fit-content',
        whiteSpace: 'nowrap',
    },
    pillIcon: {
        height: 8,
        width: 8,
        borderRadius: '50%',
        display: 'inline-block',
    },
    pillText: {
        fontSize: '0.8125rem',
        fontWeight: 'inherit',
        paddingLeft: 5,
        color: Colors.black,
    },
    secondary: {
        backgroundColor: `rgba(${Colors.lavenderGrayRGB}, 0.3)`,
        fontWeight: 600,

        '& .pillIcon': {
            backgroundColor: Colors.lavenderGray,
        },
    },
    primary: {
        backgroundColor: `rgba(${Colors.ultramarineBlueRGB}, 0.1)`,
        fontWeight: 600,

        '& .pillIcon': {
            backgroundColor: Colors.ultramarineBlue,
        },
    },
    italic: {
        fontStyle: 'italic',
        fontWeight: 500,
        color: Colors.darkElectricBlue,
        backgroundColor: 'transparent',

        '& .pillIcon': {
            height: 0,
            width: 0,
        },
    },
    alarm: {
        background: `rgba(${Colors.pastelRedRGB},.2)`,
        '& .pillIcon': {
            backgroundColor: Colors.pastelRed,
        },
    },
});

export default styles;
