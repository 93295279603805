import PropTypes from 'prop-types';
import { IMaskMixin } from 'react-imask';
import { Controller } from 'react-hook-form';

import TextInput from '../textinput';

const InnerTextInput = IMaskMixin((maskProps) => <TextInput {...maskProps} />);

const TextMask = ({ name, control, ...otherProps }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <InnerTextInput name={name} value={field.value || ''} onAccept={field.onChange} {...otherProps} />
        )}
    />
);

TextMask.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
};

export default TextMask;
