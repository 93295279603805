import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import DisplayOptionsPopover from './display-options-popover';

import Styles from './styles';

const ReportHeader = ({ classes, backButtonLabel, onBackButtonClick }) => (
    <header className={classes.wrapper}>
        <Grid item container style={{ height: '100%' }}>
            <Grid item xs={9}>
                <Button className={classes.buttonText} onClick={onBackButtonClick}>
                    <ArrowBackIcon />
                    {backButtonLabel}
                </Button>
            </Grid>
            <Grid item xs={3}>
                <Box display="flex" justifyContent="flex-end">
                    <DisplayOptionsPopover />
                </Box>
            </Grid>
        </Grid>
    </header>
);

ReportHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    backButtonLabel: PropTypes.string.isRequired,
    onBackButtonClick: PropTypes.func.isRequired,
};

export default withStyles(ReportHeader, Styles);
