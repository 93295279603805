import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import CreateEditOrganizationsForm from './form';
import Styles from '../../invite-edit-tester/styles';
import DashboardStyles from '../../styles';
import CombineStyles from '../../../../utils/combine-styles';
import { getOrganizationSelector } from '../../../../redux/organizations/selectors';

const CreateEditOrganization = ({ classes }) => {
    const { orgId } = useParams();
    const getSite = useSelector(getOrganizationSelector);
    const org = orgId ? getSite(orgId) : null;

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer} style={{ paddingTop: 0 }}>
            <Grid item xs={12} className={classes.backLink}>
                <Link to="/dashboard/organizations">Back to Manage Organizations</Link>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h1">{org ? 'Edit ' : 'Add New '} Organization</Typography>
            </Grid>
            <Grid item xs={12} className={classes.description}>
                <Typography variant="body1">All fields are required.</Typography>
            </Grid>

            <Grid item xs={9}>
                <CreateEditOrganizationsForm org={org} />
            </Grid>
        </Grid>
    );
};

CreateEditOrganization.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(DashboardStyles, Styles);
export default withStyles(CreateEditOrganization, combinedStyles);
