import { string, array, object, mixed } from 'yup';

const schema = object().shape({
    // report
    name: string().required('Report Name is required'),
    instructions: string().required('Report Instructions are required'),
    // sections
    // loop through sections which have the structure of { name: string, icon: string, questions: [ { text: string, type: string, required: bool, questions: array } ] }
    // validate that text and icon are not empty and then loop through questions
    sections: array().of(
        object().shape({
            name: string()
                .required('Section Name is required')
                .test('name is unique', 'Section Name must be unique', (value, context) => {
                    // test if the section name is unique
                    const { sections } = context.from[1].value;
                    const sectionNames = sections.map((section) => section.name);
                    return sectionNames.filter((sectionName) => sectionName === value).length === 1;
                }),
            icon: string().required('Section Icon is required'),
            // Questions - Level 1
            questions: array().of(
                object().shape({
                    text: string().required('Question Text is required'),
                    type: string().required('Question Type is required'),
                    options: array()
                        .nullable()
                        .when('type', {
                            is: (type) => type === 'radioButton' || type === 'checkBoxes',
                            then: (arraySchema) => arraySchema.required('Options are required').min(1, 'At least one option is required'),
                            otherwise: (arraySchema) => arraySchema.nullable(),
                        }),
                    // Questions - Level 2
                    questions: array().of(
                        object().shape({
                            text: string().required('Text is required'),
                            type: string().required('Type is required'),
                            renderLogic: object().shape({
                                condition: string()
                                    .nullable()
                                    // Must not be an arrow function in order to access context because yup binds to "this".
                                    // eslint-disable-next-line prefer-arrow-callback
                                    .test('condition', 'Condition is required', function test(value, context) {
                                        const parentType = context.from[2].value.type;
                                        if (parentType === 'repeatingGroup') {
                                          return true; // No validation needed for repeatingGroup
                                        }
                                        return value !== undefined; // Condition is required for other types
                                      }),
                                value: mixed()
                                    .when('condition', {
                                        is: (condition) => condition !== 'isEmpty' && condition !== 'isNotEmpty' && condition !== undefined,
                                        then: (stringSchema) => stringSchema.required('Condition statement must be complete'),
                                        otherwise: (stringSchema) => stringSchema.notRequired(),
                                    }),
                            }),
                            options: array()
                                .nullable()
                                .when('type', {
                                    is: (type) => type === 'radioButton' || type === 'checkBoxes',
                                    then: (arraySchema) => arraySchema.required('Options are required').min(1, 'At least one option is required'),
                                    otherwise: (arraySchema) => arraySchema.notRequired(),
                                }),
                            // Questions - Level 3
                            questions: array().of(
                                object().shape({
                                    text: string().required('Text is required'),
                                    type: string().required('Type is required'),
                                    renderLogic: object().shape({
                                        condition: string()
                                            .nullable()
                                            // Must not be an arrow function in order to access context because yup binds to "this".
                                            // eslint-disable-next-line prefer-arrow-callback
                                            .test('condition', 'Condition is required', function test(value, context) {
                                                const parentType = context.from[2].value.type;
                                                if (parentType === 'repeatingGroup') {
                                                    return true; // No validation needed for repeatingGroup
                                                }
                                                return value !== undefined; // Condition is required for other types
                                            }),
                                        value: mixed()
                                            .when('condition', {
                                                is: (condition) => condition !== 'isEmpty' && condition !== 'isNotEmpty' && condition !== undefined,
                                                then: (stringSchema) => stringSchema.required('Condition statement must be complete'),
                                                otherwise: (stringSchema) => stringSchema.notRequired(),
                                            }),
                                    }),
                                    options: array()
                                        .nullable()
                                        .when('type', {
                                            is: (type) => type === 'radioButton' || type === 'checkBoxes',
                                            then: (arraySchema) => arraySchema.required('Options are required').min(1, 'At least one option is required'),
                                            otherwise: (arraySchema) => arraySchema.notRequired(),
                                        }),
                                }),
                            ),
                        }),
                    ),
                }),
            ),
        }),
    ),
});

export default schema;
