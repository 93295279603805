import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoopIcon from '@mui/icons-material/Loop';

import Styles from './styles';
import { REPORT_STATUS } from '../../../../redux/reports';
import SubmitIcon from '../../../../components/icons/submit-icon';

const OUTCOME_MAP = {
    PENDING: {
        text: 'Submitted',
        icon: <SubmitIcon />,
    },
    POSITIVE: {
        text: 'Positive',
        icon: <AddCircleIcon />,
    },
    NEGATIVE: {
        text: 'Negative',
        icon: <RemoveCircleIcon />,
    },
    INCONCLUSIVE: {
        text: 'Inconclusive',
        icon: <NotInterestedIcon />,
    },
    [REPORT_STATUS.DRAFT]: {
        text: 'Draft',
        icon: null,
    },
    [REPORT_STATUS.SUBMITTED]: {
        text: 'Submitted',
        icon: <SubmitIcon />,
    },
    [REPORT_STATUS.REOPENED]: {
        text: 'Re-opened',
        icon: <LoopIcon />,
    },
};

const OutcomePill = ({ outcome, classes }) => {
    const { text, icon } = OUTCOME_MAP[outcome];

    return (
        <div className={`${classes.pill} ${classes[outcome.toLowerCase()]} `}>
            {icon && <div className={`pillIcon ${classes.pillIcon}`}>{icon}</div>}
            <Typography variant="body2" component="div" className={classes.pillText}>
                {text}
            </Typography>
        </div>
    );
};

OutcomePill.propTypes = {
    outcome: PropTypes.oneOf(['SUBMITTED', 'PENDING', 'POSITIVE', 'NEGATIVE', 'INCONCLUSIVE', 'REOPENED', 'DRAFT']).isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(OutcomePill, Styles);
