import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import Styles from './styles';

const PropertyBlockButton = ({
    classes,
    progress,
    title,
    property,
    address,
    visitDate,
    propertyImg,
    onEditClick,
    onDeleteClick,
}) => (
    <div
        className={`
            ${progress === 'inprogress' && classes.progressInProgress}
            ${progress === 'completed' && classes.progressCompleted}
            ${progress === 'reopened' && classes.progressReopened}
            ${classes.buttonWrapper}
        `}
    >
        <div className={`${classes.header} header`}>
            <Typography className={classes.headerText}>{title}</Typography>
        </div>
        <div className={`${classes.body} property-body`}>
            {propertyImg && <img src={propertyImg} alt="" className={classes.propertyImg} />}

            <Typography variant="h2" component="h3" className={classes.bodyHeaderText}>
                {property}
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
                {address} &#8226; Visited {visitDate}
            </Typography>

            <Grid item container xs={12} spacing={2} style={{ width: '100%' }}>
                {progress !== 'completed' && (
                    <>
                        <Grid item xs={6}>
                            <Button
                                className={`${classes.outlineButton} ${classes.smallButton} ${classes.fullWidth}`}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.outlineButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                onClick={onDeleteClick}
                                aria-label={`Delete ${property} Report`}
                            >
                                <DeleteIcon className={classes.buttonIcon} />
                                Delete
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                className={`${classes.outlineButton} ${classes.outlineBlueButton} ${classes.smallButton} ${classes.fullWidth}`}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.outlineBlueButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                onClick={onEditClick}
                                aria-label={`Edit ${property} Report`}
                            >
                                <EditIcon className={classes.buttonIcon} />
                                Edit
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    </div>
);

PropertyBlockButton.defaultProps = {
    propertyImg: null,
    onEditClick: null,
    onDeleteClick: null,
};

PropertyBlockButton.propTypes = {
    classes: PropTypes.object.isRequired,
    progress: PropTypes.oneOf(['inprogress', 'completed', 'reopened']).isRequired,
    title: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    visitDate: PropTypes.string.isRequired,
    propertyImg: PropTypes.string,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(PropertyBlockButton, combinedStyles);
