import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ErrorMessage } from '@hookform/error-message';

const TextInput = ({
    name,
    label,
    value,
    placeholder,
    helperText,
    register,
    errors,
    success,
    required,
    disabled,
    readOnly,
    inputProps,
    children,
    hiddenLabel,
    showError,
    showRequired,
    validationRules,
    ...otherProps // any other props that should be passed to the Material InputBase component
}) => {
    const isInvalid = !!errors[name];

    let describedBy = helperText ? `${name}-helper` : null;
    if (showError && isInvalid) {
        describedBy = describedBy ? `${describedBy} ${name}-error` : `${name}-error`;
    }

    const { ref, ...formProps } = register(name, validationRules);

    const elementProps = {
        ...inputProps,
        id: name,
        'aria-label': hiddenLabel ? label : null,
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        'aria-disabled': disabled,
    };

    return (
        <FormControl variant="outlined" component="fieldset" error={isInvalid} fullWidth required={required}>
            {!hiddenLabel && (
                <InputLabel htmlFor={name} required={showRequired && required} disabled={disabled} shrink={false}>
                    {label}
                </InputLabel>
            )}

            <OutlinedInput
                inputRef={ref}
                id={name}
                value={value}
                placeholder={hiddenLabel ? placeholder : null}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
                error={isInvalid}
                notched={hiddenLabel && !placeholder ? false : undefined}
                inputProps={elementProps}
                {...(!Object.hasOwn(formProps, 'formProps') && formProps)} // eslint-disable-line react/jsx-props-no-spreading
                {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
            />

            {children}

            {helperText && <FormHelperText id={`${name}-helper`}>{helperText}</FormHelperText>}

            {showError && (
                <FormHelperText id={`${name}-error`} role="alert" aria-live="polite" error>
                    {/**
                     * This error message component assumes a structure provided by react-hook-form.
                     * If you need to supply an error message outside of react hook form, pass an object
                     * with the following structure:
                     * {
                     *      inputName: {
                     *          message: 'Error message'
                     *      }
                     * } */}
                    <ErrorMessage name={name} errors={errors} />
                </FormHelperText>
            )}
        </FormControl>
    );
};

TextInput.defaultProps = {
    value: undefined,
    placeholder: null,
    helperText: null,
    errors: {},
    success: false,
    disabled: false,
    required: false,
    readOnly: false,
    inputProps: {},
    children: null,
    hiddenLabel: false,
    showError: true,
    showRequired: false,
    register: () => ({
        ref: null,
        formProps: {},
    }),
    validationRules: {},
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    register: PropTypes.func,
    errors: PropTypes.object,
    success: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    inputProps: PropTypes.object,
    children: PropTypes.node,
    hiddenLabel: PropTypes.bool,
    showError: PropTypes.bool,
    showRequired: PropTypes.bool,
    validationRules: PropTypes.object,
};

export default TextInput;
