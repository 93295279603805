const styles = (theme) => ({
    wrapper: {
        backgroundColor: theme.palette.primary.main,
        minHeight: '100vh',
        position: 'fixed',
        width: 240,
        zIndex: 999,
    },
    imgBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: 200,
        paddingTop: 15,
        paddingBottom: 15,
        margin: '0 auto',
    },
    lscLogo: {
        width: 180,
        paddingBottom: 45,
        margin: '0 auto',
    },
});

export default styles;
