import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import { REQUEST_STATUS } from '../../../redux/reports';

const ReportError = ({ currentReport }) => {
    const { requestStatus, errorMsg = {} } = currentReport;

    if (requestStatus === REQUEST_STATUS.SESSION_EXPIRED) {
        return (
            <Alert severity="error" style={{ marginTop: '12px' }}>
                {errorMsg.message}
            </Alert>
        );
    }
    if (requestStatus === REQUEST_STATUS.ERROR) {
        return (
            <Alert severity="error" style={{ marginTop: '12px' }}>
                {errorMsg.message}
            </Alert>
        );
    }

    return null;
};

ReportError.propTypes = {
    currentReport: PropTypes.shape({
        requestStatus: PropTypes.string,
        errorMsg: PropTypes.shape({
            message: PropTypes.string,
        }),
    }).isRequired,
};

export default ReportError;
