import Colors from '../../../../styles/colors';

const styles = (theme) => ({
    modalContent: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        textAlign: 'center',
        padding: '0 56px',
        maxWidth: '680px',
        width: '100%',
    },
    modalTitle: {
        fontSize: '1.5rem',
        paddingTop: '20px',
        paddingBottom: '40px',
    },
    modalFooter: {
        textAlign: 'right',
        marginTop: '40px',
        marginBottom: '56px',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'right',
        paddingTop: '20px',
    },
    siteInfo: {
        background: Colors.lavenderGray,
        padding: '1rem',
        borderRadius: '6px',
        textAlign: 'left',
    },
    deleteButton: {
        ...theme.customButtons,
        backgroundColor: Colors.carnelian,
        color: Colors.white,
        '&:hover, &:focus:hover': {
            backgroundColor: Colors.carnelian,
            boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)',
        },
    },
});

export default styles;
