import PropTypes from 'prop-types';
import TextMask from '../text-mask';

const TextMaskDate = ({ name, control, ...otherProps }) => (
    <TextMask name={name} control={control} mask="00/00/0000" {...otherProps} />
);

TextMaskDate.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
};

export default TextMaskDate;
