import Colors from '../../styles/colors';

const styles = (theme) => ({
    // tester dashboard
    wrapper: {
        paddingLeft: 30,
        paddingRight: 15,

        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    greyHr: {
        border: `.5px solid ${Colors.lavenderGray}`,
        marginTop: 0,
    },
    // admin dashboard
    dashboardContainer: {
        padding: '35px 110px 200px 30px',

        [theme.breakpoints.down('lg')]: {
            marginLeft: 20,
            paddingLeft: 20,
            paddingRight: 0,
        },
    },
    filterWrapper: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: 20,
    },
    tableWrapper: {
        width: '100%',
        boxShadow: 'none',
        borderRadius: 10,
    },
    tableContainer: {
        maxHeight: 650,
        borderRadius: 'inherit',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    tableHeader: {
        '& th': {
            height: 50,
            fontWeight: 700,
            fontSize: '0.875rem',
            backgroundColor: Colors.white,
            whiteSpace: 'nowrap',
        },

        '& th:nth-of-type(1)': {
            paddingLeft: 30,
        },
    },
    tableBody: {
        '& td:nth-of-type(1)': {
            paddingLeft: 30,
        },
    },
    tableCell: {
        borderColor: Colors.lavenderGray,
        fontSize: '0.9375rem',
        height: 60,
        padding: '0 16px',
    },
    emptyTableCell: {
        border: 'none',
    },
    noWrapCell: {
        whiteSpace: 'nowrap',
    },
    siteName: {
        fontWeight: 700,
    },
    siteOwner: {
        color: Colors.darkElectricBlue,
        fontSize: '0.875rem',
    },
    tableFooter: {
        '& tr:last-child td': {
            border: 'none',
        },
    },
    paginationContainer: {},
    paginationSpacer: {
        width: 300,
    },
    paginationToolbar: {
        padding: '10px 20px',
    },
    // training videos
    trainingVideosContainer: {
        paddingLeft: 50,
        paddingRight: 30,
        paddingTop: 35,

        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 20,
        },
    },
    trainingVideosHeader: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            paddingLeft: 20,
        },
    },
    internalUserName: {
        fontSize: '0.9375rem',
        fontWeight: 600,
    },
    userEmail: {
        fontSize: '0.875rem',
        color: Colors.darkElectricBlue,
    },
    trainingVideosBody: {
        marginLeft: 0,
        marginRight: 0,
        justifyContent: 'space-evenly',

        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
        },

        [theme.breakpoints.down('sm')]: {
            marginLeft: 20,
            marginRight: 20,
            justifyContent: 'center',
        },
    },
    backLink: {
        paddingBottom: 30,

        '& a': {
            color: Colors.persianBlue,
            fontSize: '0.875rem',
            fontWeight: 'bold',
            textDecoration: 'none',
        },

        '& a:hover': {
            textDecoration: 'underline',
        },
    },
    reportDetailsContainer: {
        marginTop: 24,
        maxWidth: 428,
        padding: '16px 32px',
        borderRadius: 6,
        backgroundColor: '#C6CFD8',
    },
    reportDetailsTitle: {
        fontWeight: 700,
    },
    circle: {
        position: 'relative',
        border: '2px solid #E0E3E7',
        borderRadius: '100%',
        width: 12,
        height: 12,
        textAlign: 'center',

        backgroundColor: '#E0E3E7',
        zIndex: 2,

        '&::before': {
            position: 'absolute',
            border: '1px solid #E0E3E7',
            width: 0,
            height: '50px',
            marginTop: '-48px',
            display: 'block',
            content: '" "',
            zIndex: 1,
            top: 0,
            left: 3,
        },
        '&::after': {
            position: 'absolute',
            border: '1px solid #E0E3E7',
            width: 0,
            height: '2px',
            marginTop: '10px',
            display: 'block',
            content: '" "',
            zIndex: 1,
            top: 0,
            left: 3,
        },

        '&.first': {
            marginTop: 0,
            '&::before': {
                display: 'none',
            },
        },

        '&.last': {
            '&::after': {
                display: 'none',
            },
        },
    },
    noBorder: {
        border: 'none',
    },
    timelineCell: {
        border: 'none',
        padding: 0,
    },
    roleToolTip: {
        listStyleType: 'none',
    },
    pageTitle: {
        display: 'inline-block',
    },
    pageCount: {
        display: 'inline-block',
        marginLeft: 20,
    },
});
export default styles;
