import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import { NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { logout } from '../../../../redux/auth';
import LogoImg from '../../../../images/logo_on_blue_bg.png';
import LSCLogo from '../../../../images/LSC Logo-White-Horizontal.png';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import Styles from './styles';
import { ADMINISTRATIVE_ROLES, USER_ROLES } from '../../../../utils/constants';
import { UserRolesPropType } from '../../../../utils/prop-types';
import { getUserOrganization } from '../../../../redux/auth/selectors';

const LinkComponent = forwardRef((props, ref) => {
    const { route, ...rest } = props;
    return <NavLink to={`/dashboard/${route}`} {...ref} {...rest} />;
});

LinkComponent.propTypes = {
    route: PropTypes.string.isRequired,
};

const DesktopNav = ({ classes, role }) => {
    const dispatch = useDispatch();
    const userOrganization = useSelector(getUserOrganization);
    const showAdmin = ADMINISTRATIVE_ROLES.includes(role);
    const canEdit = role === USER_ROLES.admin.value || role === USER_ROLES.testCoordinator.value || role === USER_ROLES.orgAdmin.value;

    return (
        <Grid item className={classes.wrapper} style={{ display: 'flex', flexDirection: 'column' }}>
            <h1 className="offscreen">{userOrganization?.name || ''}</h1>

            <Box className={classes.imgBox}>
                {userOrganization?.logo && (
                    <img className={classes.logo} src={userOrganization.logo} alt="" />
                )}
                {!userOrganization?.logo && (
                    <img className={classes.logo} src={LogoImg} alt="" />
                )}
                <img className={classes.lscLogo} src={LSCLogo} alt="" />
            </Box>
            <Grid item container style={{ display: 'flex', alignItems: 'space-between', flexGrow: 1 }}>
                <Grid item xs={12}>
                    {showAdmin ? (
                        <>
                            <Button
                                component={LinkComponent}
                                route="reports"
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                Reports
                            </Button>
                            <Button
                                component={LinkComponent}
                                route="testers"
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                {canEdit ? 'Manage Testers' : 'Testers'}
                            </Button>
                            {(role === USER_ROLES.admin.value || role === USER_ROLES.orgAdmin.value) && (
                                <Button
                                    component={LinkComponent}
                                    route="users"
                                    className={classes.navButton}
                                    fullWidth
                                    role="link"
                                >
                                    Manage Internal Users
                                </Button>
                            )}
                            <Button component={LinkComponent} route="sites" className={classes.navButton} fullWidth role="link">
                                {canEdit ? 'Manage Sites' : 'Sites'}
                            </Button>
                            {(role === USER_ROLES.admin.value || role === USER_ROLES.orgAdmin.value) && (
                                <Button
                                    component={LinkComponent}
                                    route="test-builder"
                                    className={classes.navButton}
                                    fullWidth
                                    role="link"
                                >
                                    Test Builder
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            <Button
                                component={LinkComponent}
                                route="reports"
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                My Reports
                            </Button>
                            <Button
                                component={LinkComponent}
                                route="training"
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                Training Videos
                            </Button>
                        </>
                    )}

                    <Button onClick={() => dispatch(logout())} className={classes.navButton} fullWidth role="link">
                        Log Out
                    </Button>
                </Grid>
                {role === 'admin' && (
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', paddingBottom: 30 }}>
                        <Button
                            component={LinkComponent}
                            route="organizations"
                            className={classes.navButton}
                            fullWidth
                            role="link"
                        >
                            Manage<br />Organizations
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

DesktopNav.propTypes = {
    classes: PropTypes.object.isRequired,
    role: UserRolesPropType.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(DesktopNav, combinedStyles);
