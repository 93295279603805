import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import PrimaryButton from '../buttons/primary-button';
import SecondaryButton from '../buttons/secondary-button';
import ModalWrapper from '../modal-parts/modal-wrapper';
import ModalContentWrapper from '../modal-parts/modal-content-wrapper';
import ButtonStyles from '../../styles/buttons';

const AbandonChangesModal = ({ open, handleClose, handleConfirm, classes }) => (
        <ModalWrapper
            open={open}
            onClose={handleClose}
            aria-label="Select Section Icon"
            style={{ maxWidth: '80vw', margin: '0 auto' }}
        >
            <ModalContentWrapper>
                <Grid item container pb={2} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h2">
                            Cancel Changes
                        </Typography>
                        <Typography variant="body1" pt={2}>
                            Canceling will lose any changes you&apos;ve made.
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <IconButton
                            color="primary"
                            aria-label="Close modal"
                            onClick={handleClose}
                            edge="end"
                            size="large"
                            disableFocusRipple
                        >
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid item container pt={2}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <SecondaryButton className={classes.secondaryButton} onClick={handleClose} sx={{ mr: 2 }}>
                            Go Back
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={handleConfirm}
                            className={classes.primaryButton}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                        >
                            Yes, Cancel
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </ModalContentWrapper>
        </ModalWrapper>
    );

AbandonChangesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(AbandonChangesModal, ButtonStyles);
