export default [
    { key: 'equals', label: 'is', types: ['date', 'time', 'radioButton', 'checkBoxes'] },
    { key: 'notEquals', label: 'is not', types: ['date', 'time', 'radioButton', 'checkBoxes'] },
    { key: 'includes', label: 'includes', types: ['checkBoxes'] },
    { key: 'excludes', label: 'excludes', types: ['checkBoxes'] },
    { key: 'isEmpty', label: 'is empty', types: ['shortText', 'longText', 'date', 'time', 'radioButton', 'checkBoxes', 'file'] },
    { key: 'isNotEmpty', label: 'is not empty', types: ['shortText', 'longText', 'date', 'time', 'radioButton', 'checkBoxes', 'file'] },
    { key: 'isBefore', label: 'is before', types: ['date', 'time'] },
    { key: 'isAfter', label: 'is after', types: ['date', 'time'] },
];
