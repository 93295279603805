import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { withStyles } from 'tss-react/mui';
// eslint-disable-next-line import/no-unresolved
import { ErrorMessage } from '@hookform/error-message';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import GetFileExtension from '../../utils/get-file-extension';
import Styles from './styles';
import { APIError } from '../../redux/app';

const FileUploader = ({
    classes,
    name,
    label,
    labelledBy,
    describedBy,
    required,
    onChange,
    onClear,
    register,
    setValue,
    errors,
    allowedFileTypes,
    previousFilename,
}) => {
    const dispatch = useDispatch();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [localError, setLocalError] = useState(null);

    // on mount, if a file has been uploaded previously (when offering the ability to replace the file),
    // set the uploadedFile and default uploadStatus to 'success', otherwise set to null so that it does
    // not default to a File or FileList object, both of which are not serializable
    useEffect(() => {
        if (previousFilename) {
            setUploadedFile({ name: previousFilename });
        } else if (setValue) {
            // report-attachment-uploader does not have setValue prop
            setValue(name, null);
        }
    }, [setUploadedFile, name, previousFilename]);
    const isInvalid = !!errors[name];
    const describedByError = isInvalid ? `${name}-error` : null;

    const elementProps = {
        'aria-labelledby': labelledBy,
        'aria-label': label,
        'aria-describedby': `${describedBy} ${describedByError}`,
        'aria-invalid': isInvalid,
        'aria-required': required,
        required,
    };

    const registrationData = register ? register(name) : null;

    const selectFile = useCallback(() => {
        document.getElementById(`file-input-${name}`).click();
    }, [name]);

    const handleChange = useCallback(() => {
        const fileToUpload = document.getElementById(`file-input-${name}`).files[0];
        let uploadErrMsg = null;
        // only proceed if we have a file selected
        if (fileToUpload) {
            if (!allowedFileTypes.includes(GetFileExtension(fileToUpload.name, true).toLowerCase())) {
                uploadErrMsg = 'File type not supported';
                dispatch(APIError({ text: uploadErrMsg }));
            } else if (fileToUpload.size > 10485760) {
                // 10mb
                uploadErrMsg = 'File size exceeds 10MB limit';
                dispatch(APIError({ text: uploadErrMsg }));
            } else {
                setUploadedFile(fileToUpload);
                if (setValue) {
                    setValue(name, fileToUpload.name);
                }

                if (onChange) {
                    onChange(fileToUpload);
                }
            }
            setLocalError(uploadErrMsg);
        }
    }, [dispatch, allowedFileTypes, setUploadedFile, name, onChange]);

    const clearSelectedFile = useCallback(() => {
        setUploadedFile(null);
        setValue(name, null);
        // clear file uploader component, so onChange event is registered
        document.getElementById(`file-input-${name}`).value = '';

        if (onClear) onClear();
    }, [setUploadedFile, name, onClear]);

    const replaceFile = useCallback(() => {
        clearSelectedFile();
        selectFile();
    }, [clearSelectedFile, selectFile]);

    return (
        <>
            <Grid item container spacing={2} xs={12}>
                {uploadedFile && uploadedFile.name && (
                    <>
                        <Grid item xs={1}>
                            <InsertDriveFileIcon className={classes.documentIcon} />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">{uploadedFile.name}</Typography>
                        </Grid>
                    </>
                )}
                <Grid item xs={7}>
                    <input
                        id={`file-input-${name}`}
                        type="file"
                        name={name}
                        accept={allowedFileTypes ? allowedFileTypes.join(',') : null}
                        style={{ display: 'none' }}
                        ref={registrationData?.ref}
                        {...elementProps}
                        onChange={handleChange}
                    />
                    <Box sx={{ display: 'inline', marginRight: '10px' }}>
                        <Button
                            className={`${classes.outlineBlueButton} ${classes.button}`}
                            classes={{
                                label: classes.uploadButtonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.outlineBlueButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            aria-label={
                                !uploadedFile || !uploadedFile.name
                                    ? 'upload outcome file'
                                    : 'replace uploaded outcome file'
                            }
                            onClick={!uploadedFile || !uploadedFile.name ? selectFile : replaceFile}
                        >
                            {!uploadedFile || !uploadedFile.name ? 'Upload File' : 'Replace'}
                        </Button>
                    </Box>
                    {((uploadedFile && uploadedFile.name) || previousFilename) && (
                        <Box sx={{ display: 'inline', margin: '0 10' }}>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={clearSelectedFile}
                                aria-label="delete uploaded outcome file"
                                className={`${classes.deleteButton} ${classes.button}`}
                            >
                                Delete
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div id={`${name}-error`} className={classes.errorMessage} role="alert" aria-live="polite">
                    <ErrorMessage name={name} errors={errors} />
                    {localError && (
                        <FormHelperText error>
                            <b>Error with selected file:</b>
                            <br />
                            <span style={{ fontStyle: 'italic', fontSize: '13px' }}>{localError}</span>
                        </FormHelperText>
                    )}
                </div>
            </Grid>
        </>
    );
};

FileUploader.defaultProps = {
    label: null,
    labelledBy: null,
    describedBy: null,
    errors: {},
    required: false,
    onChange: null,
    onClear: null,
    register: null,
    setValue: null,
    allowedFileTypes: null,
    previousFilename: null,
};

FileUploader.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelledBy: (props, propName, componentName) => {
        if (!props.label && (!props[propName] || typeof props[propName] !== 'string')) {
            return new Error(`One of props: label or labelledBy supplied to ${componentName} is required.`);
        }
        return undefined;
    },
    describedBy: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    register: PropTypes.func,
    setValue: PropTypes.func,
    errors: PropTypes.object,
    allowedFileTypes: PropTypes.array,
    previousFilename: PropTypes.string,
};

const combinedStyles = CombineStyles(InputStyles, ButtonStyles, Styles);
export default withStyles(FileUploader, combinedStyles);
