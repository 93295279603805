import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

const convertDate = (date) => {
    if (!date) return null;

    let newDate = date;

    if (typeof date === 'string') {
        const zLocation = date.search('Z');
        if (zLocation > -1) {
            newDate = new Date(date.substring(0, date.search('Z')));
        } else {
            newDate = new Date(date);
        }
    }

    return newDate;
};

export const getFormattedDate = (dateToFormat) => {
    const newDate = convertDate(dateToFormat);
    return isValid(newDate) ? format(newDate, 'MM/dd/yyyy') : newDate;
};

// this is going to be a timestamp coming in, and is only used in 1 place, so doesn't
// need to do any of the above gymnastics - just make a date and output in the desired format
export const getFormattedTimeStamp = (dateToFormat) => {
    if (!dateToFormat) return null;

    const newDate = new Date(dateToFormat);
    return format(newDate, "M/dd/yyyy 'at' h:mm a");
};

export default getFormattedDate;
