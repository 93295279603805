import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import CreateEditSiteForm from './form';
import Styles from '../invite-edit-tester/styles';
import DashboardStyles from '../styles';
import CombineStyles from '../../../utils/combine-styles';
import { getSiteSelector } from '../../../redux/sites/selectors';

const CreateEditSite = ({ classes }) => {
    const { siteId } = useParams();
    const getSite = useSelector(getSiteSelector);
    const site = getSite(siteId);

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            <Grid item xs={12} className={classes.backLink}>
                <Link to="/dashboard/sites">Back to Manage Sites</Link>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h1">{site ? 'Edit ' : 'Add New '} Site</Typography>
            </Grid>
            <Grid item xs={12} className={classes.description}>
                <Typography variant="body1">All fields marked with an asterisk (*) are required.</Typography>
            </Grid>

            <Grid item xs={9}>
                <CreateEditSiteForm site={site} />
            </Grid>
        </Grid>
    );
};

CreateEditSite.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(DashboardStyles, Styles);
export default withStyles(CreateEditSite, combinedStyles);
