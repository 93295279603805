// This rule is basically in direct conflict w/ Immer
/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import API from '../../api';
import { resetRequestState } from '../app';

const fetchCurrentUser = createAsyncThunk('auth/fetchCurrentUser', async (reqOpts, thunkApi) => {
    const source = Axios.CancelToken.source();
    thunkApi.signal.addEventListener('abort', () => {
        source.cancel();
    });
    const data = await API.fetchCurrentUser(reqOpts);

    return data;
});

const login = createAsyncThunk('auth/login', async ({ formValues, onSuccess, onError, reqOpts }) => {
    const { email, password } = formValues;
    try {
        const data = await API.login({ email, password }, reqOpts);

        localStorage.setItem('authToken', data.token);

        if (onSuccess) onSuccess();

        return data.user;
    } catch (error) {
        let errorType = 'UNKNOWN';
        if (error.response && error.response.status === 401) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && error.response.status === 422) {
            errorType = 'UNPROCESSABLE';
        }
        if (onError) onError(errorType);

        throw error;
    }
});

const forgotPassword = createAsyncThunk('auth/forgotPassword', async ({ email, onSuccess, onError, reqOpts }) => {
    try {
        await API.requestPasswordReset({ email }, reqOpts);
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (error.response.status === 404 || error.response.status === 400)) {
            errorType = 'NOT_FOUND';
        }

        if (onError) onError(errorType);

        throw error;
    }
});

const resetPassword = createAsyncThunk('auth/resetPassword', async ({ formValues, onSuccess, onError, reqOpts }) => {
    const { email, resetToken, password } = formValues;

    try {
        await API.resetPassword(
            {
                email,
                newPassword: password,
                resetToken,
            },
            reqOpts,
        );
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (error.response.status === 404 || error.response.status === 400)) {
            errorType = 'NOT_FOUND';
        }

        if (onError) onError(errorType);

        throw error;
    }
});

const logout = createAsyncThunk('auth/logout', async () => {
    try {
        await API.logout();
        localStorage.removeItem('authToken');
    } catch (err) {
        if (err.response?.status === 401) {
            // API thinks we're already logged out, so trash our defunct token
            localStorage.removeItem('authToken');
            return;
        }

        throw err;
    }
});

const initialState = {
    currentUser: null,
    loading: 'idle',
    currentRequestId: null,
    error: false,
    errorMsg: null,
    forgotPasswordCompleted: false,
    resetPasswordCompleted: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(resetRequestState, (state) => {
                state.loading = initialState.loading;
                state.currentRequestId = initialState.currentRequestId;
                state.error = initialState.error;
                state.errorMsg = initialState.errorMsg;
            })
            .addCase(logout.fulfilled, () => authSlice.getInitialState())
            .addCase(forgotPassword.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = null;
                    state.forgotPasswordCompleted = true;
                }
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.inflightRequests = state.inflightRequests || {};
                    state.inflightRequests.resetPassword = null;
                    state.resetPasswordCompleted = true;
                }
            })
            .addMatcher(isAnyOf(login.fulfilled, fetchCurrentUser.fulfilled), (state, action) => {
                const { requestId } = action.meta;
                if (state.loading && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = null;
                    state.currentUser = action.payload;
                }
            })
            .addMatcher(
                isAnyOf(
                    resetPassword.pending,
                    forgotPassword.pending,
                    login.pending,
                    fetchCurrentUser.pending,
                    logout.pending,
                ),
                (state, action) => {
                    const { requestId } = action.meta;
                    if (state.loading === 'idle') {
                        state.loading = 'pending';
                        state.currentRequestId = requestId;
                    }
                },
            )
            .addMatcher(
                isAnyOf(
                    resetPassword.rejected,
                    forgotPassword.rejected,
                    login.rejected,
                    fetchCurrentUser.rejected,
                    logout.rejected,
                ),
                (state, action) => {
                    const { requestId } = action.meta;
                    if (state.loading === 'pending' && state.currentRequestId === requestId) {
                        state.loading = 'idle';
                        state.error = action.error;
                        state.currentRequestId = undefined;
                        state.errorMsg = action.error.message;
                    }
                },
            );
    },
});

// Extract the action creators object and the reducer
const { reducer } = authSlice;

export { fetchCurrentUser, forgotPassword, login, logout, resetPassword };

// Export the reducer, either as a default or named export
export default reducer;
