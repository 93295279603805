/* FILE NOTES
buildQuestionComponent()
- puts inbound input information into a dynamicInput, and send to render
- this is the last stop for the question routines

ReportSection
- builds top level questions
-- passes the question information to buildQuestionComponent(), where it is put in a UI component

- if any of the top level questions have children
-- determineChildQuestions(question)
--- if we the incoming question is a repeating group, then iterate over the children questions to match the value of the inbound questions answer
--- if the parent is a repeating group, we update it's ID to `${childQuestion.id}-${i}-${question.id}`
--- if the parent is NOT a repeating group, we do not update the ID with an iteration index or parent id
-- pass child question information over to buildChildQuestionComponents()

buildChildQuestionComponents()
-- this function takes the question that needs to be built, which will be an L2 or L3 question, as L2's loop over this with their children
-- determine if the parent question was a repeating group, as this influences the UI presentation of the question relative to others
-- pass the question information to buildQuestionComponent(), where it is put in a UI component
-- if the inbound questions has children, (only relevant to L2 question), trigger determineChildQuestions
// this is where it gets weird
--- determineChildQuestions()
--- this function builds a return value of all it's activity, and passes it back to be rendered
---- determine if the parent (which would be an L2 Question) is a repeating group, and what it's value is
---- iterate in a for loop for the value of the L2 repeating group question, or just 1 time if the L2 parent is not a repeating group
-----  remember, if the parent is a repeating group, we need the entire child of that parent set to be replicated x number of times
---- inside the loop, if repeating group, add group label.
---- inside the loop, map() the question set of the parent
----- if the L2 parent is a repeating group, we need to amend it's ID for uniqueness
----- if the L1 parent is a repeating group (the great grandparent), we also need to amend it's ID for uniqueness, which we do by passing it's assumed always unique parent id down to it
------ as a result, we ALWAYS update the id at this level to `${current question id}-${I}-${parent.id}`;
----- pass the return value to the parent function, which will then add to the UI

// Id naming convention
L1 = top level
L2 = first level of children
L3 = second level of children

// TERMONOLOGY NOTE
- lookUpId: this represents the id of a question as used within the app. this id is a compounded value of the L2 question, ex `questionId-repeatingGroupIndex-parentQuestionId`

L1
- {questionId}
L2
- {questionId}
L2 when parent L1 is a repeating group
- {questionId}-{repeatingGroupIndex}-{parentQuestionId}
All L3 Questions
- {questionId}-{repeatingGroupIndex}-{lookUpId}
- NOTE for L3, we iterare over the parent repeating group index to create unique ids. if no repeating group, then the index is 0, but still ammended
- it's possible for an L3 question to be a grandchild of an L1 repeating group, so we need to ammend the parent id to the child id for uniqueness
*/

import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { parse, parseISO, isValid } from 'date-fns';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

import ConditionalQuestions from '../../../../components/inputs/conditional-questions';
import DynamicInput from '../../../../components/inputs/dynamic-input';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import InputStyles from '../../../../styles/inputs';
import Styles from '../../styles';
import Colors from '../../../../styles/colors';
import QuestionWrapper from './question-wrapper';
import testConditional from '../../../../utils/test-conditional';

const buildFieldItemData = (question) => {
    const fieldItem = {
        name: question.text,
        required: question.required,
        key: question.id,
        type: question.type,
    };

    if (question.type === 'select' || question.type === 'radioButton' || question.type === 'checkBoxes') {
        fieldItem.options = question.options;
    }

    if (question.type === 'instructions') {
        fieldItem.instructions = question.instructions;
    }
    if (question.type === 'repeatingGroup') {
        // add select menu with options for number of times to repeat
        fieldItem.options = ['0', '1', '2', '3', '4', '5'];
    }

    return fieldItem;
};

const getDateTimeValue = (value, formatString) => {
    if (value) {
        const rehydrationValue = parseISO(value, formatString, new Date());
        if (isValid(rehydrationValue)) return rehydrationValue;
        // if we get here, we are working with a value from the component selector, not a rehyrdation value
        return parse(value, formatString, new Date());
    }

    return null;
};
 // this implements the the UI for all questions being rendered
const buildQuestionComponent = (
    question,
    register,
    control,
    errors,
    watchValues,
    handleDateChange,
    handleTimeChange,
    setUploadedAttachments,
    setValue,
    disabled,
) => {
    const fieldItem = buildFieldItemData(question);
    return (
        <DynamicInput
            register={register}
            onDateChange={handleDateChange}
            onTimeChange={handleTimeChange}
            dateValue={question.type === 'date' ? getDateTimeValue(watchValues[question.id], 'MM/dd/yyyy') : null}
            timeValue={question.type === 'time' ? getDateTimeValue(watchValues[question.id], 'hh:mm aa') : null}
            item={fieldItem}
            control={control}
            errors={errors}
            hiddenLabel={false}
            key={question.id}
            renderLogic={question.renderLogic}
            setUploadedAttachments={setUploadedAttachments}
            setValue={setValue}
            disabled={disabled}
        />
    );
};

// all questions that have children will be built here
const buildChildQuestionComponents = (
    question,
    register,
    control,
    errors,
    parentQuestion,
    watchValues,
    handleDateChange,
    handleTimeChange,
    setUploadedAttachments,
    setValue,
    disabled,
) => {
    // determine if the parent question is a repeating group
        // determine the number of times to repeat the children
        // visually cluster the children

    const parentWatchValue = watchValues[parentQuestion.id];
    const parentIsRepeatingGroup = parentQuestion.type === 'repeatingGroup';

    const determineChildQuestions = () => {
        const parentL2WatchValue = watchValues[question.id];
        const parentL2IsRepeatingGroup = question.type === 'repeatingGroup';
        const returnValue = [];

        // TODO: if performance is an issue, consider eliminating the need for a "for" loop
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < (parentL2IsRepeatingGroup ? parseInt(parentL2WatchValue, 10) : 1); i++) {
            returnValue.push(
                <Box key={`${question.id}-${i}-box`} style={{ borderBottom: (parentL2IsRepeatingGroup && (i + 1) < parseInt(parentL2WatchValue, 10)) && `1px solid ${Colors.lavenderGray}`, marginLeft: 30 }}>
                    {parentL2IsRepeatingGroup && (
                        <Typography variant="h2" style={{ paddingBottom: 10, paddingTop: 10 }}>
                            {`${question.groupLabel || ''} ${i + 1}`}
                        </Typography>
                    )}
                    {question.questions.map((childQuestion) => {
                        const ammendedQuestion = { ...childQuestion };
                        // all L3 children need unique ids, cause they could be children of children of repeating groups
                        ammendedQuestion.id = `${childQuestion.id}-${i}-${question.id}`;

                        return (
                            buildChildQuestionComponents(
                                ammendedQuestion,
                                register,
                                control,
                                errors,
                                question, // parentQuestion
                                watchValues,
                                handleDateChange,
                                handleTimeChange,
                                setUploadedAttachments,
                                setValue,
                                disabled,
                            )
                        );
                    })}
                </Box>,
            );
        }
        return returnValue;
    };

    return (
        <Box key={`${question.id}-box`} style={{ paddingBottom: 15, paddingTop: 15 }}>
            <ConditionalQuestions
                key={`${question.id}-conditional`}
                // testConditinal() defaults to true, so we can just pass the test if no condition present (which is the case of repeatingGroups)
                condition={testConditional(question.renderLogic, parentWatchValue)}
                hideBorder={parentIsRepeatingGroup}
                hideContainer
            >
                {buildQuestionComponent(
                    question,
                    register,
                    control,
                    errors,
                    watchValues,
                    handleDateChange,
                    handleTimeChange,
                    setUploadedAttachments,
                    setValue,
                    disabled,
                )}
                {/* if the inbound question is a L2, then it may have children, handle that here */}
                {/* L3 questions will interact with this too, but they wont enter this block */}
                {question.questions && determineChildQuestions()}
            </ConditionalQuestions>
        </Box>
    );
};

// this manages the top level questions and their children
const ReportSection = ({
    watchValues,
    questions,
    instructions,
    control,
    register,
    errors,
    handleDateChange,
    handleTimeChange,
    setUploadedAttachments,
    setValue,
    classes,
}) => {
    const determineChildQuestions = (question, disabled) => {
        const parentWatchValue = watchValues[question.id];
        const parentIsRepeatingGroup = question.type === 'repeatingGroup';
        const returnValue = [];

        if (question.questions.length) {
            // TODO: if performance is an issue, consider eliminating the need for a "for" loop
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < (parentIsRepeatingGroup ? parseInt(parentWatchValue, 10) : 1); i++) {
                returnValue.push(
                    <Box key={`${question.id}-${i}-box`} style={{ borderBottom: parentIsRepeatingGroup && `1px solid ${Colors.lavenderGray}`, marginLeft: 30 }}>
                        {parentIsRepeatingGroup && (
                            <Typography variant="h2" style={{ paddingBottom: 10, paddingTop: 10 }}>
                                {`${question.groupLabel || ''} ${i + 1}`}
                            </Typography>
                        )}
                        {question.questions.map((childQuestion) => {
                            const ammendedQuestion = { ...childQuestion };
                            if (parentIsRepeatingGroup) {
                                // we need to make the id unique for each child question
                                // if the grandparent is a repeating group,
                                // we have made the parent id unique, so we ammend it to the child id
                                ammendedQuestion.id = `${childQuestion.id}-${i}-${question.id}`;
                            }
                            return (
                                buildChildQuestionComponents(
                                    ammendedQuestion,
                                    register,
                                    control,
                                    errors,
                                    question, // parentQuestion
                                    watchValues,
                                    handleDateChange,
                                    handleTimeChange,
                                    setUploadedAttachments,
                                    setValue,
                                    disabled,
                                )
                            );
                        })}
                    </Box>,
                );
            }
        }
        return returnValue;
    };

    return (
        <Grid item xs={12}>
            <Stack spacing={2}>
                <Box>{instructions}</Box>
                {questions.map((question) => (
                    <QuestionWrapper
                        questionId={question.id}
                        questionType={question.type}
                        key={`${question.id}-wrapper`}
                        classes={classes}
                        render={({ disabled }) => (
                            <>
                                {buildQuestionComponent(
                                    question,
                                    register,
                                    control,
                                    errors,
                                    watchValues,
                                    handleDateChange,
                                    handleTimeChange,
                                    setUploadedAttachments,
                                    setValue,
                                    disabled,
                                )}
                                {determineChildQuestions(question, disabled)}
                            </>
                        )}
                    />
                ))}
            </Stack>
        </Grid>
    );
};

ReportSection.defaultProps = {
    instructions: '',
};

ReportSection.propTypes = {
    classes: PropTypes.object.isRequired,
    instructions: PropTypes.string,
    questions: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    watchValues: PropTypes.object.isRequired,
    handleDateChange: PropTypes.func.isRequired,
    handleTimeChange: PropTypes.func.isRequired,
    setUploadedAttachments: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(ReportSection, combinedStyles);
