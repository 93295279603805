import Colors from '../../styles/colors';

const styles = () => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${Colors.darkGrey}`,
        borderRadius: 3,
        maxWidth: 'max-content',
    },
    defaultActionContainer: {
        borderRight: `1px solid ${Colors.darkGrey}`,
    },
    defaultActionButton: {
        color: Colors.darkGrey,
        fontWeight: 600,
        padding: '0 7px',
        borderRadius: 0,
        minWidth: 60,
        height: 30,
        fontSize: '0.9375rem',
        whiteSpace: 'nowrap',
    },
    expandButton: {
        color: Colors.darkGrey,
        padding: '0 3px',
        borderRadius: 0,
        whiteSpace: 'nowrap',
        height: 30,
    },
    popper: {
        zIndex: 1000,
    },
    paper: {
        borderRadius: 3,
        boxShadow: '0 5px 10px 0 rgba(25,25,25,0.3)',
    },
});

export default styles;
