import Colors from '../../styles/colors';

const styles = () => ({
    mobileInstructionsWrapper: {
        backgroundColor: Colors.antiflashWhite,
        padding: 0,
        justifyContent: 'flex-start',
        minHeight: 'fit-content',
    },
    mobileInstructionsWrapperExpanded: {
        minHeight: 'fit-content !important',
        margin: '0 !important',
    },
    mobileInstructionsSummaryContent: {
        maxWidth: 'fit-content',
        margin: 0,
    },
    mobileInstructionsSummaryText: {
        color: Colors.persianBlue,
        fontWeight: 600,
    },
    mobileInstructionsExpandButton: {
        color: Colors.persianBlue,
        fontWeight: 600,
        padding: 0,
    },
    mobileInstructionsContent: {
        backgroundColor: Colors.antiflashWhite,
    },
    testerComment: {
        width: '100%',
    },
    container: {
        marginTop: 15,
    },
});

export default styles;
