import Colors from '../../styles/colors';

const styles = () => ({
    popper: {
        zIndex: 1,
        '& .arrow': {
            position: 'absolute',
            fontSize: 7,
            width: '3em',
            height: '3em',
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
            },
        },
        '&[x-placement*="bottom"] .arrow': {
            top: 0,
            left: 25,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${Colors.antiflashWhite} transparent`,
            },
        },
        '&[x-placement*="top"] .arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${Colors.antiflashWhite} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] .arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${Colors.antiflashWhite} transparent transparent`,
            },
        },
        '&[x-placement*="left"] .arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${Colors.antiflashWhite}`,
            },
        },
    },
    popperBody: {
        color: Colors.black,
        backgroundColor: Colors.antiflashWhite,
        padding: '13px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
    },
});

export default styles;
