import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import { getTesterSelector } from '../../../redux/testers/selectors';

import InviteEditTesterForm from './form';
import Icon from './images/tester-phone.png';
import Styles from './styles';
import DashboardStyles from '../styles';
import CombineStyles from '../../../utils/combine-styles';

const InviteEditTester = ({ classes }) => {
    const { testerId } = useParams();
    const getTester = useSelector(getTesterSelector);
    const tester = getTester(testerId);
    const isEditing = !!tester;

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            <Grid item xs={12} className={classes.backLink}>
                <Link to="/dashboard/testers">Back to Manage Testers</Link>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h1">{isEditing ? 'Edit ' : 'Invite New '} Tester</Typography>
            </Grid>
            <Grid item xs={12} className={classes.description}>
                <Typography variant="body1">All questions are required unless marked optional</Typography>
            </Grid>

            <Grid item container xs={12}>
                <Grid item xs={6}>
                    <InviteEditTesterForm testerId={testerId} tester={tester} />
                </Grid>

                <Grid item xs={6}>
                    <div className={classes.icon}>
                        <img src={Icon} alt="" />
                    </div>
                    <div className={classes.note}>
                        <Typography variant="body1">
                            <b>Note:</b> Call the tester to make sure you get all of their information before sending
                            the invitation. This will be submitted each time with their report.
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

InviteEditTester.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(DashboardStyles, Styles);
export default withStyles(InviteEditTester, combinedStyles);
