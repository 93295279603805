import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';

import ReportHome from '../../routes/report';
import ReportHeader from './components/header';

const ReportLayout = ({ currentReport, flaggedVersion }) => {
    const navigate = useNavigate();

    const onBackButtonClick = useCallback(() => {
        navigate('/dashboard/reports');
    }, [navigate]);

    return (
        <>
            <ReportHeader onBackButtonClick={onBackButtonClick} backButtonLabel="Back to Dashboard" />
            {currentReport?.id && (
                <main>
                    <Container maxWidth="md">
                        <ReportHome report={currentReport} currentVersion={flaggedVersion} />
                    </Container>
                </main>
            )}
        </>
    );
};

ReportLayout.propTypes = {
    currentReport: PropTypes.object.isRequired,
    flaggedVersion: PropTypes.object.isRequired,
};

export default ReportLayout;
