import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

import { useThemeDispatch, increaseFontSize, decreaseFontSize } from '../../wiring/theme-context';

import Styles from './styles';

const DisplayOptionsControlPanel = ({ classes }) => {
    const dispatch = useThemeDispatch();

    const handleIncreaseFontSize = useCallback(() => {
        dispatch(increaseFontSize());
    }, [dispatch]);

    const handleDecreaseFontSize = useCallback(() => {
        dispatch(decreaseFontSize());
    }, [dispatch]);

    return (
        <div className={`row ${classes.fontSizeControl}`}>
            <div className="col">
                <Typography variant="body1">Text size:</Typography>
            </div>
            <div className="col-8">
                <ButtonGroup
                    variant="text"
                    color="primary"
                    classes={{
                        groupedTextHorizontal: classes.fontSizeButton,
                    }}
                    aria-label="increase or decrease font size"
                >
                    <Button className={classes.fontSizeDecrease} onClick={handleDecreaseFontSize}>
                        A
                    </Button>
                    <Button className={classes.fontSizeIncrease} onClick={handleIncreaseFontSize}>
                        A
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

DisplayOptionsControlPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(DisplayOptionsControlPanel, Styles);
