import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

const NotificationSnackbar = ({ open, onClose, text, severity, autoHideDisabled }) => (
    <Snackbar
        open={open}
        autoHideDuration={autoHideDisabled ? null : 5000}
        onClose={onClose}
        anchorOrigin={{ vertical: severity === 'success' ? 'bottom' : 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
    >
        <Alert
            severity={severity}
            icon={false}
            action={
                <Button type="button" onClick={onClose} variant="secondary">
                    Dismiss
                </Button>
            }
        >
            {text}
        </Alert>
    </Snackbar>
);
NotificationSnackbar.defaultProps = {
    text: '',
    severity: 'success',
    autoHideDisabled: null,
};

NotificationSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    text: PropTypes.string,
    severity: PropTypes.oneOf(['success', 'error', 'info']),
    autoHideDisabled: PropTypes.bool,
};

export default NotificationSnackbar;
