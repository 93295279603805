/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import noop from 'lodash/noop';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import API from '../../api';
import { APIError, resetRequestState } from '../app';

const getSitesForReportsFilters = createAsyncThunk('sites/getSitesForReportsFilters', async (onSuccess = noop) => {
    const response = await API.fetchSites();

    const preparedData = response.map((site) => ({
        label: site.siteName,
        value: site.id,
        ...site,
    }));
    const sortedByName = preparedData.sort((a, b) => a.label.localeCompare(b.label));

    if (onSuccess) onSuccess(sortedByName);
});

const fetchSites = createAsyncThunk('sites/getSites', async (payload = {}) => {
    const data = await API.fetchSitesList(payload);

    return data;
});

const createSite = createAsyncThunk(
    'sites/createSite',
    async ({ site, onSuccess = noop, onError = noop }, thunkApi) => {
        try {
            const data = await API.createSite(site);

            onSuccess(data.id);
            return data;
        } catch (error) {
            thunkApi.dispatch(APIError({ text: 'Error creating site.' }));
            onError();
        }
    },
);

const updateSite = createAsyncThunk(
    'sites/updateSite',
    async ({ siteId, site, onSuccess = noop, onError = noop }, thunkApi) => {
        try {
            const result = await API.updateSite(siteId, site);

            onSuccess(siteId);
            return result;
        } catch (error) {
            thunkApi.dispatch(APIError({ text: 'Error updating site.' }));
            onError();
        }
    },
);

const deleteSite = createAsyncThunk('sites/deleteSite', async ({ id, onSuccess = noop, onError = noop }, thunkApi) => {
    try {
        await API.deleteSite(id);
        onSuccess();
    } catch (error) {
        thunkApi.dispatch(APIError({ text: 'Error deleting site.' }));
        onError();
    }
});

const initialState = {
    data: [],
    currentRequestId: null,
    errorMsg: null,
    loading: 'idle',
    total: 0,
};

const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetRequestState, (state) => {
                state.errorMsg = initialState.errorMsg;
                state.loading = initialState.loading;
                state.currentRequestId = initialState.currentRequestId;
            })
            .addCase(fetchSites.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = action.payload.testSites;
                    state.total = action.payload.total;
                }
            })
            .addCase(updateSite.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = state.data.map((site) => {
                        if (site.id === action.payload.id) {
                            return action.payload;
                        }
                        return site;
                    });
                }
            })
            .addCase(deleteSite.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = state.data.filter((site) => site.id !== action.meta.arg.id);
                }
            })
            .addCase(createSite.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = [...state.data, action.payload];
                }
            })
            .addMatcher(
                isAnyOf(updateSite.pending, deleteSite.pending, createSite.pending, fetchSites.pending),
                (state, action) => {
                    if (state.loading === 'idle') {
                        state.loading = 'pending';
                        state.currentRequestId = action.meta.requestId;
                    }
                },
            )
            .addMatcher(
                isAnyOf(deleteSite.rejected, createSite.rejected, fetchSites.rejected, updateSite.rejected),
                (state, action) => {
                    const { requestId } = action.meta;
                    if (state.loading === 'pending' && state.currentRequestId === requestId) {
                        state.loading = 'idle';
                        state.error = action.error;
                        state.currentRequestId = undefined;
                    }
                },
            );
    },
});

const { reducer } = sitesSlice;

export { getSitesForReportsFilters, fetchSites, createSite, updateSite, deleteSite };

export default reducer;
