import { useLocation, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getCurrentReport } from '../../redux/current-report/selectors';
import ReportLayout from '../../layouts/report';
import ReportSectionLayout from '../../layouts/report/section';
import { getFlaggedVersion } from '../../redux/reports';

const ReportRouter = () => {
    const location = useLocation();
    const currentReport = useSelector(getCurrentReport);
    const flaggedVersion = getFlaggedVersion(currentReport);

    return (
        <Routes location={location}>
            <Route
                exact
                path="/section/:sectionId/*"
                element={<ReportSectionLayout currentReport={currentReport} flaggedVersion={flaggedVersion} />}
            />
            <Route
                exact
                path="/"
                element={<ReportLayout currentReport={currentReport} flaggedVersion={flaggedVersion} />}
            />
        </Routes>
    );
};

export default ReportRouter;
