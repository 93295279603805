import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { withStyles } from 'tss-react/mui';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers/yup';

import { uploadOutcome } from '../../../../redux/reports';
import FileUploader from '../../../../components/file-uploader';
import Select from '../../../../components/inputs/select';
import validationSchema from '../../../../validation/validation-schema-outcome';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import DialogStyles from '../../../../styles/dialog';
import { APIError } from '../../../../redux/app';

import Styles from './styles';

const UploadOutcomeDialog = ({ classes, isDialogOpen, handleCloseDialog, reportDetails }) => {
    const dispatch = useDispatch();

    const { id, testSite, tester, testDate, previousOutcome, previousOutcomeFile } = reportDetails;

    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            outcome: null,
            outcomeFile: null,
            previousOutcomeFile: null,
        },
    });

    const { ref } = register('previousOutcomeFile');

    useEffect(() => {
        reset({
            outcome: previousOutcome || null,
            outcomeFile: null,
            previousOutcomeFile: previousOutcomeFile || null,
        });
    }, [previousOutcome, previousOutcomeFile, reset]);

    const handleFileUploadClear = useCallback(() => {
        if (previousOutcomeFile) {
            setValue('previousOutcomeFile', '');
        }
    }, [previousOutcomeFile, setValue]);

    const handleFileUploadChange = useCallback(
        (uploadedFile) => {
            setValue('outcomeFile', uploadedFile);
        },
        [setValue],
    );

    const handleSave = useCallback(
        (values) => {
            const onSuccess = () => handleCloseDialog();
            const onError = (error) => {
                // eslint-disable-next-line no-console
                console.error(error);
                dispatch(APIError({ text: 'Error Saving, please try again.' }));
                // don't close dialog if API call fails
                // handleCloseDialog();
            };

            // if an outcome file has already been uploaded and was not replaced
            if (previousOutcomeFile && (!values.outcomeFile || values.outcomeFile.length === 0)) {
                // just update the outcome value don't upload a new file
                dispatch(uploadOutcome({ reportId: id, outcomeData: { outcome: values.outcome }, onSuccess, onError }));
            } else {
                dispatch(uploadOutcome({ reportId: id, outcomeData: values, onSuccess, onError }));
            }
        },
        [id, dispatch, handleCloseDialog, previousOutcomeFile],
    );

    // There is a short period of time between when a Dialog is opened and when it gets passed reportDetails
    // so we need to handle that gap and return null until reportDetails is passed
    if (!id) {
        return null;
    }

    return (
        <Dialog
            classes={{
                paper: classes.dialogContainer,
            }}
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="upload-dialog-title"
            fullWidth
        >
            <form onSubmit={handleSubmit(handleSave)} noValidate>
                <DialogTitle id="upload-dialog-title" className={classes.dialogTitle}>
                    <Grid item container spacing={2} alignItems="center" direction="column" justifyContent="center">
                        <Grid item sm={12} container spacing={2} justifyContent="flex-end">
                            <IconButton aria-label="cancel adding the outcome" onClick={handleCloseDialog}>
                                <CloseIcon
                                    classes={{
                                        root: classes.dialogCloseButton,
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item container sm={12} justifyContent="center">
                            <Typography variant="h1" component="h2">
                                {previousOutcomeFile ? 'Replace' : 'Add'} the outcome
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid item container className={classes.detailsWrapper} spacing={2}>
                        <Grid item sm={4}>
                            <Typography variant="body1" className={classes.detailTitle}>
                                Site
                            </Typography>
                            <Typography variant="body1" className={classes.detailContent}>
                                {testSite.siteName}
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant="body1" className={classes.detailTitle}>
                                Tester
                            </Typography>
                            <Typography variant="body1" className={classes.detailContent}>
                                {tester}
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant="body1" className={classes.detailTitle}>
                                Date of Visit/Phone Test
                            </Typography>
                            <Typography variant="body1" className={classes.detailContent}>
                                {testDate}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <ol className={classes.list}>
                            <li>
                                <Box>
                                    <Typography id="outcomeFile-label" variant="body1" className={classes.listItem}>
                                        Attach the outcome document (DOC or PDF)
                                    </Typography>
                                    <div>
                                        <input type="hidden" ref={ref} name="previousOutcomeFile" />
                                        <FileUploader
                                            name="outcomeFile"
                                            labelledBy="outcomeFile-label"
                                            register={register}
                                            onChange={(uploadedFile) => handleFileUploadChange(uploadedFile)}
                                            errors={errors}
                                            required
                                            allowedFileTypes={['.pdf', '.doc', '.docx']}
                                            previousFilename={previousOutcomeFile || null}
                                            onClear={handleFileUploadClear}
                                        />
                                    </div>
                                </Box>
                            </li>
                            <li>
                                <Box>
                                    <Typography id="select-label" variant="body1" className={classes.listItem}>
                                        Select the outcome
                                    </Typography>
                                    <div>
                                        <Select
                                            labelledBy="select-label"
                                            name="outcome"
                                            errors={errors}
                                            required
                                            control={control}
                                            displayEmpty
                                        >
                                            <MenuItem disabled value="">
                                                Select -
                                            </MenuItem>
                                            <MenuItem value="INCONCLUSIVE">Inconclusive</MenuItem>
                                            <MenuItem value="POSITIVE">Positive</MenuItem>
                                            <MenuItem value="NEGATIVE">Negative</MenuItem>
                                        </Select>
                                    </div>
                                </Box>
                            </li>
                        </ol>
                    </Grid>
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    <Button
                        className={classes.outlineButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.outlineButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        onClick={handleCloseDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={`${classes.primaryButton}`}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        type="submit"
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

UploadOutcomeDialog.defaultProps = {
    reportDetails: {},
};

UploadOutcomeDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    reportDetails: PropTypes.object,
};

const combinedStyles = CombineStyles(ButtonStyles, DialogStyles, Styles);
export default withStyles(UploadOutcomeDialog, combinedStyles);
