import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
// TODO: any issue with this being loaded in prod? it's a dev only need
import axe from '@axe-core/react';

import { Provider } from 'react-redux';

import ErrorFallback from './components/error-fallback';

import App from './routes';

import 'sanitize.css/sanitize.css';
import './index.css';
import ScrollToTop from './components/scroll-to-top';
import GoogleAnalytics from './components/google-analytics';
import { init as sentryInit } from './utils/sentry';

import store from './redux';
import { resetRequestState } from './redux/app';

if (!import.meta.env.PROD) {
    axe(React, ReactDOM, 1000);
}

const container = document.getElementById('root');
const root = createRoot(container);
const persistor = persistStore(store);

// initialize sentry
sentryInit();

const onBeforeLift = () => {
    store.dispatch(resetRequestState());
};

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
            <BrowserRouter>
                <Sentry.ErrorBoundary fallback={() => <ErrorFallback />}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ScrollToTop />
                        <GoogleAnalytics />
                        <App store={store} />
                    </LocalizationProvider>
                </Sentry.ErrorBoundary>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
);
