import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from '../../../components/inputs/textinput';
import Select from '../../../components/inputs/select';
import TextArea from '../../../components/inputs/textarea';
import states from '../invite-edit-tester/states';
import ButtonStyles from '../../../styles/buttons';
import Checkbox from '../../../components/inputs/checkbox-item';
import validationSchema from '../../../validation/validation-schema-sites';
import { createSite, updateSite } from '../../../redux/sites';
import useSnackbar from '../../../utils/use-snackbar';
import NotificationSnackbar from '../../../components/notification-snackbar';
import makeEmptyEntriesNull from '../../../utils/make-empty-entries-null';

const CreateEditSiteForm = ({ site, classes }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();
    const [litigationIsChecked, setLitigationIsChecked] = useState(!!(site && site.isInLitigation));
    const isEditing = !!site;

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            siteName: site?.siteName || null,
            address: site?.address || null,
            city: site?.city || null,
            state: 'MI',
            zipcode: site?.zipcode || null,
            censusNo: site?.censusNo || null,
            municipality: site?.municipality || null,
            phone: site?.phone || null,
            blockGroup: site?.blockGroup || null,
            notes: site?.notes || null,
            isInLitigation: site?.isInLitigation || false,
        },
    });

    const onError = () => {
        handleOpenSnackbar({ text: 'An unknown error occurred. Please try again later.', type: 'error' });
    };

    const onSubmitSuccess = (siteId) => {
        navigate(`/dashboard/sites/${siteId}`);
        handleOpenSnackbar({ text: 'Saved successfully', type: 'success' });
    };

    const onSubmitNew = (values) => {
        const newSiteValues = { ...makeEmptyEntriesNull(values), isInLitigation: litigationIsChecked };
        dispatch(createSite({ site: newSiteValues, onSuccess: onSubmitSuccess, onError }));
        reset();
    };

    const onSubmitEdit = (values) => {
        const updateValues = { ...makeEmptyEntriesNull(values), isInLitigation: litigationIsChecked };
        dispatch(updateSite({ siteId: site.id, site: updateValues, onSuccess: onSubmitSuccess, onError }));
    };

    const handleCancel = () => {
        navigate('/dashboard/sites');
    };

    return (
        <form onSubmit={handleSubmit(isEditing ? onSubmitEdit : onSubmitNew)} noValidate>
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        name="siteName"
                        label="Name *"
                        errors={errors}
                        required
                        register={register}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name="address"
                        label="Address *"
                        errors={errors}
                        required
                        register={register}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            name="city"
                            label="City *"
                            errors={errors}
                            required
                            register={register}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Select name="state" label="State" errors={errors} required control={control} displayEmpty>
                            {states.map((state) => (
                                <MenuItem key={state} value={state}>
                                    {state}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            name="zipcode"
                            label="Zip Code"
                            register={register}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            name="phone"
                            label="Phone Number"
                            register={register}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            name="municipality"
                            label="Municipality"
                            register={register}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            name="censusNo"
                            label="Census Number"
                            register={register}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            name="blockGroup"
                            label="Block Group"
                            register={register}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <TextArea name="notes" label="Notes" errors={errors} register={register} rowsMin={3} />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Checkbox
                            name="isInLitigation"
                            label="Site currently in litigation"
                            onChange={(event) => setLitigationIsChecked(!!event.target.checked)}
                            isChecked={litigationIsChecked}
                            value={`${litigationIsChecked}`}
                            register={register}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Button
                            className={`${classes.primaryButton}`}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            aria-label={isEditing ? 'Save updates to site' : null}
                            type="submit"
                        >
                            {isEditing ? 'Save' : 'Create Site'}
                        </Button>
                        &nbsp;
                        {isEditing && (
                            <Button
                                className={`${classes.secondaryButton}`}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                aria-label="Return to sites"
                                type="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <NotificationSnackbar {...snackbarProps} />
        </form>
    );
};

CreateEditSiteForm.propTypes = {
    site: PropTypes.shape({
        id: PropTypes.number,
        siteName: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipcode: PropTypes.string,
        censusNo: PropTypes.string,
        municipality: PropTypes.string,
        phone: PropTypes.string,
        blockGroup: PropTypes.string,
        notes: PropTypes.string,
        isInLitigation: PropTypes.bool,
        length: PropTypes.number,
    }),
    classes: PropTypes.object.isRequired,
};

CreateEditSiteForm.defaultProps = {
    site: null,
};

export default withStyles(CreateEditSiteForm, ButtonStyles);
