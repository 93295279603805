import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import noop from '../../../utils/noop';

const TertiaryButton = ({ children, onClick, ...props }) => (
    <Button variant="text" disableElevation disableFocusRipple disableRipple onClick={onClick} {...props}>
        {children}
    </Button>
);

TertiaryButton.defaultProps = {
    children: null,
    onClick: noop,
};

TertiaryButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
};

export default TertiaryButton;
