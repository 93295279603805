export const USER_ROLES = {
    orgAdmin: {
        value: 'orgAdmin',
        label: 'Organization Admin',
        pluralLabel: 'Organization Admins',
        permissions: [
            'View and Edit Internal Users',
            'View and Edit Testers',
            'View and Edit Sites',
            'View and Edit Tests',
            'Request Modifications',
            'View Reports',
        ],
    },
    admin: {
        value: 'admin',
        label: 'Admin',
        pluralLabel: 'Admins',
        permissions: [
            'Manage Testers',
            'Manage Sites',
            'Manage Internal Users',
            'Request Report Changes',
            'Manage Reports',
        ],
    },
    tester: {
        value: 'tester',
        label: 'Tester',
    },
    testCoordinator: {
        value: 'testCoordinator',
        label: 'Test Coordinator',
        pluralLabel: 'Test Coordinators',
        permissions: ['Manage Testers', 'Manage Sites', 'Request Report Changes', 'Manage Reports'],
    },
    attorney: {
        value: 'attorney',
        label: 'Attorney',
        pluralLabel: 'Attorneys',
        permissions: ['View Testers', 'View Sites', 'Request Report Changes', 'Manage Reports'],
    },
};

export const REPORT_HISTORY_ACTIONS = {
    reportCreated: {
        value: 'reportCreated',
        label: 'Report Created',
    },
    reportSubmitted: {
        value: 'reportSubmitted',
        label: 'Report Submitted',
    },
    reportReopened: {
        value: 'reportReopened',
        label: 'Report Reopened',
    },
    reportAssignedOutcome: {
        value: 'reportAssignedOutcome',
        label: 'Report Assigned Outcome',
    },
};

export const ADMINISTRATIVE_ROLES = [
    USER_ROLES.orgAdmin.value,
    USER_ROLES.admin.value,
    USER_ROLES.testCoordinator.value,
    USER_ROLES.attorney.value,
];

export default { USER_ROLES, ADMINISTRATIVE_ROLES };

export const TOTAL_ALLOWED_UPLOADS = 10;
export const ALLOWED_FILE_TYPES = [
    // documents
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.csv',
    '.ppt',
    '.pptx',
    'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/pdf',
    'application/msword',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/csv',
    '.txt',
    '.rtf',
    // images
    '.gif',
    '.png',
    '.jpeg',
    '.jpg',
    '.heic',
    '.tif',
    '.tiff',
    // audio # https://en.wikipedia.org/wiki/Audio_file_format
    '.wv',
    '.wma',
    '.webm',
    '.wav',
    '.mp4',
    '.mp3',
    '.m4a',
    '.m4b',
    '.m4p',
    '.aiff',
    '.alac',
    '.flac',
    '.ogg',
    '.oga',
    '.opus',
    // movie
    '.mov',
    '.avi',
    '.mpg',
    '.mpeg',
    '.wmv',
    '.m4v',
    '.mkv',
    '.webm',
    '.flv',
    '.vob',
    '.ogv',
];
