import { createTheme } from '@mui/material/styles';

import GeneralTheme from './material-theme';
import Colors from './colors';

const theme = createTheme({
    ...GeneralTheme,
    palette: {
        ...GeneralTheme.palette,
        background: {
            default: Colors.persianBlue,
        },
    },
});

export default theme;
