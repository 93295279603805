import { createSelector } from '@reduxjs/toolkit';

const getOrgsState = (state) => state.organizations;

// eslint-disable-next-line import/prefer-default-export
export const getOrganizations = createSelector(getOrgsState, (orgs) => orgs.results || []);

export const getSitesTotal = createSelector(getOrgsState, (orgs) => orgs.total || 0);

export const getActiveOrgizations = createSelector(getOrganizations, (orgs) => orgs.filter((org) => !org?.deletedAt));

export const getOrganizationSelector = createSelector(
    getActiveOrgizations,
    (orgs) => (orgId) => orgs.find((org) => `${orgId}` === `${org.id}`),
);

export const getOrgizationsList = createSelector(getOrganizations, (testers) => Object.values(testers) || []);

export const getTotalOrganizations = createSelector(getOrgsState, (state) => state.total);
