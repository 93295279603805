import PropTypes from 'prop-types';
import format from 'date-fns/format';
import noop from 'lodash/noop';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { ErrorMessage } from '@hookform/error-message';

import DatePicker from '../datepicker';
import RadioButton from '../radiobutton';
import RadioGroup from '../radiogroup';
import TimePicker from '../timepicker';
import TextField from '../textinput';
import CheckboxGroup from '../checkboxgroup';
import CheckboxItem from '../checkbox-item';
import Select from '../select';
import ReportAttachmentUploader from '../report-attachment-uploader';

const DynamicInput = ({
    item,
    errors,
    register,
    control,
    dateValue,
    timeValue,
    onDateChange,
    onTimeChange,
    hiddenLabel,
    setUploadedAttachments,
    setValue,
    disabled = false,
}) => {
    let validationRules = {};
    if (item.validationRules) validationRules = { ...validationRules, ...item.validationRules };

    // build regex from pattern in config
    if (validationRules.pattern) validationRules.pattern.value = new RegExp(validationRules.pattern.value);

    if (item.required) validationRules.required = 'Field required';

    if (item.type === 'date') {
        return (
            <DatePicker
                errors={errors}
                // control={control}
                rules={validationRules}
                {...register(item.key, validationRules)}
                name={item.key}
                label={hiddenLabel ? '' : item.name}
                value={dateValue}
                required={!!item.required}
                showRequired={!!(item.required && !hiddenLabel)}
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYYY"
                initialFocusedDate={format(new Date(), 'MM/dd/yyyy')}
                onChange={onDateChange}
                disabled={disabled}
            />
        );
    }
    if (item.type === 'time') {
        return (
            <TimePicker
                // lifted from volta, untested in this context
                {...register(item.key, validationRules)}
                errors={errors}
                name={item.key}
                label={hiddenLabel ? '' : item.name}
                value={timeValue}
                format="hh:mm aa"
                onChange={onTimeChange}
                fullWidth
                required={!!item.required}
                showRequired={!!(item.required && !hiddenLabel)}
                disabled={disabled}
            />
        );
    }

    if (item.type === 'select' || item.type === 'multiSelect' || item.type === 'repeatingGroup') {
        return (
            <Select
                errors={errors}
                name={item.key}
                label={hiddenLabel ? '' : item.name}
                control={control}
                multiple={item.type === 'multiSelect'}
                placeholder={item.placeholder || 'Select'}
                required={!!item.required}
                displayEmpty
                validationRules={validationRules}
                disabled={disabled}
            >
                {item.options.map((option) => (
                    <MenuItem value={option} key={`${item.key}-${option}`}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        );
    }

    if (item.type === 'shortText' || item.type === 'longText') {
        return (
            <TextField
                errors={errors}
                name={item.key}
                label={hiddenLabel ? '' : item.name}
                register={register}
                required={!!item.required}
                showRequired={!!(item.required && !hiddenLabel)}
                validationRules={validationRules}
                multiline={item.type === 'longText'}
                minRows={4}
                type="text"
                inputProps={{
                    maxLength: item.type === 'shortText' ? 200 : 524288,
                }}
                disabled={disabled}
            />
        );
    }

    if (item.type === 'radioButton') {
        return (
            <RadioGroup
                name={item.key}
                label={item.name}
                errors={errors}
                required={item.required}
                row
                control={control}
                disabled={disabled}
            >
                {item.options.map((option) => (
                    <RadioButton key={`${item.key}-${option}`} value={option} label={option} buttonStyle />
                ))}
            </RadioGroup>
        );
    }

    if (item.type === 'checkBoxes') {
        return (
            <CheckboxGroup name={item.key} label={item.name} options={item.options} errors={errors} control={control} disabled={disabled} required={!!item.required}>
                {item.options.map((option) => (
                    <CheckboxItem key={option} value={option.toString()} label={option} name={option} disabled={disabled} />
                ))}
            </CheckboxGroup>
        );
    }

    if (item.type === 'file') {
        return (
            <>
                <ReportAttachmentUploader
                    register={register}
                    setValue={setValue}
                    item={item}
                    setUploadedAttachments={setUploadedAttachments}
                    disabled={disabled}
                />
                <FormHelperText id={`${item.name}-error`} role="alert" aria-live="polite" error>
                    <ErrorMessage name={item.key} errors={errors} />
                </FormHelperText>
            </>
        );
    }

    if (item.type === 'instructions') {
        return (
            <Box>
                <Typography>{item.name}</Typography>
                <Typography>{item.instructions}</Typography>
            </Box>
        );
    }

    return null;
};

DynamicInput.propTypes = {
    item: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    dateValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    timeValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onDateChange: PropTypes.func,
    onTimeChange: PropTypes.func,
    hiddenLabel: PropTypes.bool,
    setUploadedAttachments: PropTypes.func,
    setValue: PropTypes.func,
    disabled: PropTypes.bool,
};

DynamicInput.defaultProps = {
    dateValue: null,
    timeValue: null,
    onDateChange: noop,
    onTimeChange: noop,
    setUploadedAttachments: noop,
    hiddenLabel: false,
    setValue: null,
    disabled: false,
};

export default DynamicInput;
