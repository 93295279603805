import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

import PrimaryButton from '../../../../../components/buttons/primary-button';
import SecondaryButton from '../../../../../components/buttons/secondary-button';
import ModalWrapper from '../../../../../components/modal-parts/modal-wrapper';
import ModalContentWrapper from '../../../../../components/modal-parts/modal-content-wrapper';
import ButtonStyles from '../../../../../styles/buttons';

const DeleteSectionModal = ({ classes, open, handleClose, handleDelete, handleDeletePayload }) => {
    const deleteSection = () => {
        handleDelete(handleDeletePayload);
        handleClose();
    };

    return (
        <ModalWrapper
            open={open}
            onClose={handleClose}
            aria-label="Select Section Icon"
            style={{ maxWidth: '80vw', margin: '0 auto' }}
        >
            <ModalContentWrapper>
                <Grid item container pb={2} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h2">
                            Delete Section
                        </Typography>
                        <Typography variant="body1" pt={2}>
                            Deleting this section will also delete any questions nested within.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container pt={2}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <SecondaryButton className={classes.secondaryButton} onClick={handleClose} sx={{ mr: 2 }}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton
                            className={classes.primaryButton}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            onClick={deleteSection}
                        >
                            Delete
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </ModalContentWrapper>
        </ModalWrapper>
    );
};

DeleteSectionModal.defaultProps = {
    handleDeletePayload: null,
};

DeleteSectionModal.propTypes = {
    handleDeletePayload: PropTypes.number,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(DeleteSectionModal, ButtonStyles);
