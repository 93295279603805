import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';

import { getReportAnswersSelector } from '../../../redux/reports/selectors';
import { getCurrentReportId } from '../../../redux/current-report/selectors';
import FileUploader from '../../file-uploader';
import { ALLOWED_FILE_TYPES, TOTAL_ALLOWED_UPLOADS } from '../../../utils/constants';

const ReportAttachmentUploader = ({ item, setValue, register, setUploadedAttachments }) => {
    const [uploaderHyrdrated, setUploaderHydrated] = useState(false);
    const reportId = useSelector(getCurrentReportId);
    const reportAnswers = useSelector(getReportAnswersSelector)(reportId);
    // filter answers to only include those that are relevant to the current question
    // we use the item key to filter the answers ex item key is ###, uploads will appear in answer set as ####_0, ####_2
    // we also want to filter out any null values
    const filteredAnswers = Object.entries(reportAnswers)
        // eslint-disable-next-line no-unused-vars
        .filter(([key, value]) => key.includes(`${item.key}_`)) //  && value !== null
        // eslint-disable-next-line no-unused-vars
        .map(([key, value]) => {
            // extract the value from after the "_" in the key
            // this is the index of the attachment
            // eslint-disable-next-line no-unused-vars
            const [_, index] = key.split('_');

            return { name: value, index };
        })
         // sort the attachments by index
         // this matters because we want to display the attachments in the order they were uploaded
         // and it affects how things are deleted and updated
         .sort((a, b) => a.index - b.index);

    // used to manage the component state
    // when this state changes, we update the parent component
    const [uploadedAttachmentsLocallyManagement, setUploadedAttachmentsLocallyManaged] = useState([null]);

    // REHYDRATE LOCAL STATE
    useEffect(() => {
        // we need an array value in uploadedAttachmentsLocallyManagement to hydrate the ui
        // this can [null], which is default, or an array of attachments
        // it can't just be an empty array
        if (filteredAnswers.length > 0) {
            setUploadedAttachmentsLocallyManaged(filteredAnswers);
        }
    }, [getReportAnswersSelector]);

    // everytime the local state changes
    // UPDATE PARENT STATE
    useEffect(() => {
        setUploadedAttachments(uploadedAttachmentsLocallyManagement);
    }, [setUploadedAttachments, uploadedAttachmentsLocallyManagement]);

    // triggered when a file is selected
    const handleFileUploadChange = useCallback(
        (fileNo, uploadedFile) => {
            const updatedAttachments = [...uploadedAttachmentsLocallyManagement];
            updatedAttachments[fileNo] = uploadedFile;
            setUploadedAttachmentsLocallyManaged(updatedAttachments);
        },
        [setUploadedAttachmentsLocallyManaged, uploadedAttachmentsLocallyManagement],
    );

    // triggered when a file is cleared
    const handleFileUploadClear = useCallback((fileNo) => {
            handleFileUploadChange(fileNo, null);

            if (uploadedAttachmentsLocallyManagement.length === 1) {
                // then we just deleted the last item
            }
    }, [handleFileUploadChange]);

    const handleAddButtonClick = useCallback(() => {
        if (uploadedAttachmentsLocallyManagement.length >= TOTAL_ALLOWED_UPLOADS) return;

        const updatedAttachments = [...uploadedAttachmentsLocallyManagement];
        updatedAttachments.push(null);
        setUploadedAttachmentsLocallyManaged(updatedAttachments);
    }, [setUploadedAttachmentsLocallyManaged, uploadedAttachmentsLocallyManagement]);

    useEffect(() => {
        // we've hydrated the uploader, ignore future changes
        if (uploaderHyrdrated) return;
        // if we have filteredAnswers, but no attachments in local state, we have no hydrated, return false
        // if we have filteredAnswers, but no attachments in local state, return null
        // this will prevent the component from rendering
        // we need to do this, because the folllowing condition indicates that uploadedAttachmentsLocallyManagement hasn't yet been hydrated with inbound values
        // the file uploder will render without values and in doing so overwrite the first value in the uploadedAttachmentsLocallyManagement array with a null values.
        // classic race condition
        if (filteredAnswers.length > 0 && uploadedAttachmentsLocallyManagement[0] === null) {
            setUploaderHydrated(false);
        } else {
            setUploaderHydrated(true);
        }
    }, [filteredAnswers, uploadedAttachmentsLocallyManagement]);

    return (
        <Box>
            <FormLabel style={{ marginBottom: '-20px' }}>
                {item.name}
            </FormLabel>
            <ul>
                <li>Select up to 10 files to upload</li>
                <li>Maximum file size is 10MB</li>
                <li>Allowed file types include most: documents, images, videos, and audio files</li>
            </ul>
            {uploaderHyrdrated && uploadedAttachmentsLocallyManagement.map((attachment, fileNo) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item container key={`attachment-${fileNo}`}>
                    <Grid item xs={12}>
                        <Typography id={`attachment-${fileNo}-label`} variant="body1" sx={{ fontWeight: 700 }}>
                            Attachment {fileNo + 1}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} container>
                        <Grid item xs={11}>
                            <FileUploader
                                name={`${item.key}_${fileNo}`}
                                labelledBy={`attachment-${fileNo}-label`}
                                describedBy={`attachment-${fileNo}-label`}
                                allowedFileTypes={ALLOWED_FILE_TYPES}
                                previousFilename={attachment ? attachment.name : null}
                                onChange={(uploadedFile) => handleFileUploadChange(fileNo, uploadedFile)}
                                onClear={() => handleFileUploadClear(fileNo)}
                                register={register}
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            {attachment && fileNo < TOTAL_ALLOWED_UPLOADS - 1 && (
                                <IconButton
                                    color="primary"
                                    aria-label="Upload another file"
                                    onClick={handleAddButtonClick}
                                >
                                    <AddBoxIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Box>
    );
};

ReportAttachmentUploader.propTypes = {
    item: PropTypes.object.isRequired,
    setUploadedAttachments: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
};

export default ReportAttachmentUploader;
