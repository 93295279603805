/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import noop from 'lodash/noop';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { APIError, APISuccess, resetRequestState } from '../app';

import API from '../../api';

export const USER_STATUS = {
    INVITED: 'INVITED',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};

const fetchInternalUsers = createAsyncThunk('user/fetchInternalUsers', async (payload) => {
    const data = await API.fetchInternalUsers(payload);

    return data;
});

const updateInternalUser = createAsyncThunk(
    'user/updateInternalUser',
    async ({ userId, data, onSuccess = noop, onError = noop }, thunkApi) => {
        try {
            const result = await API.updateInternalUser(userId, data);

            onSuccess();
            return result;
        } catch (error) {
            thunkApi.dispatch(APIError({ text: 'Error inviting user.' }));
            onError();
        }
    },
);

const createInternalUser = createAsyncThunk(
    'user/createInternalUser',
    async ({ data, onSuccess = noop, onError = noop }, thunkApi) => {
        try {
            const { result } = await API.createInternalUser(data);

            onSuccess();
            return result;
        } catch (error) {
            thunkApi.dispatch(APIError({ text: 'Error creating user.' }));
            onError();
        }
    },
);

const inviteUser = createAsyncThunk('user/inviteUser', async ({ id }, thunkApi) => {
    try {
        const { data } = await API.inviteUser(id);
        thunkApi.dispatch(APISuccess({ text: 'Invitation sent.' }));
        return data;
    } catch (error) {
        thunkApi.dispatch(APIError({ text: 'Error inviting user.' }));
    }
});

const initialState = {
    data: {},
    internalUsers: {
        list: [],
        total: 0,
    },
    successMsg: null,
    currentRequestId: null,
    loading: 'idle',
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserSuccessMessage: (state) => {
            state.successMsg = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetRequestState, (state) => {
                state.errorMsg = initialState.errorMsg;
                state.loading = initialState.loading;
                state.currentRequestId = initialState.currentRequestId;
                state.successMsg = initialState.successMsg;
            })
            .addCase(fetchInternalUsers.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.internalUsers.list = action.payload.results;
                    state.internalUsers.total = action.payload.total;
                }
            })
            .addCase(updateInternalUser.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.internalUsers = {
                        ...state.internalUsers,
                        list: state.internalUsers.list.map((user) => {
                            if (user.id === action.payload.id) {
                                return {
                                    ...user,
                                    ...action.payload,
                                };
                            }
                            return user;
                        }),
                    };
                }
            })
            .addCase(inviteUser.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.successMsg = 'Invitation sent successfully';
                    state.internalUsers = {
                        ...state.internalUsers,
                        list: state.internalUsers.list.map((user) => {
                            if (user.id === action.payload) {
                                return {
                                    ...user,
                                    status: USER_STATUS.INVITED,
                                    lastInvitedOn: new Date(),
                                };
                            }
                            return user;
                        }),
                    };
                }
            })
            .addMatcher(
                isAnyOf(fetchInternalUsers.rejected, updateInternalUser.rejected, inviteUser.rejected),
                (state, action) => {
                    const { requestId } = action.meta;
                    if (state.loading === 'pending' && state.currentRequestId === requestId) {
                        state.loading = 'idle';
                        state.currentRequestId = undefined;
                        state.error = action.error;
                    }
                },
            )
            .addMatcher(
                isAnyOf(fetchInternalUsers.pending, updateInternalUser.pending, inviteUser.pending),
                (state, action) => {
                    if (state.loading === 'idle') {
                        state.loading = 'pending';
                        state.currentRequestId = action.meta.requestId;
                    }
                },
            );
    },
});

const { reducer } = userSlice;

export const { clearUserSuccessMessage } = userSlice.actions;
export { fetchInternalUsers, updateInternalUser, createInternalUser, inviteUser };

export default reducer;
