import Colors from '../../../styles/colors';

const styles = () => ({
    backdrop: {
        zIndex: 1,
        color: Colors.ultramarineBlue,
        backgroundColors: 'rgba(0,0,0,0.7)',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        margin: '40px 20px',
    },
});

export default styles;
