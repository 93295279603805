import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import TablePaginationActions from '../../../components/table-pagination-actions';
import VideoBlock from '../../../components/video-block';
import { fetchVideos } from '../../../redux/videos';
import getVideos from '../../../redux/videos/selectors';

import Styles from '../styles';

const TrainingVideos = ({ classes }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVideos());
    }, [dispatch]);

    const videos = useSelector(getVideos);
    const totalVideos = videos?.length || 0;

    const handleChangePage = useCallback(
        (event, newPage) => {
            setPage(newPage);
        },
        [setPage],
    );

    const handleChangeRowsPerPage = useCallback(
        (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        },
        [setRowsPerPage, setPage],
    );

    const getVideosForCurrentPage = useCallback(() => {
        if (totalVideos && rowsPerPage > 0) {
            return videos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }
        return videos || [];
    }, [rowsPerPage, page, videos, totalVideos]);

    return (
        <Grid item container spacing={2} className={classes.trainingVideosContainer}>
            <Grid item xs={12} className={classes.trainingVideosHeader}>
                <Typography id="videos-title" variant="h1" component="h2" className={classes.pageTitle}>
                    Training Videos
                </Typography>
                <Typography id="videos-count" variant="body1" className={classes.pageCount}>
                    {totalVideos} videos
                </Typography>
            </Grid>

            <Grid item container xs={12} justify="flex-start" className={classes.trainingVideosBody}>
                {getVideosForCurrentPage().map((video) => (
                    <Grid item xs={4} key={video.id}>
                        <VideoBlock video={video} />
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12}>
                {totalVideos > 10 && (
                    <TablePagination
                        className={classes.tableFooter}
                        component="div"
                        count={totalVideos}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        classes={{
                            root: classes.paginationContainer,
                            toolbar: classes.paginationToolbar,
                            spacer: classes.paginationSpacer,
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};
TrainingVideos.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(TrainingVideos, Styles);
