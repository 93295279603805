import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/GetApp';
import Container from '@mui/material/Container';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import { getReportById } from '../../../redux/reports/selectors';
import getFormattedDate, { getFormattedTimeStamp } from '../../../utils/get-formatted-date';
import WebClient from '../../../utils/web-client';
import { REPORT_HISTORY_ACTIONS, USER_ROLES } from '../../../utils/constants';
import OutcomePill from '../components/outcome-pill';
import { REPORT_STATUS, downloadReport } from '../../../redux/reports';
import Styles from '../styles';
import transformReportHistoryData from '../../../utils/transform-report-history-data';

const ReportHistory = ({ classes }) => {
    const { reportId } = useParams();
    const dispatch = useDispatch();
    const getReport = useSelector(getReportById);
    const report = useMemo(() => getReport(reportId) || {}, [getReport, reportId]);
    const [reportHistory, setReportHistory] = useState(null);

    useEffect(() => {
        const fetchReportHistory = async () => {
            const { data } = await WebClient.get(`/reports/${reportId}/history`);
            setReportHistory(transformReportHistoryData(reportId, data));
        };
        fetchReportHistory();
    }, [reportId]);

    const renderReportAction = (action, value) => {
        if (action === REPORT_HISTORY_ACTIONS.reportSubmitted.value) {
            return <OutcomePill outcome={value || REPORT_STATUS.SUBMITTED} />;
        }

        if (action === REPORT_HISTORY_ACTIONS.reportReopened.value) {
            return <OutcomePill outcome={REPORT_STATUS.REOPENED} />;
        }

        if (action === REPORT_HISTORY_ACTIONS.reportAssignedOutcome.value) {
            return <OutcomePill outcome={value} />;
        }

        return action;
    };

    const handleDownloadReport = useCallback(() => {
        const onSuccess = (url) => {
            window.location = url;
        };

        dispatch(downloadReport({ reportId, onSuccess }));
    }, [dispatch, reportId]);

    if (!reportHistory) {
        return <CircularProgress />;
    }

    return (
        <Container>
            <Grid item xs={12}>
                <div className={classes.backLink}>
                    <Link to="/dashboard/reports">Back to Reports</Link>
                </div>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h1">Report History</Typography>
            </Grid>

            <Grid item container xs={12} className={classes.reportDetailsContainer}>
                <Grid item xs={4}>
                    <Typography variant="body1" className={classes.reportDetailsTitle}>
                        Site
                    </Typography>
                    <Typography variant="body1">{report.testSite.siteName}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1" className={classes.reportDetailsTitle}>
                        Tester
                    </Typography>
                    <Typography variant="body1">{report.tester.fullName}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1" className={classes.reportDetailsTitle}>
                        Test Date
                    </Typography>
                    <Typography variant="body1">{getFormattedDate(report.testDate)}</Typography>
                </Grid>
            </Grid>

            {report.status !== REPORT_STATUS.DRAFT && (
                <Grid item xs={12} pt={3}>
                    <Button
                        className={classes.secondaryButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        type="button"
                        onClick={handleDownloadReport}
                    >
                        <DownloadIcon className="mr-2" /> Download Report
                    </Button>
                </Grid>
            )}

            <Grid item xs={12} pt={3}>
                <Paper className={classes.tableWrapper}>
                    <TableContainer>
                        <Table stickyHeader aria-labelledby="report-title report-count">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell />
                                    <TableCell className={classes.tableCell}>Action</TableCell>
                                    <TableCell className={classes.tableCell}>Date</TableCell>
                                    <TableCell className={classes.tableCell}>User</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                <TableRow>
                                    <TableCell className={classes.timelineCell}>
                                        <div className={`${classes.circle} first`}>&nbsp;</div>
                                    </TableCell>
                                    <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                        Report Created
                                    </TableCell>
                                    <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                        {getFormattedTimeStamp(report.createdAt)}
                                    </TableCell>
                                    <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                        <strong>{USER_ROLES.tester.label}</strong> {report.tester.fullName}
                                    </TableCell>
                                </TableRow>
                                {reportHistory.map(({ action, date, user, value }, index) => (
                                    <TableRow key={reportId}>
                                        <TableCell className={classes.timelineCell}>
                                            <div
                                                className={`${classes.circle} ${index === reportHistory.length - 1 && 'last'}`}
                                            >
                                                &nbsp;
                                            </div>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                            {renderReportAction(action, value)}
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                            {getFormattedTimeStamp(date)}
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                            <strong>{USER_ROLES[user.role].label}</strong> {user.fullName}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Container>
    );
};

ReportHistory.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(ReportHistory, combinedStyles);
