import { createSelector } from '@reduxjs/toolkit';

const getUserState = (state) => state.user;

export const getUserId = createSelector(getUserState, (user) => user.id);

export const getUserName = createSelector(getUserState, (user) => user.fullName);

export const getInternalUsers = createSelector(getUserState, (user) => user.internalUsers?.list || []);

export const getInternalUsersTotal = createSelector(getUserState, (user) => user.internalUsers?.total);

export const getInternalUser = createSelector(
    getInternalUsers,
    (internalUsers) => (userId) => internalUsers.find((user) => user.id === userId),
);

export const getUserSuccessMessage = createSelector(getUserState, (userState) => userState.successMsg);
