import { useEffect } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';

import ConditionalOptions from '../../../../../../wiring/question-render-logic-conditions-options';
import Select from '../../../../../../components/inputs/select';

const RenderLogicCondition = ({ parentType, fieldName, control, errors, getValues, setValue }) => {
    // if the selected value for this field is still an option based on the parent's type, then we proceed with no updates
    // if the selected value is not an option, we reset the value to empty
    let selectedValue = getValues(fieldName);
    const availableOptions = ConditionalOptions.filter((option) => option.types.includes(parentType));
    useEffect(() => {
        if (selectedValue && !availableOptions.find(({ key }) => key === selectedValue)) {
            setValue(fieldName, undefined);
            selectedValue = undefined;
            // NOTE: the console will produce "You have provided an out-of-range value" warning associated with this.
            // we are updating the value of the field, which is a controlled component
            // the update wont be reflected till the next render, but during this render we will have a select menu that tries to select an out of scope menu option
        }
    }, [parentType]);
    // we take this out of the selects renderValue function because we are possibly computing based on a newly established value
    const renderedValue = selectedValue ? availableOptions.find(({ key }) => key === selectedValue)?.label : 'Select';
    return (
        <Select
            errors={errors}
            name={fieldName}
            control={control}
            displayEmpty
            placeholder={renderedValue}
            renderValue={(value) => (value !== '' ? renderedValue : 'Select')}
        >
            {availableOptions.map((option) => <MenuItem value={option.key} key={option.label}>{option.label}</MenuItem>)}
        </Select>
    );
};

RenderLogicCondition.propTypes = {
    parentType: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    getValues: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
};

export default RenderLogicCondition;
