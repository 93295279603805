import { Suspense, lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme-public';
import { SentryRoutes } from '../../utils/sentry';

const Login = lazy(() => import('../../routes/login'));
const ForgotPassword = lazy(() => import('../../routes/login/forgot-password'));
const ResetPassword = lazy(() => import('../../routes/login/reset-password'));
const Error404 = lazy(() => import('../../routes/error/404'));
const ErrorSentry = lazy(() => import('../../routes/error/sentry'));

const PublicLayout = () => (
    <Suspense fallback={<div />}>
        <main>
            <Container maxWidth={false} disableGutters>
                <SentryRoutes>
                    <Route path="/error/404" element={<Error404 />} />
                    <Route path="/error/sentry-test" element={<ErrorSentry />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/login/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
                    <Route path="/set-password/:resetToken" element={<ResetPassword initialSetup />} />
                    <Route path="*" element={<Navigate replace to="/login" />} />
                </SentryRoutes>
            </Container>
        </main>
    </Suspense>
);

export default withRoot(PublicLayout, themer);
