import Colors from '../../styles/colors';

const styles = () => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: 320,
        marginBottom: 20,
    },
    header: {
        backgroundColor: Colors.ultramarineBlue,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 20,
        width: '100%',
        border: `1px solid ${Colors.ultramarineBlue}`,
        borderRadius: '10px 10px 0 0',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.white,
        width: '100%',
        height: '100%',
        border: `1px solid ${Colors.lavenderGray}`,
        borderTop: 0,
        borderRadius: '0 0 10px 10px',
    },
    titleText: {
        fontWeight: 700,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '80%',
        textAlign: 'center',
        padding: '15px 20px 5px',
    },
    descriptionText: {
        fontSize: '0.875rem',
        padding: '5px 10px 20px',
        textAlign: 'center',
        lineHeight: '2rem',
    },
    videoContainer: {
        backgroundColor: Colors.lavenderGray,
        height: 160,
    },
});
export default styles;
