import { useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { withStyles } from 'tss-react/mui';

import Styles from './styles';

const TablePaginationActions = ({ classes, count, page, rowsPerPage, onPageChange }) => {
    const handleFirstPageButtonClick = useCallback(
        (event) => {
            onPageChange(event, 0);
        },
        [onPageChange],
    );

    const handleBackButtonClick = useCallback(
        (event) => {
            onPageChange(event, page - 1);
        },
        [onPageChange, page],
    );

    const handleNextButtonClick = useCallback(
        (event) => {
            onPageChange(event, page + 1);
        },
        [onPageChange, page],
    );

    const handleLastPageButtonClick = useCallback(
        (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        },
        [onPageChange, count, rowsPerPage],
    );

    return (
        <div className={classes.container}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                <FirstPageIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
};

TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default withStyles(TablePaginationActions, Styles);
