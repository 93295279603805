import { USER_STATUS } from '../../../../redux/user';
import getFormattedDate from '../../../../utils/get-formatted-date';

const STATUS_MAP = {
    INVITED: { type: 'italic', text: 'Invited' },
    ACTIVE: { type: 'primary', text: 'Active' },
    INACTIVE: { type: 'secondary', text: 'Inactive' },
};

export default (tester) => {
    const { type, text } = STATUS_MAP[tester.status];
    if (tester.status === USER_STATUS.INVITED && tester.lastInvitedOn) {
        return {
            type,
            text: `${text} on ${getFormattedDate(tester.lastInvitedOn)}`,
        };
    }
    return { type, text };
};
