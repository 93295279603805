import PropTypes from 'prop-types';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import TrainingVideos from '../../routes/dashboard/training-videos';
import ManageUsers from '../../routes/dashboard/manage-users';
import ManageTesters from '../../routes/dashboard/manage-testers';
import ManageSites from '../../routes/dashboard/manage-sites';
import ManageOrganizations from '../../routes/dashboard/manage-organizations';
import InviteEditUser from '../../routes/dashboard/invite-edit-user';
import InviteEditTester from '../../routes/dashboard/invite-edit-tester';
import CreateEditSite from '../../routes/dashboard/create-edit-site';
import CreateEditOrganizations from '../../routes/dashboard/manage-organizations/create-edit-organization';
import InviteOrganizationAdmin from '../../routes/dashboard/manage-organizations/invite-edit-admin';
import OrganizationViewAdmins from '../../routes/dashboard/manage-organizations/view-admins';
import ReportHistory from '../../routes/dashboard/report-history';
import AdminDashboard from '../../routes/dashboard/admin-dashboard';
import TesterDashboard from '../../routes/dashboard/tester-dashboard';
import TestBuilder from '../../routes/dashboard/test-builder';
import { getRole } from '../../redux/auth/selectors';

import DesktopNav from './components/desktop-nav';
import MobileNav from './components/mobile-nav';
import { ADMINISTRATIVE_ROLES, USER_ROLES } from '../../utils/constants';

const MainContents = ({ location, role }) => {
    // Admin routes
    if (ADMINISTRATIVE_ROLES.includes(role)) {
        return (
            <Routes path="dashboard" location={location}>
                <Route path="" element={<Navigate replace to="reports" />} />
                <Route path="reports/:reportId" element={<ReportHistory />} />
                <Route path="reports" element={<AdminDashboard />} />
                <Route path="test-builder" element={<TestBuilder />} />
                <Route path="testers" element={<ManageTesters role={role} />} />
                <Route path="tester/:testerId?" element={<InviteEditTester />} />
                <Route path="sites" element={<ManageSites role={role} />} />
                <Route path="sites/:siteId" element={<CreateEditSite />} />
                {(role === USER_ROLES.admin.value || role === USER_ROLES.orgAdmin.value) && (
                    <>
                        <Route path="user/:userId?" element={<InviteEditUser />} />
                        <Route path="users" element={<ManageUsers />} />
                    </>
                )}
                <Route path="organizations" element={<ManageOrganizations role={role} />} />
                <Route path="organizations/:orgId" element={<CreateEditOrganizations />} />
                <Route path="organizations/:orgId/view-admins" element={<OrganizationViewAdmins />} />
                <Route path="organizations/:orgId/invite-admin" element={<InviteOrganizationAdmin />} />
                <Route path="organizations/:orgId/edit-admin/:userId" element={<InviteOrganizationAdmin />} />
                <Route path="*" element={<Navigate replace to="reports" />} />
            </Routes>
        );
    }

    // Tester routes
    return (
        <Routes path="dashboard" location={location}>
            <Route path="" element={<Navigate replace to="reports" />} />
            <Route path="reports" element={<TesterDashboard />} />
            <Route path="training" element={<TrainingVideos />} />
        </Routes>
    );
};

MainContents.propTypes = {
    location: PropTypes.object.isRequired,
    role: PropTypes.oneOf(Object.values(USER_ROLES).map((role) => role.value)).isRequired,
};

const DashboardLayout = () => {
    const location = useLocation();
    const role = useSelector(getRole);

    return (
        <Grid item container xs={12} style={{ maxWidth: '100%' }}>
            <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                <header>
                    <nav>
                        <DesktopNav role={role} />
                    </nav>
                </header>
            </Grid>
            <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                <header>
                    <MobileNav role={role} />
                </header>
            </Grid>
            <Grid item xs={12} sm={10}>
                <main>
                    <Box sx={{ marginTop: 6 }}>
                        <MainContents location={location} role={role} />
                    </Box>
                </main>
            </Grid>
        </Grid>
    );
};

export default DashboardLayout;
