import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';

import { fetchSingleOrganization } from '../../../../api';
import { USER_ROLES } from '../../../../utils/constants';
import TextInput from '../../../../components/inputs/textinput';
import Select from '../../../../components/inputs/select';
import Styles from './styles';
import DashboardStyles from '../../styles';
import { createInternalUser, updateInternalUser } from '../../../../redux/user';
import validationSchema from '../../../../validation/validation-schema-internal-user';
import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import InputStyles from '../../../../styles/inputs';
import useSnackbar from '../../../../utils/use-snackbar';
import NotificationSnackbar from '../../../../components/notification-snackbar';

const InviteEditUser = ({ classes }) => {
    const { orgId, userId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [orgName, setOrgName] = useState('');
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();
    const isEditing = !!userId;

    const { control, register, handleSubmit, reset, formState, setValue } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            role: 'orgAdmin',
        },
    });

    useEffect(() => {
        const fetchData = async () => {
             const data = await dispatch(fetchSingleOrganization(orgId));
             if (data) {
                 const { name } = data;
                 setOrgName(name);

                 if (userId) {
                    // get user data from data.users by matching on the id param
                    // set form data to user data
                    const userData = data.users.find((user) => user.id.toString() === userId.toString());
                    if (userData) {
                        setValue('firstName', userData.firstName);
                        setValue('lastName', userData.lastName);
                        setValue('email', userData.email);
                    }
                 }
             }
         };
         if (orgId) {
             fetchData();
         }
     }, [orgId, userId]);

    const { errors } = formState;

    const onSubmit = (data) => {
        const onError = () => {
            handleOpenSnackbar({ text: 'An unknown issue occurred.  Please try again later.', type: 'error' });
        };

        if (isEditing) {
            const onSuccess = () => {
                navigate(`/dashboard/organizations/${orgId}/view-admins`);
            };
            dispatch(
                updateInternalUser({
                    userId,
                    data,
                    onSuccess,
                    onError,
                }),
            );
        } else {
            const onSuccess = () => {
                reset(
                    {
                        firstName: '',
                        lastName: '',
                        email: '',
                        role: 'orgAdmin',
                    },
                    { submitCount: true },
                );
                navigate('/dashboard/organizations');
                handleOpenSnackbar({ text: 'Your invitation has been sent!', type: 'success' });
            };
            dispatch(createInternalUser({ data: { ...data, organizationId: orgId }, onSuccess, onError }));
        }
    };

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            <Grid item xs={12} className={classes.backLink}>
                <Link to="/dashboard/organizations">Back to Manage Organizations</Link>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h1">{isEditing ? 'Edit Organization Admin' : 'Invite New Organization Admin'}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.description}>
                <Typography variant="body1">All questions are required</Typography>
            </Grid>

            <Grid container item xs={4}>
                <Grid item xs={12} pb={2}>
                    <Box className={classes.siteInfo}>
                        <Typography variant="body1">
                            <strong>Name</strong>
                            <br />
                            {orgName}
                        </Typography>
                    </Box>
                </Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid item container>
                        <Grid item container xs={12}>
                            <Grid item xs={6} sx={{ mr: 2 }}>
                                <TextInput
                                    name="firstName"
                                    label="First Name"
                                    errors={errors}
                                    required
                                    register={register}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <TextInput
                                    name="lastName"
                                    label="Last Name"
                                    errors={errors}
                                    required
                                    register={register}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.inputWrapper}>
                            <TextInput
                                name="email"
                                label="Email"
                                errors={errors}
                                required
                                register={register}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <div className={classes.inputWrapper}>
                                <Select
                                    name="role"
                                    label="Role"
                                    errors={errors}
                                    required
                                    control={control}
                                    displayEmpty
                                    disabled
                                    renderValue={(value) => value && 'Organizational Admin'}
                                >
                                    <MenuItem value="orgAdmin">Organizational Admin</MenuItem>
                                </Select>
                            </div>
                        </Grid>

                        <Grid item xs={12} pt={3}>
                            <Box>
                                <Typography variant="body1" style={{ fontWeight: 700 }}>
                                    Admins can:
                                </Typography>
                                <ul>
                                    {USER_ROLES.admin.permissions.map((permission) => (
                                        <li key={permission}>{permission}</li>
                                    ))}
                                </ul>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                className={classes.primaryButton}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                type="submit"
                            >
                                {isEditing ? 'Update User' : 'Send Invitation'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <NotificationSnackbar {...snackbarProps} />
        </Grid>
    );
};

InviteEditUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(DashboardStyles, ButtonStyles, InputStyles, Styles);
export default withStyles(InviteEditUser, combinedStyles);
