import { configureStore, combineReducers } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth';
import videoReducer from './videos';
import userReducer from './user';
import testersReducer from './testers';
import sitesReducer from './sites';
import reportsReducer from './reports';
import appReducer from './app';
import organizationsReducer from './organizations';
import currentReportReducer from './current-report';
import thunkCrashReporter from './thunk-crash-reporter';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const reducers = combineReducers({
    auth: authReducer,
    videos: videoReducer,
    user: userReducer,
    testers: testersReducer,
    sites: sitesReducer,
    reports: reportsReducer,
    app: appReducer,
    organizations: organizationsReducer,
    currentReport: currentReportReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'user', 'currentReport', 'reports', 'testers', 'videos', 'sites', 'organizations'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: import.meta.env.VITE_DEPLOY_ENV !== 'production',
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(thunkCrashReporter),
});

export default store;
