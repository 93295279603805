import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format, isValid, parse } from 'date-fns';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { fetchSites } from '../../../../redux/sites';
import { getActiveSites } from '../../../../redux/sites/selectors';
import { getCurrentUser } from '../../../../redux/auth/selectors';
import { createReport, getCurrentTest } from '../../../../redux/reports';
import TextInput from '../../../../components/inputs/textinput';
import Select from '../../../../components/inputs/select';
import RadioGroup from '../../../../components/inputs/radiogroup';
import RadioButton from '../../../../components/inputs/radiobutton';
import DatePicker from '../../../../components/inputs/datepicker';
import TextMaskAlphanumeric from '../../../../components/inputs/text-mask-alphanumeric';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import InputStyles from '../../../../styles/inputs';
import DialogStyles from '../../../../styles/dialog';
import sortByAttribute from '../../../../utils/sort-by-attribute';

const CreateEditReportDialog = ({ classes, isDialogOpen, handleCloseDialog, reportDetails }) => {
    const [beginButtonEnabled, setBeginButtonEnabled] = useState(false);
    const [testDate, setTestDate] = useState(format(new Date(), 'MM/dd/yyyy'));
    const currentUser = useSelector(getCurrentUser);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchSites());
        dispatch(getCurrentTest());
    }, [currentUser]);

    const testSites = sortByAttribute(useSelector(getActiveSites), 'siteName');

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
        register,
        reset,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            testSite: '',
            testNumber: '',
            testCoordinator: '',
            dateOfVisitType: '',
        },
    });
    const testSite = watch('testSite');
    const testCoordinator = watch('testCoordinator');
    const dateOfVisitType = watch('dateOfVisitType');
    const testNumber = watch('testNumber');

    useEffect(() => {
        reset({
            testSite: '',
            testNumber: '',
            testCoordinator: '',
            dateOfVisitType: '',
        });
    }, [reportDetails, reset]);

    useEffect(() => {
        if (dateOfVisitType === 'today') {
            setTestDate(format(new Date(), 'MM/dd/yyyy'));
        } else {
            setTestDate(format(new Date(), 'MM/dd/yyyy'));
        }
    }, [dateOfVisitType, setTestDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (testSite && testDate && testCoordinator && testNumber) setBeginButtonEnabled(true);
        else setBeginButtonEnabled(false);
    }, [testSite, testDate, testCoordinator, testNumber]);

    const onDateChange = useCallback(
        (date) => {
            if (isValid(date)) {
                setTestDate(format(date, 'MM/dd/yyyy'));
            }
        },
        [setTestDate],
    );

    const onSubmit = useCallback(
        (values) => {
            const reportData = {
                testSite: testSites.find((site) => values.testSite === site.id),
                testNumber: values.testNumber === '' ? null : values.testNumber,
                testCoordinator: values.testCoordinator === '' ? null : values.testCoordinator,
                testDate,
            };
            const tempId = uuid();

            // continue on to the Report Overview whether the report was successfully created or not
            dispatch(
                createReport({
                    tempId,
                    reportData,
                    onSuccess: () => navigate('/report'),
                    onError: () => navigate('/report'),
                }),
            );
        },
        [testDate, testSites, reportDetails, handleCloseDialog, dispatch, navigate],
    );

    return (
        <Dialog
            classes={{
                paper: classes.dialogContainer,
            }}
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="report-dialog-title"
            fullWidth
        >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <DialogTitle id="report-dialog-title" className={classes.dialogTitle}>
                    <Grid item container xs={12}>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <IconButton aria-label="cancel" onClick={handleCloseDialog}>
                                <CloseIcon
                                    classes={{
                                        root: classes.dialogCloseButton,
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h1" component="h2" align="center">
                                    Start New Report
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <DialogContentText className={classes.dialogContentText}>
                        Please enter some basic information to get started
                    </DialogContentText>

                    <Grid item xs={12}>
                        <div className={classes.inputWrapper}>
                            <TextMaskAlphanumeric
                                name="testNumber"
                                label="Test Number"
                                control={control}
                                errors={errors}
                                showRequired
                                required
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={classes.inputWrapper}>
                            <TextInput
                                name="testCoordinator"
                                label="Test Coordinator"
                                errors={errors}
                                register={register}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                showRequired
                                required
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} pt={2}>
                        <Select
                            name="testSite"
                            label="Test Site"
                            errors={errors}
                            required
                            control={control}
                            displayEmpty
                            placeholder="Select a Test Site"
                            value={testSite}
                            renderValue={(siteId) => {
                                if (siteId) {
                                    const site = testSites.find((s) => s.id === siteId);
                                    return (
                                        <MenuItem key={site.id} value={site.id} sx={{ padding: 0 }}>
                                            {site.siteName}, {site.address}, {site.city}, {site.state} {site.zipcode}
                                        </MenuItem>
                                    );
                                }

                                return (
                                    <MenuItem disabled value="">
                                        Select a Test Site
                                    </MenuItem>
                                );
                            }}
                        >
                            {testSites.map((site) => (
                                <MenuItem key={site.id} value={site.id} sx={{ padding: 0 }}>
                                    {site.siteName}, {site.address}, {site.city}, {site.state} {site.zipcode}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={classes.inputWrapper}>
                            <RadioGroup name="dateOfVisitType" label="Test Date" row control={control}>
                                <RadioButton value="today" label="Today" buttonStyle />
                                <RadioButton value="selectDate" label="Select date" buttonStyle />
                            </RadioGroup>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        {dateOfVisitType === 'selectDate' && (
                            <div className={classes.inputWrapper}>
                                <DatePicker
                                    name="testDate"
                                    label="Enter date of test"
                                    value={parse(testDate, 'MM/dd/yyyy', new Date())}
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    defaultValue={new Date()}
                                    onChange={onDateChange}
                                    errors={errors}
                                />
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} pt={2}>
                        <Alert severity="info">
                            Please double check this information before proceeding as it cannot be changed later.
                        </Alert>
                    </Grid>
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    <Button
                        className={classes.outlineButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.outlineButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        onClick={handleCloseDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={`${classes.primaryButton}`}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        type="submit"
                        disabled={!beginButtonEnabled}
                    >
                        Begin
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

CreateEditReportDialog.defaultProps = {
    reportDetails: null,
};

CreateEditReportDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    reportDetails: PropTypes.object,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, DialogStyles);
export default withStyles(CreateEditReportDialog, combinedStyles);
