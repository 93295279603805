/* eslint-disable no-confusing-arrow */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CombineStyles from '../../../../../utils/combine-styles';
import ButtonStyles from '../../../../../styles/buttons';

import Styles from './styles';
import { USER_ROLES } from '../../../../../utils/constants';
import useEventListener from '../../../../../utils/use-event-listener';

const FormButtons = ({ classes, onSaveDraft, onSubmit, onSaveFeedback, onCancelFeedback, role }) => {
    const [offline, setOffline] = useState(false);
    const manageOnline = () => {
        setOffline(false);
    };
    const manageOffline = () => {
        setOffline(true);
    };
    useEventListener('online', manageOnline);
    useEventListener('offline', manageOffline);

    useEffect(() => {
        if (!window.navigator.onLine) setOffline(true);
    }, []);

    return (
        <>
            {offline && (
                <Grid item xs={12} pt={4}>
                    <Typography varient="body" color="error">
                        It looks like your internet connection is unstable. You can try saving again when you reconnect. In the meantime, do not navigate away from this page or use the back button or you will lose your new responses.
                    </Typography>
                </Grid>
            )}
            {role === USER_ROLES.tester.value ? (
                <Grid item xs={12} className={classes.buttonsContainer}>
                    <Button
                        className={classes.secondaryButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.secondaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        disabled={offline}
                        onClick={onSaveDraft}
                    >
                        Save as Draft
                    </Button>
                    <Button
                        className={classes.primaryButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        disabled={offline}
                        type="submit"
                        onClick={onSubmit}
                    >
                        Complete Section
                    </Button>
                </Grid>
            ) : (
                <div className="row">
                    <div className={`col ${classes.buttonsContainer}`}>
                        <div className="pr-3">
                            <Button
                                className={classes.secondaryButton}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.secondaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                disabled={offline}
                                onClick={onCancelFeedback}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button
                                className={classes.primaryButton}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                disabled={offline}
                                onClick={onSaveFeedback}
                            >
                                Save Comments
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

FormButtons.propTypes = {
    classes: PropTypes.object.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSaveFeedback: PropTypes.func.isRequired,
    onCancelFeedback: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(FormButtons, combinedStyles);
