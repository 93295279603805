import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import ActionMenu from '../../../../components/action-menu';

const OrgActionMenu = ({ org, onDelete }) => {
    const navigate = useNavigate();
    const handleDelete = useCallback(() => {
        onDelete(org);
    }, [org]);
    const handleInviteNewAdmin = useCallback(() => {
        navigate(`/dashboard/organizations/${org.id}/invite-admin`);
    });
    const handleViewAdmins = useCallback(() => {
        navigate(`/dashboard/organizations/${org.id}/view-admins`);
    });

    const handleEdit = () => {
        navigate(`/dashboard/organizations/${org.id}`);
    };

    const actions = [
        { label: 'View Admins', onClick: handleViewAdmins },
        { label: 'Invite New Admin', onClick: handleInviteNewAdmin },
        { label: 'Delete', onClick: handleDelete },
    ];

    return (
        <ActionMenu
            id={`${org.id}-actions`}
            actions={actions}
            defaultAction={{
                label: 'Edit',
                onClick: handleEdit,
            }}
        />
    );
};

OrgActionMenu.propTypes = {
    org: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default OrgActionMenu;
