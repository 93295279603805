import Colors from '../../../../styles/colors';

const styles = (theme) => ({
    wrapper: {
        backgroundColor: Colors.persianBlue,
        height: 60,
    },
    drawerPaper: {
        top: 60,
        width: '100%',
        backgroundColor: Colors.persianBlue,
    },
    drawerModalRoot: {
        top: 60,
    },
    drawerIcon: {
        color: Colors.white,
    },
    hideDrawer: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    imgContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        height: 35,
    },
    lscLogo: {
        height: 50,
    },
});

export default styles;
