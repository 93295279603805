import { useState, useEffect, useCallback } from 'react';
import { isValid } from 'date-fns';

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import getFormattedDate from '../../../../../../utils/get-formatted-date';
import DynamicInput from '../../../../../../components/inputs/dynamic-input';

// used to determine the field to render for conditional questions renderLogic
const RenderLogicValue = ({ parent, selectedCondition, fieldName, control, errors, register, watchValues, setValue }) => {
    const [initialParentType, setInitialParentType] = useState(parent.type);
    useEffect(() => {
        setInitialParentType(parent.type);
    }, []);
    useEffect(() => {
        // if the parent type has changed, we need to reset the value of the value field
        // this is because the value field may no longer be valid
        if (parent.type !== initialParentType) {
            if (parent.type === 'checkBoxes') setValue(fieldName, []);
            else setValue(fieldName, undefined);
        }
    }, [parent.type]);

     // the date picker and time picker are special, we handle their values differently
    const handleDateChange = useCallback((date, name) => {
        if (isValid(date)) {
            setValue(name, getFormattedDate(date), { shouldValidate: true, shouldDirty: true });
        } else if (date === null) setValue(name, null, { shouldValidate: true, shouldDirty: true });
    }, [setValue]);
    const handleTimeChange = useCallback((time, name) => {
        setValue(name, time, { shouldValidate: true, shouldDirty: true });
    }, [setValue]);
    if (selectedCondition === 'isEmpty' || selectedCondition === 'isNotEmpty') {
        return null;
    }

    // this is a bit hacky
    // but the dynamic input we are expecting to render is based on the parent type
    // and we want to deviate from that for radio buttons and checkboxes
    let parentType = parent.type;
    if (parent.type === 'checkBoxes') parentType = 'multiSelect';
    if (parent.type === 'radioButton') parentType = 'select';

    const fieldItem = {
        name: fieldName,
        label: '',
        required: true,
        key: fieldName,
        type: parentType,
    };
    if (parent.type === 'date' || parent.type === 'time' || parent.type === 'radioButton' || parent.type === 'checkBoxes') {
        if (parent.type === 'radioButton' || parent.type === 'checkBoxes') {
            if (parent?.options?.length > 0) {
                fieldItem.options = parent.options;
                fieldItem.placeholder = 'Select';
            } else {
                return <Typography variant="body1" color="error">Error: Enter Options for Parent Question</Typography>;
            }
        }
        return (
            <DynamicInput
                register={register}
                onDateChange={handleDateChange}
                onTimechange={handleTimeChange}
                dateValue={watchValues[fieldName]}
                item={fieldItem}
                control={control}
                errors={errors}
                hiddenLabel
            />
        );
    }
    // short text / long text / file - all are limited to isEmpty/isNotEmpty
    return null;
};

RenderLogicValue.propTypes = {
    parent: PropTypes.object.isRequired,
    selectedCondition: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    watchValues: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
};

export default RenderLogicValue;
