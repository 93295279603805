import { createTheme } from '@mui/material/styles';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
// https://fonts.adobe.com/fonts/ibm-plex-sans#fonts-section
const baseTheme = createTheme({
    typography: {
        fontFamily: '"Inter", sans-serif',
        fontWeight: 400,
        htmlFontSize: 16,
        h1: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
        },
        h2: {
            fontSize: '1.125rem',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '1rem',
            fontWeight: 600,
        },
        h4: {
            fontSize: '.8rem',
            lineHeight: '3.72rem',
        },
        h5: {
            fontSize: '.6rem',
            lineHeight: '3.0rem',
            fontWeight: 500,
        },
        h6: {
            fontSize: '.4rem',
            lineHeight: '2.4rem',
            fontWeight: 'bold',
        },
        body1: {
            fontSize: '0.9375rem',
            fontFamily: '"Inter", sans-serif',
            lineHeight: '1.5rem',
            color: Colors.darkGrey,
        },
        body2: {
            fontSize: '0.9375rem',
            fontFamily: '"Inter", sans-serif',
        },
        button: {
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '1rem',
        },
        color: Colors.darkGrey,
    },
    customButtons: {
        textTransform: 'none',
        fontSize: '1rem',
        minHeight: 50,
        borderRadius: 6,
        lineHeight: 1,
        paddingLeft: 24,
        paddingRight: 24,
    },
    palette: {
        text: {
            primary: Colors.black,
            secondary: Colors.grey,
        },
        primary: {
            main: Colors.persianBlue,
            light: Colors.brightPink,
            contrastText: Colors.darkGrey,
        },
        secondary: {
            main: Colors.midnight,
            contrastText: Colors.white,
        },
        tertiary: {
            main: Colors.brightPink,
            contrastText: Colors.black,
        },
        quaternary: {
            main: Colors.tAndPink,
        },
        background: {
            default: Colors.antiflashWhite,
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        body2: {
            color: baseTheme.palette.text.secondary,
            fontFamily: '"Inter", sans-serif',
        },
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 0,
                    border: 'none',
                    borderRadius: 0,
                    boxShadow: 'none',
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 0,
                },
                content: {
                    margin: 0,
                    '&.Mui-expanded': {
                        margin: 0,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    fontSize: 15,
                },
                deleteIcon: {
                    fontSize: 18,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: {
            // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    fontSize: '1rem',
                    fontWeight: 700,
                    lineHeight: 'inherit',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: 0,
                    left: 0,
                    color: baseTheme.palette.text.primary,
                    fontSize: '1rem',
                    fontWeight: 600,
                    '&.Mui-error, &.Mui-focused': {
                        color: baseTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    whiteSpace: 'normal',
                    position: 'relative',
                    top: -15,
                    left: -15,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    marginLeft: 0,
                    marginRight: 0,
                    color: baseTheme.palette.text.secondary,
                    '&.Mui-error': {
                        color: baseTheme.palette.error,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.primary,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    fontSize: '1rem',
                    marginTop: 5,

                    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: Colors.blue,
                    },
                },
                notchedOutline: {
                    borderColor: Colors.grey,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    // color: 'green',
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${Colors.darkElectricBlue} !important`, // overrides the "disabled" border color
                    },
                },
                select: {
                    // color: 'yellow',
                    '&.Mui-disabled': {},
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 10px 19px -4px rgba(0, 0, 0, 0.15)',
                    borderRadius: '0 0 8px 8px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: '"Inter", sans-serif',
                    lineHeight: '2.1rem',
                    textTransform: 'none',
                    fontSize: '1rem',
                    padding: '16px',
                    '&:focus-visible': {
                        boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
                    },
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: Colors.ultramarineBlue,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: Colors.greyLightest,
                        color: Colors.greyLight,
                    },
                },
                outlinedPrimary: {
                    borderColor: Colors.greyLight,
                    '&:hover': {
                        backgroundColor: Colors.greyLightest,
                        borderColor: baseTheme.palette.primary.main,
                    },
                },
                containedSecondary: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.secondary.light,
                    },
                },
                containedTertiary: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.tertiary.light,
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                primary: {
                    '&.Mui-disabled, &[aria-disabled=true]': {
                        opacity: '40%',
                        backgroundColor: baseTheme.palette.primary.main,
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.primary,
                    '& .MuiAlert-icon': {
                        color: baseTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    '& .MuiAlert-root': {
                        alignItems: 'center',

                        '& .MuiAlert-action': {
                            paddingTop: 0,
                        },
                    },
                },
            },
        },
        MuiPickersToolbarText: {
            styleOverrides: {
                toolbarTxt: {
                    color: Colors.antiflashWhite,
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                button: {
                    '&:hover, &:focus': {
                        backgroundColor: `rgba(${Colors.ultramarineBlueRGB}, 0.25)`,
                    },
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&:hover, &:focus': {
                        backgroundColor: `rgba(${Colors.ultramarineBlueRGB}, 0.25)`,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                },
            },
        },
    },
});

export default theme;
