import { createSelector } from '@reduxjs/toolkit';

const getAuthState = (state) => state.auth;

export const getIsAuthenticated = createSelector(getAuthState, (authState) => !!authState.currentUser);

export const getCurrentUser = createSelector(getAuthState, (authState) => authState.currentUser);

export const getIsAuthenticating = createSelector(getAuthState, (authState) => authState.loading);

export const getRole = createSelector(getAuthState, (authState) => authState.currentUser?.role);

export const getAuthError = createSelector(getAuthState, (authState) => authState.error);

export const getAuthErrorMessage = createSelector(getAuthState, (authState) => authState.errorMsg);

export const isResetPasswordCompleted = createSelector(getAuthState, (authState) => authState.resetPasswordCompleted);

export const getResetPasswordError = createSelector(getAuthState, (authState) => authState.error);
export const getUserColorScheme = createSelector(getAuthState, (authState) => authState.currentUser?.organization?.colorScheme);
export const getUserOrganization = createSelector(getAuthState, (authState) => authState.currentUser?.organization);
