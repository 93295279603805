// an input text field that creates a material chip array when the user presses enter
// each chip can be deleted by clicking the 'x' icon
// this component is a wrapper around the material-ui Chip component
// and is a child of a parent react-hook-form Controller component

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { ErrorMessage } from '@hookform/error-message';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';

const ChipArray = ({
    name,
    label,
    value,
    errors,
    control,
    rules,
    showError,
    required,
    setValue,
}) => {
    const isInvalid = !!errors[name];

    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setValue(name, [...value, inputValue], { shouldValidate: true });
            setInputValue('');
        }
    };

    const handleDelete = (index) => {
        // remove the chip at the index
        setValue(name, value.filter((_, i) => i !== index), { shouldValidate: true });
    };

    return (
        <FormControl component="fieldset" error={isInvalid} fullWidth required={required}>
            <Grid item container>
                <Grid item xs="auto">
                    <InputLabel htmlFor={name} shrink={false}>
                        {label}
                    </InputLabel>
                    <OutlinedInput
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        inputProps={{ id: name, 'aria-label': label }}
                    />
                    {showError && (
                        <FormHelperText id={`${name}-error`} role="alert" aria-live="polite" error>
                            <ErrorMessage name={name} errors={errors} />
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item container xs style={{ alignItems: 'end', marginLeft: 15 }}>
                    <Controller
                        name={name}
                        control={control}
                        rules={rules}
                        render={({ field }) => (
                            field.value?.map((chip, index) => (
                                // we are using the index because we cannot guarantee the uniqueness of the chips
                                // eslint-disable-next-line react/no-array-index-key
                                <Grid item xs="auto" key={`chip-${index}`}>
                                    <Chip
                                        label={chip}
                                        onDelete={() => handleDelete(index)}
                                        style={{ margin: 5 }}
                                        deleteIcon={<CloseIcon />}
                                    />
                                </Grid>
                            ))
                        )}
                    />
                </Grid>
            </Grid>
        </FormControl>
    );
};

ChipArray.defaultProps = {
    errors: {},
    rules: {},
    showError: true,
    required: false,
};

ChipArray.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    errors: PropTypes.object,
    control: PropTypes.object.isRequired,
    rules: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    setValue: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired,

};

export default ChipArray;
