import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@mui/material/styles/styled';
import FormHelperText from '@mui/material/FormHelperText';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ErrorMessage } from '@hookform/error-message';
import { useForm, useFieldArray } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers/yup';
import { withStyles } from 'tss-react/mui';

import ValidationSchema from './validation-schema';
import DeleteQuestionModal from './components/delete-question-modal';
import DeleteSectionModal from './components/delete-section-modal';
import EditSectionIconModal from './components/section-icon-modal';
import AbandonChangesModal from '../../../components/abandon-changes-modal';

import SupportingQuestionInputs from './components/supporting-question-inputs';
import Select from '../../../components/inputs/select';
import TextField from '../../../components/inputs/textinput';
import PrimaryButton from '../../../components/buttons/primary-button';
import SecondaryButton from '../../../components/buttons/secondary-button';
import ConditionalQuestionLogic from './components/conditional-question-logic';
import Colors from '../../../styles/colors';
import IconList from '../../../wiring/icon-map';
import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import Styles from '../styles';
import WebClient from '../../../utils/web-client';
import { APISuccess, APIError } from '../../../redux/app';

const StyledPaper = styled(Paper)({
    borderRadius: 10,
    border: `1px solid ${Colors.lavenderGray}`,
    padding: 25,
    width: '100%',
});

const StyledHR = styled('hr')({
    border: 0,
    borderTop: `1px solid ${Colors.lavenderGray}`,
    marginLeft: -25,
    marginRight: -25,
    marginTop: 25,
    marginBottom: 25,
});

const SimpleButton = styled('button')(({ theme }) => ({
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
}));

const LeftSideBorderElement = styled('div')({
    backgroundColor: Colors.lavenderGray,
    width: 1,
    height: '100%',
    marginRight: 20,
    marginLeft: 5,
});

const questionTypes = [
    { key: 'shortText', label: 'Short Answer', helperText: 'Max 200 characters' },
    { key: 'longText', label: 'Long Answer', helperText: 'Max 500 characters' },
    { key: 'date', label: 'Date Entry (MM/DD/YYYY)' },
    { key: 'time', label: 'Time Entry' },
    { key: 'radioButton', label: 'Radio Button' }, // all select types add a param "options" to the question object
    { key: 'checkBoxes', label: 'Checkboxes' },
    { key: 'repeatingGroup', label: 'Repeating Group', helperText: 'All children will be a collection of questions' },
    { key: 'file', label: 'File Upload', helperText: 'File types supported: .pdf, .doc, .xls' },
    { key: 'instructions', label: 'Instructions', helperText: 'This will not be a question, but will be displayed as a header' }, // instructions add a param "instructions" to the question object
];

// new array of questionTypes that does not include the repeatingGroup type
// this is used by grandchild questions to prevent the repeatingGroup type from being an option
const questionTypesWithoutRepeatingGroup = questionTypes.filter(({ key }) => key !== 'repeatingGroup');

const questionIntialValue = {
    text: '',
    type: 'radioButton',
    required: true,
    questions: [],
};

const TestBuilder = ({ classes }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        reset,
        control,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ValidationSchema),
        defaultValues: {},
    });

    const watchValues = watch();

    const { fields, append, remove, move, update } = useFieldArray({
        control,
        name: 'sections',
        keyName: 'id',
    });

    // hydrate form on page load
    useEffect(() => {
        WebClient.get('/test')
            .then((reponse) => {
                const defaultValues = reponse.data;
                delete defaultValues.id;
                delete defaultValues.createdAt;
                delete defaultValues.userId;
                reset(defaultValues);
            }).catch((error) => {
                dispatch(APIError({ text: error?.response?.data?.errors?.error[0] }));
            });
    }, []);

    useEffect(() => {
        // go through each section, looking at the questions array and the questions array of each question
        // if the question is of type instructions, we want to clear out the questions array
        // grandchild questions dont support conditional children, so we don't need to worry about them
        let requiredUpdate = false;

        fields.forEach((section, sectionIndex) => {
            section.questions.forEach((question, questionIndex) => {
                if (question.type === 'instructions' && question.questions.length > 0) {
                    setValue(`sections[${sectionIndex}].questions[${questionIndex}].questions`, []);
                    setValue(`sections[${sectionIndex}].questions[${questionIndex}].options`, []);
                    requiredUpdate = true;
                }
                question.questions.forEach((childQuestion, childQuestionIndex) => {
                    if (childQuestion.type === 'instructions' && childQuestion.questions.length > 0) {
                        setValue(`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].questions`, []);
                        setValue(`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].options`, []);
                        requiredUpdate = true;
                    }
                });
            });
        });
        if (requiredUpdate) update();
    }, [watchValues, setValue]);

    const handleAddSection = () => {
        append({
            id: uuidv4(),
            name: '',
            icon: null,
            questions: [{ ...questionIntialValue, id: uuidv4() }],
        });
    };

    const handleAddQuestion = (sectionIndex, questionIndex, childQuestionIndex) => {
        if (childQuestionIndex !== undefined) { // if we have a child question index, we are adding a grandchild question
            setValue(`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].questions`, [
                ...fields[sectionIndex].questions[questionIndex].questions[childQuestionIndex].questions,
                {
                    ...questionIntialValue,
                    id: uuidv4(),
                },
            ]);
        } else if (questionIndex !== undefined) { // if we have a question index, we are adding a child question
            setValue(`sections[${sectionIndex}].questions[${questionIndex}].questions`, [
                ...fields[sectionIndex].questions[questionIndex].questions,
                {
                    ...questionIntialValue,
                    id: uuidv4(),
                },
            ]);
        } else {
            setValue(`sections[${sectionIndex}].questions`, [
                ...fields[sectionIndex].questions,
                {
                    ...questionIntialValue,
                    id: uuidv4(),
                },
            ]);
        }
        update();
    };
    const handleRemoveQuestion = useCallback((payload) => {
        const { sectionIndex, questionIndex, childQuestionIndex, grandchildQuestionIndex } = payload;

        setValue(`sections[${sectionIndex}].questions`, fields[sectionIndex].questions, { shouldValidate: true });

        if (grandchildQuestionIndex !== undefined) { // if we have a grandchild question index, we are removing a grandchild question
            fields[sectionIndex].questions[questionIndex].questions[childQuestionIndex].questions.splice(grandchildQuestionIndex, 1);
        } else if (childQuestionIndex !== undefined) { // if we have a child question index, we are removing a child question
            fields[sectionIndex].questions[questionIndex].questions.splice(childQuestionIndex, 1);
        } else {
            fields[sectionIndex].questions.splice(questionIndex, 1);
        }
        setValue(`sections[${sectionIndex}].questions`, fields[sectionIndex].questions, { shouldDirty: true, shouldValidate: true });
        // update();
    }, [fields, update]);

    const handleRemoveSection = (sectionIndex) => {
        remove(sectionIndex);
        // npm update(); <- this was causing issues with sections unmounting on DEV but not locallyd
    };

    const handleQuestionOrder = (sectionIndex, questionIndex, destinationQuestionIndex) => {
        // look to fields[sectionIndex].questions and reorder the question at questionIndex to destinationQuestionIndex
        const { questions } = fields[sectionIndex];
        const [removed] = questions.splice(questionIndex, 1);
        questions.splice(destinationQuestionIndex, 0, removed);
        setValue(`sections[${sectionIndex}].questions`, questions, { shouldDirty: true, shouldValidate: true });
        // update();
    };
    const handleChildQuestionOrder = (sectionIndex, questionIndex, childQuestionIndex, destinationQuestionIndex) => {
        const { questions } = fields[sectionIndex].questions[questionIndex];
        const [removed] = questions.splice(childQuestionIndex, 1);
        questions.splice(destinationQuestionIndex, 0, removed);
        setValue(`sections[${sectionIndex}].questions[${questionIndex}].questions`, questions, { shouldDirty: true, shouldValidate: true });
        // update();
    };
    const handleGrandchildQuestionOrder = (sectionIndex, questionIndex, childQuestionIndex, grandchildQuestionIndex, destinationQuestionIndex) => {
        const { questions } = fields[sectionIndex].questions[questionIndex].questions[childQuestionIndex];
        const [removed] = questions.splice(grandchildQuestionIndex, 1);
        questions.splice(destinationQuestionIndex, 0, removed);
        setValue(`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].questions`, questions, { shouldDirty: true, shouldValidate: true });
        // update();
    };

    const onSubmit = (data) => {
       const payload = data;
       // remove the organizationId from the payload, since it's not allowed to be set by the user
       delete payload.organizationId;
       WebClient.post('/tests', payload)
            .then(() => {
                dispatch(APISuccess({ text: 'Test Successfully saved' }));
                navigate('/dashboard');
            }).catch((error) => {
                dispatch(APIError({ text: error?.response?.data?.errors?.error[0] }));
            });
    };

    const [editSectionIconModalOpen, setEditSectionIconModalOpen] = useState(false);
    const [editingIconFieldName, setEditingIconFieldName] = useState('');
    const [editingIconValue, setEditingIconValue] = useState(null);
    const handleEditSectionIcon = (fieldName, sectionIndex) => {
        setEditSectionIconModalOpen(true);
        setEditingIconFieldName(fieldName);
        setEditingIconValue(watchValues.sections[sectionIndex]?.icon);
    };

    // Delete Question Modal
    // we pass the payload to the modal to handle the delete action
    // this fees like the long road for the data handling
    const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState(false);
    const [handleDeleteQuestionPayload, setHandleDeleteQuestionPayload] = useState(null);
    const [handleDeleteSectionPayload, setHandleDeleteSectionPayload] = useState(null);

    const handleDeleteQuestionModal = (payload) => {
        setDeleteQuestionModalOpen(true);
        setHandleDeleteQuestionPayload(payload);
    };

    // Delete Section Modal
    // we pass the payload to the modal to handle the delete action
    // this fees like the long road for the data handling
    const [deleteSectionModalOpen, setDeleteSectionModalOpen] = useState(false);
    const handleDeleteSectionModal = (payload) => {
        setDeleteSectionModalOpen(true);
        setHandleDeleteSectionPayload(payload);
    };

    // confirm abandon form modal
    const [abandonFormModalOpen, setAbandonFormModalOpen] = useState(false);

    return (
        <Grid item container className={classes.dashboardContainer}>
            <EditSectionIconModal
                open={editSectionIconModalOpen}
                // we need to update so field values are updated
                handleClose={() => { setEditSectionIconModalOpen(false); update(); }}
                fieldName={editingIconFieldName}
                setValue={setValue}
                defaultValue={editingIconValue}
            />
            <DeleteQuestionModal
                open={deleteQuestionModalOpen}
                handleClose={() => setDeleteQuestionModalOpen(false)}
                handleDeletePayload={handleDeleteQuestionPayload}
                handleDelete={handleRemoveQuestion}
            />
            <DeleteSectionModal
                open={deleteSectionModalOpen}
                handleClose={() => setDeleteSectionModalOpen(false)}
                handleDeletePayload={handleDeleteSectionPayload}
                handleDelete={handleRemoveSection}
            />
            <AbandonChangesModal
                open={abandonFormModalOpen}
                handleClose={() => setAbandonFormModalOpen(false)}
                handleConfirm={() => navigate(-1)}
            />

            <Grid item xs={12}>
                <Typography variant="h1">Test Builder</Typography>
                <Typography variant="body1">Add, edit, and delete questions for your report. Changes made will not impact testers who have already submitted the report previously.</Typography>
            </Grid>

            <Grid item xs={12} pt={5}>
                <StyledPaper>
                    <div style={{ marginBottom: 20 }}>
                        <TextField
                            errors={errors}
                            name="name"
                            label="Test Name"
                            required
                            register={register}
                            style={{ width: 280 }}
                        />
                    </div>
                    <TextField
                        errors={errors}
                        name="instructions"
                        label="Test Instructions"
                        required
                        register={register}
                        multiline
                        minRows={4}
                    />
                </StyledPaper>
            </Grid>

            {fields.map((item, sectionIndex) => {
                // if we have an icon value for the section, we want to display the icon
                let selectedIcon = '';
                if (item.icon) {
                    const iconObject = IconList.find(({ key }) => key === item.icon);
                    if (iconObject) {
                        selectedIcon = iconObject.value;
                    }
                }
                return (
                    <Grid item container xs={12} key={`section-${item.id}`} pt={5}>
                        <StyledPaper>
                            <Accordion defaultExpanded>
                                <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
                                    <Grid item container xs={12}>
                                        <Grid item xs="auto" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <IconButton onClick={(evt) => { evt.stopPropagation(); move(sectionIndex, sectionIndex - 1); }} disabled={sectionIndex === 0}>
                                                <KeyboardArrowUpIcon style={{ color: Colors.darkGrey2 }} />
                                            </IconButton>
                                            <IconButton onClick={(evt) => { evt.stopPropagation(); move(sectionIndex, sectionIndex + 1); }} disabled={sectionIndex === fields.length - 1}>
                                                <KeyboardArrowDownIcon style={{ color: Colors.darkGrey2 }} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={6} pr={3}>
                                            <TextField
                                                errors={errors}
                                                name={`sections[${sectionIndex}].name`}
                                                label="Section Name"
                                                required
                                                register={register}
                                                onClick={(evt) => evt.stopPropagation()}
                                            />
                                        </Grid>
                                        <Grid item xs="auto" style={{ display: 'flex', flexDirection: watchValues.sections[sectionIndex].icon ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
                                            <input name={`sections[${sectionIndex}].icon`} {...register} type="hidden" />
                                            {watchValues.sections[sectionIndex].icon && (
                                                <>
                                                    <div
                                                        style={{
                                                            backgroundColor: Colors.greyLightest,
                                                            borderRadius: 3,
                                                            border: `1px solid ${Colors.greyLight}`,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 40,
                                                            height: 40,
                                                            marginRight: 15,
                                                        }}
                                                    >
                                                        <img src={selectedIcon} alt="icon" style={{ height: 30 }} />
                                                    </div>
                                                    <SimpleButton type="button" onClick={(evt) => { evt.stopPropagation(); handleEditSectionIcon(`sections[${sectionIndex}].icon`, sectionIndex); }}>Edit Section Icon</SimpleButton>
                                                </>
                                            )}
                                            {!watchValues.sections[sectionIndex].icon && (
                                                <>
                                                    <SimpleButton type="button" onClick={(evt) => { evt.stopPropagation(); handleEditSectionIcon(`sections[${sectionIndex}].icon`, sectionIndex); }}>Select Section Icon</SimpleButton>
                                                    <FormHelperText id={`sections[${sectionIndex}].icon-error`} role="alert" aria-live="polite" error>
                                                        <ErrorMessage name={`sections[${sectionIndex}].icon`} errors={errors} />
                                                    </FormHelperText>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginRight: 30, marginTop: 15 }}>
                                            <IconButton onClick={(evt) => { evt.stopPropagation(); handleDeleteSectionModal(sectionIndex); }}><DeleteIcon /></IconButton>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <Grid item xs={12}>
                                    <StyledHR />
                                </Grid>
                                {item.questions.map((question, questionIndex) => (
                                    <Grid item container xs={12} key={`section-${item.id}-${question.id}`} component={AccordionDetails} ml={3}>
                                        <Grid item xs="auto" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <IconButton onClick={() => handleQuestionOrder(sectionIndex, questionIndex, questionIndex - 1)} disabled={questionIndex === 0}>
                                                <KeyboardArrowUpIcon style={{ color: Colors.darkGrey2 }} />
                                            </IconButton>
                                            <IconButton onClick={() => handleQuestionOrder(sectionIndex, questionIndex, questionIndex + 1)} disabled={questionIndex === item.questions.length - 1}>
                                                <KeyboardArrowDownIcon style={{ color: Colors.darkGrey2 }} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs container spacing={3}>
                                            <Grid item xs={5}>
                                                <TextField
                                                    errors={errors}
                                                    name={`sections[${sectionIndex}].questions[${questionIndex}].text`}
                                                    label="Question Text"
                                                    required
                                                    register={register}
                                                    inputProps={{
                                                        maxLength: 500,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                    errors={errors}
                                                    name={`sections[${sectionIndex}].questions[${questionIndex}].type`}
                                                    label="Question Type"
                                                    control={control}
                                                    placeholder="Select"
                                                    displayEmpty
                                                    renderValue={(value) => value !== '' && questionTypes.find(({ key }) => key.toString() === value.toString())?.label}
                                                >
                                                    {questionTypes.map((option) => <MenuItem value={option.key} key={option.label}>{option.label}</MenuItem>)}
                                                </Select>
                                            </Grid>
                                            <Grid item xs style={{ textAlign: 'right' }}>
                                                <IconButton onClick={() => handleDeleteQuestionModal({ sectionIndex, questionIndex })}><DeleteIcon /></IconButton>
                                            </Grid>
                                            {/* select's and instructions require supporting inputs */}
                                            <SupportingQuestionInputs
                                                question={watchValues.sections[sectionIndex].questions[questionIndex]}
                                                fieldName={`sections[${sectionIndex}].questions[${questionIndex}]`}
                                                control={control}
                                                errors={errors}
                                                setValue={setValue}
                                                register={register}
                                            />
                                        </Grid>
                                        {/* CHILD QUESTION LOOP */}
                                        {watchValues.sections[sectionIndex].questions[questionIndex].questions.map((childQuestion, childQuestionIndex) => (
                                            <Grid item container key={`${question.id} - ${childQuestion.id}`} style={{ marginLeft: 45 }}>
                                                <Grid item xs="auto" style={{ display: 'flex', flexDirection: 'column' }} sx={{ mt: childQuestionIndex > 0 ? 3 : 1 }}>
                                                    <IconButton onClick={(evt) => { evt.stopPropagation(); handleChildQuestionOrder(sectionIndex, questionIndex, childQuestionIndex, childQuestionIndex - 1); }} disabled={childQuestionIndex === 0}>
                                                        <KeyboardArrowUpIcon style={{ color: Colors.darkGrey2 }} />
                                                    </IconButton>
                                                    <IconButton onClick={(evt) => { evt.stopPropagation(); handleChildQuestionOrder(sectionIndex, questionIndex, childQuestionIndex, childQuestionIndex + 1); }} disabled={childQuestionIndex === question.questions.length - 1}>
                                                        <KeyboardArrowDownIcon style={{ color: Colors.darkGrey2 }} />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <LeftSideBorderElement />
                                                </Grid>
                                                <Grid item xs container sx={{ mt: childQuestionIndex > 0 && 2 }}>
                                                    {/* conditonal style note: we want to create seperation between the child questions while having continuous left border */}
                                                    <Grid item container xs={11} spacing={3}>
                                                        <Grid item container xs={12}>
                                                            <ConditionalQuestionLogic
                                                                fieldName={`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].renderLogic`}
                                                                selectedCondition={watchValues.sections[sectionIndex].questions[questionIndex].questions[childQuestionIndex].renderLogic?.condition}
                                                                parentQuestion={watchValues.sections[sectionIndex].questions[questionIndex]}
                                                                control={control}
                                                                errors={errors}
                                                                register={register}
                                                                getValues={getValues}
                                                                setValue={setValue}
                                                                watchValues={watchValues}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                errors={errors}
                                                                name={`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].text`}
                                                                label="Question Text"
                                                                required
                                                                register={register}
                                                                inputProps={{
                                                                    maxLength: 500,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                errors={errors}
                                                                name={`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].type`}
                                                                label="Question Type"
                                                                control={control}
                                                                placeholder="Select"
                                                                displayEmpty
                                                                renderValue={(value) => questionTypes.find(({ key }) => key.toString() === value.toString())?.label}
                                                            >
                                                                {questionTypes.map((option) => <MenuItem value={option.key} key={option.label}>{option.label}</MenuItem>)}
                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SupportingQuestionInputs
                                                                question={watchValues.sections[sectionIndex].questions[questionIndex].questions[childQuestionIndex]}
                                                                fieldName={`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}]`}
                                                                control={control}
                                                                errors={errors}
                                                                setValue={setValue}
                                                                register={register}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs style={{ display: 'flex', alignItems: 'start' }}>
                                                        <IconButton onClick={() => handleDeleteQuestionModal({ sectionIndex, questionIndex, childQuestionIndex })}><DeleteIcon /></IconButton>
                                                    </Grid>
                                                    {/* GRANDCHILD QUESTION LOOP */}
                                                    {watchValues.sections[sectionIndex].questions[questionIndex]?.questions[childQuestionIndex]?.questions.map((grandchildQuestion, grandchildQuestionIndex) => (
                                                        <Grid item container key={`${question.id} - ${childQuestion.id}-${grandchildQuestion.id}`}>
                                                            <Grid item xs="auto" style={{ display: 'flex', flexDirection: 'column' }} sx={{ mt: childQuestionIndex > 0 ? 3 : 1 }}>
                                                                <IconButton onClick={(evt) => { evt.stopPropagation(); handleGrandchildQuestionOrder(sectionIndex, questionIndex, childQuestionIndex, grandchildQuestionIndex, grandchildQuestionIndex - 1); }} disabled={grandchildQuestionIndex === 0}>
                                                                    <KeyboardArrowUpIcon style={{ color: Colors.darkGrey2 }} />
                                                                </IconButton>
                                                                <IconButton onClick={(evt) => { evt.stopPropagation(); handleGrandchildQuestionOrder(sectionIndex, questionIndex, childQuestionIndex, grandchildQuestionIndex, grandchildQuestionIndex + 1); }} disabled={grandchildQuestionIndex === watchValues.sections[sectionIndex].questions[questionIndex].questions[childQuestionIndex].questions.length - 1}>
                                                                    <KeyboardArrowDownIcon style={{ color: Colors.darkGrey2 }} />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs="auto" style={{ marginLeft: -15 }}>
                                                                <LeftSideBorderElement style={{ marginLeft: 15 }} />
                                                            </Grid>
                                                            <Grid item xs container>
                                                                <Grid item container xs={11} spacing={3}>
                                                                    <Grid item container xs={12}>
                                                                        <ConditionalQuestionLogic
                                                                            fieldName={`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].questions[${grandchildQuestionIndex}].renderLogic`}
                                                                            selectedCondition={watchValues.sections[sectionIndex].questions[questionIndex].questions[childQuestionIndex].questions[grandchildQuestionIndex].renderLogic?.condition}
                                                                            parentQuestion={watchValues.sections[sectionIndex].questions[questionIndex].questions[childQuestionIndex]}
                                                                            control={control}
                                                                            errors={errors}
                                                                            register={register}
                                                                            getValues={getValues}
                                                                            setValue={setValue}
                                                                            watchValues={watchValues}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <TextField
                                                                            errors={errors}
                                                                            name={`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].questions[${grandchildQuestionIndex}].text`}
                                                                            label="Question Text"
                                                                            required
                                                                            register={register}
                                                                            inputProps={{
                                                                                maxLength: 500,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Select
                                                                            errors={errors}
                                                                            name={`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].questions[${grandchildQuestionIndex}].type`}
                                                                            label="Question Type"
                                                                            control={control}
                                                                            placeholder="Select"
                                                                            displayEmpty
                                                                            renderValue={(value) => questionTypes.find(({ key }) => key.toString() === value.toString())?.label}
                                                                        >
                                                                            {questionTypesWithoutRepeatingGroup.map((option) => <MenuItem value={option.key} key={option.label}>{option.label}</MenuItem>)}
                                                                        </Select>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <SupportingQuestionInputs
                                                                            question={watchValues.sections[sectionIndex].questions[questionIndex].questions[childQuestionIndex].questions[grandchildQuestionIndex]}
                                                                            fieldName={`sections[${sectionIndex}].questions[${questionIndex}].questions[${childQuestionIndex}].questions[${grandchildQuestionIndex}]`}
                                                                            control={control}
                                                                            errors={errors}
                                                                            setValue={setValue}
                                                                            register={register}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs style={{ display: 'flex', alignItems: 'start' }}>
                                                                    <IconButton onClick={() => handleDeleteQuestionModal({ sectionIndex, questionIndex, childQuestionIndex, grandchildQuestionIndex })}><DeleteIcon /></IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    {watchValues.sections[sectionIndex].questions[questionIndex].questions[childQuestionIndex].type !== 'instructions' && (
                                                        <Grid item xs={12}>
                                                            <SimpleButton type="button" onClick={() => handleAddQuestion(sectionIndex, questionIndex, childQuestionIndex)}><AddCircleOutlineIcon style={{ marginBottom: 5, marginRight: 5, fontSize: 17 }} />{watchValues.sections[sectionIndex].questions[questionIndex].questions[childQuestionIndex].type === 'repeatingGroup' ? 'Add Question to Group' : 'Add Conditional Question'}</SimpleButton>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Grid item xs={12} mt={3}>
                                            {watchValues.sections[sectionIndex].questions[questionIndex].type !== 'instructions' && (
                                                <SimpleButton type="buttton" onClick={() => handleAddQuestion(sectionIndex, questionIndex)}><AddCircleOutlineIcon style={{ marginBottom: 5, marginRight: 5, fontSize: 17 }} />{watchValues.sections[sectionIndex].questions[questionIndex].type === 'repeatingGroup' ? 'Add Question to Group' : 'Add Conditional Question'}</SimpleButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <hr />
                                </Grid>
                                <Grid item xs={12} pt={5}>
                                    <SimpleButton type="button" onClick={() => handleAddQuestion(sectionIndex)}><AddCircleOutlineIcon style={{ marginBottom: 5, marginRight: 5, fontSize: 17 }} />Add Question</SimpleButton>
                                </Grid>
                            </Accordion>
                        </StyledPaper>
                    </Grid>
                );
            })}
            <Grid item container xs={12} pt={5}>
                <Grid item xs={12}>
                    <SecondaryButton className={classes.secondaryButton} onClick={handleAddSection}><AddCircleOutlineIcon style={{ marginRight: 5, fontSize: 17 }} />Add Section</SecondaryButton>
                </Grid>
                {/* if the errors object has a sections key, we want to display the errors. the error should be organized by section and include the question text and associated error
                */}
                <Grid item xs={12} pt={5} style={{ textAlign: 'right' }}>
                    {errors.sections && (
                        <Typography variant="body1" color="error">There are errors in your form. Please correct them before saving.</Typography>
                    )}
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }} pt={4}>
                    <SecondaryButton className={classes.secondaryButton} onClick={() => setAbandonFormModalOpen(true)} sx={{ mr: 3 }}>Cancel</SecondaryButton>
                    <PrimaryButton
                        onClick={handleSubmit(onSubmit)}
                        className={classes.primaryButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        disabled={Object.keys(errors).length > 0}
                    >
                        Finish and Save
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

TestBuilder.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(TestBuilder, combinedStyles);
