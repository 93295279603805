import { createContext, useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ColorSchemes from './color-schemes';

const HTML_ELEMENT = document.documentElement;

// this reducer is only intended to store the state of the styles that
// are able to be overridden by the user with the DisplayOptionsControlPanel
// it is not meant to store the entire Material theme
const initialTheme = {
    background: null,
    // default to 16px because the font sizes in the theme assume this is the starting size
    fontSize: 16,
    palette: {
        primary: {
            main: null,
        },
    },
};

const ThemeTypes = {
    UPDATE_BACKGROUND_COLOR: 'UPDATE_BACKGROUND_COLOR',
    INCREASE_FONT_SIZE: 'INCREASE_FONT_SIZE',
    DECREASE_FONT_SIZE: 'DECREASE_FONT_SIZE',
    UPDATE_COLOR_SCHEME: 'UPDATE_COLOR_SCHEME',
};

const themeReducer = (state, action) => {
    switch (action.type) {
        case ThemeTypes.UPDATE_BACKGROUND_COLOR:
            return {
                ...state,
                background: action.background,
            };
        case ThemeTypes.INCREASE_FONT_SIZE:
            return {
                ...state,
                fontSize: state.fontSize + 1,
                htmlFontSize: state.fontSize + 1,
            };
        case ThemeTypes.DECREASE_FONT_SIZE:
            return {
                ...state,
                fontSize: state.fontSize - 1,
                htmlFontSize: state.fontSize - 1,
            };
        case ThemeTypes.UPDATE_COLOR_SCHEME: {
            const colorScheme = ColorSchemes.find((scheme) => scheme.key === action.scheme);
            return {
                ...state,
                palette: {
                    primary: colorScheme.primary,
                },
            };
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const increaseFontSize = () => ({
    type: ThemeTypes.INCREASE_FONT_SIZE,
});

export const decreaseFontSize = () => ({
    type: ThemeTypes.DECREASE_FONT_SIZE,
});

export const updateBackground = (color) => ({
    type: ThemeTypes.UPDATE_BACKGROUND_COLOR,
    background: color,
});

export const updateColorScheme = (scheme) => ({
    type: ThemeTypes.UPDATE_COLOR_SCHEME,
    scheme,
});

const ThemeStateContext = createContext();
const ThemeDispatchContext = createContext();

export const ThemeContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(themeReducer, initialTheme);

    useEffect(() => {
        // update the <html> font-size so that all styles using rem's will size appropriately
        HTML_ELEMENT.style.fontSize = `${state.fontSize}px`;
    }, [state.fontSize]);

    return (
        <ThemeStateContext.Provider value={state}>
            <ThemeDispatchContext.Provider value={dispatch}>{children}</ThemeDispatchContext.Provider>
        </ThemeStateContext.Provider>
    );
};

ThemeContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useThemeState = () => {
    const context = useContext(ThemeStateContext);
    if (context === undefined) {
        throw new Error('useThemeState must be used within a ThemeContextProvider');
    }
    return context;
};

export const useThemeDispatch = () => {
    const context = useContext(ThemeDispatchContext);
    if (context === undefined) {
        throw new Error('useThemeDispatch must be used within a ThemeContextProvider');
    }
    return context;
};
