import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import CheckIcon from '@mui/icons-material/Check';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import TextInput from '../../../../components/inputs/textinput';
import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import InputStyles from '../../../../styles/inputs';
import FileUploader from '../../../../components/file-uploader';
import validationSchema from '../../../../validation/validation-schema-organizations';
import { createOrganization, updateOrganization } from '../../../../redux/organizations';
import useSnackbar from '../../../../utils/use-snackbar';
import NotificationSnackbar from '../../../../components/notification-snackbar';
import makeEmptyEntriesNull from '../../../../utils/make-empty-entries-null';
import ColorSchemes from '../../../../wiring/color-schemes';
import Colors from '../../../../styles/colors';
import { fetchSingleOrganization } from '../../../../api';

const ColorSelectButton = styled(Button,
    { shouldForwardProp: (prop) => prop !== 'color' })(({ color }) => ({
    backgroundColor: color,
    borderRadius: 5,
    border: `2px solid ${Colors.lavenderGray}`,
    height: 48,
    width: 48,
    minWidth: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    '&:hover': {
        backgroundColor: color,
    },
}));

const CreateEditOrgForm = ({ classes }) => {
    const { orgId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // we hold this value in state, and then attach it to the payload during save
    const [uploadedAttachmentsLocallyManagement, setUploadedAttachmentsLocallyManaged] = useState([null]);
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();
    const isEditing = orgId !== 'new';

    const [initialLogoValue, setInitialLogoValue] = useState(null);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: '',
            colorScheme: '',
            logo: undefined,
        },
    });

    useEffect(() => {
       const fetchData = async () => {
            const data = await dispatch(fetchSingleOrganization(orgId));
            if (data) {
                const { name, colorScheme, logo } = data;
                setValue('name', name);
                setValue('colorScheme', colorScheme);
                setValue('logo', logo);
                setInitialLogoValue(logo);
            }
        };
        // this is hacky, but we share a route with /:orgId and /new, very different meanings
        if (orgId && orgId !== 'new') {
            fetchData();
        }
    }, [orgId]);

    const onSubmitSuccess = () => {
        navigate('/dashboard/organizations');
        handleOpenSnackbar({ text: 'Saved successfully', type: 'success' });
    };

    const onSubmitNew = (values) => {
        const newOrgValues = { ...makeEmptyEntriesNull(values) };
        // eslint-disable-next-line prefer-destructuring
        newOrgValues.logo = uploadedAttachmentsLocallyManagement;

        dispatch(createOrganization({ org: newOrgValues, onSuccess: onSubmitSuccess }));
    };

    const onSubmitEdit = (values) => {
        const updateValues = { ...makeEmptyEntriesNull(values) };

        // eslint-disable-next-line prefer-destructuring
        if (uploadedAttachmentsLocallyManagement !== null) {
            if (typeof updateValues.logo !== 'string') {
                updateValues.logo = uploadedAttachmentsLocallyManagement;
            } else {
                delete updateValues.logo;
            }
        } else {
            delete updateValues.logo;
        }

        dispatch(updateOrganization({ orgId, org: updateValues, onSuccess: onSubmitSuccess }));
    };

    const handleCancel = () => {
        navigate('/dashboard/organizations');
    };

    const logoValue = watch('logo');
    const colorSchemeValue = watch('colorScheme');

    // UPLOADS
    const handleFileUploadChange = useCallback(
        (uploadedFile) => {
            setUploadedAttachmentsLocallyManaged(uploadedFile);
        },
        [setUploadedAttachmentsLocallyManaged],
    );

    const handleFileUploadClear = useCallback(
        () => {
            handleFileUploadChange(null);
        },
        [handleFileUploadChange],
    );

    return (
        <form onSubmit={handleSubmit(isEditing ? onSubmitEdit : onSubmitNew)} noValidate>
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        name="name"
                        label="Name"
                        errors={errors}
                        required
                        register={register}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </Grid>
                <Grid item xs={12} pt={5}>
                    <InputLabel shrink={false} style={{ top: 0, left: 0 }}>Upload Logo</InputLabel>
                    <Typography variant="body2" sx={{ pb: 2 }}>Supported image types include: JPG, PNG, SVG</Typography>
                    <FileUploader
                        label="Upload Logo"
                        name="logo"
                        allowedFileTypes={['.png', '.jpg', '.jpeg', '.svg']}
                        previousFilename={initialLogoValue && (initialLogoValue === logoValue) ? 'Previous Upload' : null}
                        onChange={(uploadedFile) => handleFileUploadChange(uploadedFile)}
                        onClear={() => handleFileUploadClear()}
                        register={register}
                        setValue={setValue}
                    />
                    <div className={classes.errorMessage} role="alert" aria-live="polite" style={{ fontSize: 14 }}>
                        <ErrorMessage name="logo" errors={errors} />
                    </div>
                </Grid>
                <Grid item container xs={12} style={{ display: 'block' }} pt={5} pb={6}>
                    <InputLabel shrink={false} style={{ top: 0, left: 0, display: 'block' }} sx={{ pb: 2 }}>Color Scheme</InputLabel>
                    <div style={{ display: 'flex' }}>
                        {ColorSchemes.map((color) => (
                            <ColorSelectButton
                                color={color.primary.main}
                                key={color.key}
                                onClick={() => setValue('colorScheme', color.key)}
                            >
                                {color.key === colorSchemeValue && <CheckIcon style={{ color: 'white' }} />}
                            </ColorSelectButton>
                        ))}
                    </div>
                    <div className={classes.errorMessage} role="alert" aria-live="polite" style={{ fontSize: 14 }}>
                        <ErrorMessage name="colorScheme" errors={errors} />
                    </div>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Button
                            className={`${classes.primaryButton}`}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            aria-label={isEditing ? 'Save updates to org' : null}
                            type="submit"
                            sx={{ mr: 1 }}
                        >
                            {isEditing ? 'Save' : 'Create Organization'}
                        </Button>
                        &nbsp;
                        {isEditing && (
                            <Button
                                className={`${classes.secondaryButton}`}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                aria-label="Return to orgs"
                                type="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <NotificationSnackbar {...snackbarProps} />
        </form>
    );
};

CreateEditOrgForm.propTypes = {
    org: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        colorScheme: PropTypes.string,
        logo: PropTypes.string,
    }),
    classes: PropTypes.object.isRequired,
};

CreateEditOrgForm.defaultProps = {
    org: null,
};

const combinedStyles = CombineStyles(InputStyles, ButtonStyles);
export default withStyles(CreateEditOrgForm, combinedStyles);
