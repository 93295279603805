import Colors from '../../../../styles/colors';

const styles = () => ({
    wrapper: {
        backgroundColor: Colors.darkGrey,
        height: 60,
    },
    buttonText: {
        color: Colors.white,
        lineHeight: '3.1rem',
        padding: '6px 8px',
    },
    displayOptionsButton: {
        minHeight: 30,
        minWidth: 185,
        padding: '6px 8px',
        margin: 10,
        fontSize: '0.9375rem',
        borderRadius: 5,
        fontWeight: 'bold',
        color: Colors.white,
        backgroundColor: 'rgba(238, 238, 241, 0.2)',

        '&:hover, &:focus:hover': {
            backgroundColor: 'rgba(238, 238, 241, 0.5)',
            boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)',
        },
        '&:active': {
            backgroundColor: 'rgba(238, 238, 241, 0.5)',
        },
        '&:focus': {
            backgroundColor: 'rgba(238, 238, 241, 0.2)',
        },
    },
    controlPanel: {
        width: 300,
        height: 200,
        borderRadius: 5,
        backgroundColor: Colors.white,
        boxShadow: '0 10px 30px 0 rgba(25,25,25,0.3)',
    },
});

export default styles;
