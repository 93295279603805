import Image1 from '../images/section-icons/icon-1.png';
import Image2 from '../images/section-icons/icon-2.png';
import Image3 from '../images/section-icons/icon-3.png';
import Image4 from '../images/section-icons/icon-4.png';
import Image5 from '../images/section-icons/icon-5.png';
import Image6 from '../images/section-icons/icon-6.png';
import Image7 from '../images/section-icons/icon-7.png';
import Image8 from '../images/section-icons/icon-8.png';
import Image9 from '../images/section-icons/icon-9.png';

export default [
    { key: 'icon-1', value: Image1 },
    { key: 'icon-2', value: Image2 },
    { key: 'icon-3', value: Image3 },
    { key: 'icon-4', value: Image4 },
    { key: 'icon-5', value: Image5 },
    { key: 'icon-6', value: Image6 },
    { key: 'icon-7', value: Image7 },
    { key: 'icon-8', value: Image8 },
    { key: 'icon-9', value: Image9 },
];
