import * as yup from 'yup';
import { isValid as isDateTimeValid } from 'date-fns';

import testConditional from '../utils/test-conditional';

const REQUIRED_MESSAGE = 'Please answer this question';
const INVALID_DATE_MESSAGE = 'Please enter a valid date';
// const INVALID_TIME_MESSAGE = 'Please enter a valid time';
const ARRAY_TRANSFORM = (value, originalValue) => {
    if (Array.isArray(value)) {
        const { length } = value;
        return !!value && length === 0 ? null : value;
    }

    if (typeof originalValue === 'string') {
        // eslint-disable-next-line react/destructuring-assignment
        return originalValue.split(',');
    }

    // if not array or string then probably not valid
    return null;
};

const optionalText = yup
    .string()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .notRequired();

const requiredText = yup.string().nullable().required(REQUIRED_MESSAGE);

const optionalDate = yup.date().nullable().typeError(INVALID_DATE_MESSAGE).notRequired();

const requiredDate = yup
    .date()
    .nullable()
    .typeError(INVALID_DATE_MESSAGE)
    .required(REQUIRED_MESSAGE)
    .test('isDateValid', INVALID_DATE_MESSAGE, (value) => isDateTimeValid(value));

/*
we are now saving time as a string, ex "12:00 AM"
const optionalTime = yup.date().nullable().typeError(INVALID_TIME_MESSAGE).notRequired();

const requiredTime = yup
    .date()
    .nullable()
    .typeError(INVALID_TIME_MESSAGE)
    .required(REQUIRED_MESSAGE)
    .test('isTimeValid', INVALID_TIME_MESSAGE, (value) => isDateTimeValid(value));
*/

const optionalArray = yup.array().transform(ARRAY_TRANSFORM).nullable().notRequired();

const requiredArray = yup.array().transform(ARRAY_TRANSFORM).nullable().required(REQUIRED_MESSAGE);

/*
rules need to mirror question type keys founnd in redux/reports/index
const questionTypes = [
    { key: 'shortText', label: 'Short Answer', helperText: 'Max 200 characters' },
    { key: 'longText', label: 'Long Answer', helperText: 'Max 500 characters' },
    { key: 'date', label: 'Date Entry (MM/DD/YYYY)' },
    { key: 'time', label: 'Time Entry' },
    { key: 'radioButton', label: 'Radio Button' }, // all select types add a param "options" to the question object
    { key: 'checkBoxes', label: 'Checkboxes' },
    { key: 'repeatingGroup', label: 'Repeating Group', helperText: 'All children will be a collection of questions' },
    { key: 'file', label: 'File Upload', helperText: 'File types supported: .pdf, .doc, .xls' },
    { key: 'instructions', label: 'Instructions', helperText: 'This will not be a question, but will be displayed as a header' }, // instructions add a param "instructions" to the question object
];
*/
const ruleTypes = {
    shortText: {
        optional: optionalText,
        required: requiredText,
    },
    longText: {
        optional: optionalText,
        required: requiredText,
    },
    date: {
        optional: optionalDate,
        required: requiredDate,
    },
    time: {
        optional: optionalText,
        required: requiredText,
    },
    radioButton: {
        optional: optionalText,
        required: requiredText,
    },
    checkBoxes: {
        optional: optionalArray,
        required: requiredArray,
    },
    repeatingGroup: { // collecting the iterating value, which is listed as a string in the menu
        optional: optionalText,
        required: requiredText,
    },
    // TODO: this wasn't captured in the original validation set, it's unclear why/how it worked. perhaps local validation?
    // perhaps file inputs weren't required, therefor they could just be ignored?
    file: {
        optional: optionalText,
        required: optionalText,
    },
};

const getValidation = (questions, reportAnswers) => {
    const sectionQuestions = [...questions];

    const rules = {};
    const determineQuestionRules = (question, parentQuestion = null) => {
        const rulesForType = ruleTypes[question.type];

        // we have nothing to do with instructions type questions
        // file type questions are not required (even though in data they may be marked as such, they are not required for validation)
        if (question.type === 'instructions' || question.type === 'file') return;

        // if no render logic, then no condition when the question shouldn't be shown
        if (!question.renderLogic) {
            rules[question.id] = question.required ? rulesForType.required : rulesForType.optional;
        // else if, there is render logic, we can determine if the question should be shown
        // only if it's been shown, should be determine if it's required, and what type of validation it should have
        // if the question is not shown, then it's not required
        } else if (testConditional(question.renderLogic, reportAnswers[parentQuestion.id])) {
            rules[question.id] = question.required ? rulesForType.required : rulesForType.optional;
        }
        // if we get here in code, then the question is not shown, so no validation is needed
    };
    sectionQuestions.forEach((question) => {
        // handle L1 question rules
        determineQuestionRules(question);

        // if there are nested questions

        if (question.questions) {
            // this array is simply to hold the child questions (L2
            // to then be used to review L3 questions
            // all validation roles are outside of this variable
            let childQuestions = [];

            const L1WatchValue = parseInt(reportAnswers[question.id], 10);
            const L1IsRepeatingGroup = question.type === 'repeatingGroup';

            // if repeating group, loop over the number of times the group should repeat
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < (L1IsRepeatingGroup ? L1WatchValue : 1); i++) {
                // L2 question loop for children
                // eslint-disable-next-line no-loop-func
                question.questions.forEach((childQuestion) => {
                    // add the iterative number to the question id
                    const amendedQuestion = { ...childQuestion };
                    if (L1IsRepeatingGroup) {
                        amendedQuestion.id = `${childQuestion.id}-${i}-${question.id}`;
                    }
                    // pass in the current question, and it's parent as reference for the renderLogic
                    determineQuestionRules(amendedQuestion, question);
                    childQuestions.push(amendedQuestion);
                });
            }
            // determine if there the renderLogic has been met for the child questions
            // it will always pass for repeatingGroup questions
            childQuestions = childQuestions.filter((childQuestion) => testConditional(childQuestion.renderLogic, reportAnswers[question.id]));

            // L3 question loop
            // to be in alignment with /reports/components/section, id amdendments of L3 questions are needed for all questions
            // we don't arregate the L3 questions like we did with L2, because there is no downsteam activities to do with them
            childQuestions.forEach((childQuestion) => {
                if (childQuestion.questions) {
                    const parentL2WatchValue = parseInt(reportAnswers[childQuestion.id], 10);
                    const parentL2IsRepeatingGroup = childQuestion.type === 'repeatingGroup';

                    // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < (parentL2IsRepeatingGroup ? parentL2WatchValue : 1); i++) {
                        // eslint-disable-next-line no-loop-func
                        childQuestion.questions.forEach((grandChildQuestion) => {
                            if (grandChildQuestion.required) {
                                const amendedQuestion = { ...grandChildQuestion };
                                amendedQuestion.id = `${grandChildQuestion.id}-${i}-${childQuestion.id}`;

                                determineQuestionRules(amendedQuestion, childQuestion);
                            }
                        });
                    }
                }
            });
        }
    });

    return yup.object().shape(rules);
};

export default getValidation;
