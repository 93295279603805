import Colors from '../../../../styles/colors';

const styles = () => ({
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 20,
        '&:hover': {
            '& .header, $body': {},
            '& $headerText': {},
            '& .property-body': {},
        },
    },
    progressInProgress: {
        '& .header': {
            border: `1px solid ${Colors.lavenderGray}`,
            borderBottom: 0,
        },
        '& .property-body': {
            border: `1px solid ${Colors.lavenderGray}`,
            borderTop: 0,
        },
    },
    progressCompleted: {
        '& $headerText': {},
        '& .header': {
            backgroundColor: Colors.lavenderGray,
            border: `1px solid ${Colors.lavenderGray}`,
            borderBottom: 0,
        },
        '& .property-body': {
            border: `1px solid ${Colors.lavenderGray}`,
            borderTop: 0,
        },
    },
    progressReopened: {
        '& .header': {
            backgroundColor: `rgba(${Colors.electricBlueRGB}, 0.3)`,
            border: `1px solid ${Colors.lavenderGray}`,
            borderBottom: 0,
        },
        '& .property-body': {
            border: `1px solid ${Colors.lavenderGray}`,
            borderTop: 0,
        },
    },
    header: {
        backgroundColor: Colors.antiflashWhite,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 20,
        width: '100%',
        borderRadius: '10px 10px 0 0',
    },
    headerText: {
        fontSize: '0.8125rem',
        fontWeight: 600,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.white,
        width: '100%',
        height: '100%',
        borderRadius: '0 0 10px 10px',
        padding: '0px 25px 25px 25px',
    },
    bodyHeaderText: {
        fontWeight: 700,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '80%',
        textAlign: 'center',
        padding: '20px 20px 5px',
    },
    bodyText: {
        fontSize: '0.875rem',
        marginBottom: '30px',
    },
    buttonIcon: {
        width: 15,
        marginRight: 5,
    },
    propertyImg: {
        marginTop: 15,
        marginBottom: 15,
        borderRadius: '50%',
        height: 90,
        width: 90,
    },
});
export default styles;
