import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const MENU_PROPS = {
    id: 'select-menu',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: -5,
        horizontal: 'center',
    },
    elevation: 0,
};

const ControlledSelectInput = ({
    name,
    label,
    labelledBy,
    placeholder,
    helperText,
    errors,
    required,
    control,
    showError,
    menuProps,
    renderValue,
    children,
    validationRules,
    ...otherProps // pass through any Material UI Select API props
}) => {
    const isInvalid = !!errors[name];

    let describedBy = helperText ? `${name}-helper` : null;
    if (showError && isInvalid) {
        describedBy = describedBy ? `${describedBy} ${name}-error` : `${name}-error`;
    }

    const elementProps = {
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        id: name,
        required,
    };

    const defaultRenderValue = (selected) => {
        if ((placeholder || placeholder === '') && (!selected || selected === '' || selected[0] === '' || selected.length === 0)) {
            return <span>{placeholder}</span>;
        }

        // if multiple is true
        if (Array.isArray(selected)) {
            return selected.join(', ');
        }

        return selected;
    };

    return (
        <FormControl component="fieldset" error={isInvalid} fullWidth required={required}>
            {label && (
                <InputLabel htmlFor={name} required={required} error={isInvalid} shrink={false}>
                    {label}
                </InputLabel>
            )}

            <Controller
                name={name}
                control={control}
                rules={validationRules}
                defaultValue=""
                render={({ field }) => {
                    const { value, onChange, onBlur } = field;
                    let groomedValue = value || (otherProps?.multiple ? [] : '');
                    if (otherProps?.multiple && !Array.isArray(groomedValue)) {
                        groomedValue = [groomedValue];
                    }
                    return (
                        <Select
                            variant="outlined"
                            id={name}
                            labelId={labelledBy}
                            value={groomedValue}
                            error={isInvalid}
                            placeholder={placeholder}
                            MenuProps={menuProps || MENU_PROPS}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputProps={elementProps}
                            renderValue={renderValue || defaultRenderValue}
                            {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
                        >
                            {children}
                        </Select>
                    );
                }}
            />

            {helperText && <FormHelperText id={`${name}-helper`}>{helperText}</FormHelperText>}

            {showError && (
                <FormHelperText id={`${name}-error`} role="alert" aria-live="polite" error>
                    <ErrorMessage name={name} errors={errors} />
                </FormHelperText>
            )}
        </FormControl>
    );
};

ControlledSelectInput.defaultProps = {
    label: null,
    labelledBy: null,
    placeholder: null,
    helperText: null,
    errors: {},
    required: false,
    showError: true,
    menuProps: null,
    renderValue: null,
    validationRules: {},
};

ControlledSelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelledBy: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    errors: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    menuProps: PropTypes.object,
    renderValue: PropTypes.func,
    control: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    validationRules: PropTypes.object,
};

export default ControlledSelectInput;
