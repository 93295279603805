import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import noop from '../../../utils/noop';

const SecondaryButton = ({ children, onClick, ...props }) => (
    <Button
        variant="outlined"
        color="primary"
        disableElevation
        disableFocusRipple
        disableRipple
        onClick={onClick}
        {...props}
    >
        {children}
    </Button>
);

SecondaryButton.defaultProps = {
    children: null,
    onClick: noop,
};

SecondaryButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
};

export default SecondaryButton;
