import Colors from '../../../../styles/colors';

const styles = (theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        borderRadius: 10,
        width: '100%',

        '&:hover': {
            border: 0,
            backgroundColor: Colors.darkGrey,

            '& .header, .body': {
                border: 0,
                backgroundColor: Colors.darkGrey,
            },
            '& .headerText, .headerIcon': {
                color: Colors.darkGrey,
            },
            '& .bodyText': {
                color: Colors.white,
            },
            '& .body svg g': {
                fill: Colors.white,
            },
        },
    },
    progressNotStarted: {
        border: `1px solid ${Colors.lavenderGray}`,
    },
    progressInProgress: {
        border: `1px solid ${Colors.lavenderGray}`,
    },
    progressCompleted: {
        border: `1px solid ${Colors.ultramarineBlue}`,

        '& $header': {
            backgroundColor: Colors.ultramarineBlue,
        },
    },
    questionsFlagged: {
        border: `1px solid ${Colors.info} !important`,

        '& .MuiCardHeader-root': {
            backgroundColor: Colors.info,
        },
    },
    actionButton: {
        height: 160,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'stretch',

        [theme.breakpoints.down('sm')]: {
            height: 148,
        },
    },
    header: {
        backgroundColor: Colors.antiflashWhite,
        height: 20,
        padding: 0,
        width: '100%',
    },
    headerText: {
        fontSize: '0.8125rem',
        fontWeight: 600,
        textAlign: 'center',
    },
    headerIcon: {
        height: '0.8em',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.white,
        width: '100%',
        height: '100%',

        '& svg g': {
            fill: Colors.darkGrey,
        },
    },
    bodyText: {
        fontWeight: 700,
        textAlign: 'center',
    },
    bodyIcon: {
        width: 60,
        height: 60,

        '& svg': {},
    },
});
export default styles;
