/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../auth';

const initialState = {
    id: null,
};

const currentReportSlice = createSlice({
    name: 'currentReport',
    initialState,
    reducers: {
        setCurrentReportId: (state, action) => {
            state.id = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout.fulfilled, (state) => {
            /* eslint-disable-next-line no-unused-vars */
            state = initialState;
        });
    },
});

const { reducer, actions } = currentReportSlice;

export const { setCurrentReportId } = actions;

export default reducer;
