import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';

import getFormattedDate from '../../../../utils/get-formatted-date';
import { REPORT_STATUS } from '../../../../redux/reports';

const SummaryHeader = ({ isTester, report, classes }) => {
    const { testDate, testSite } = report;
    const { siteName } = testSite;

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h1" style={{ display: 'inline-block' }}>
                    Report: {siteName}
                </Typography>
                <Typography variant="body1" style={{ display: 'inline-block', marginLeft: 10 }}>
                    Visited {getFormattedDate(testDate)}
                </Typography>
            </Grid>
            {/* DESKTOP */}
            <Grid item sm={12}>
                <ul>
                    {isTester ? (
                        <>
                            <Typography variant="body1" component="li">
                                You can start with any section. Once all sections are complete, you can sign and submit
                                the form.
                            </Typography>
                            <Typography variant="body1" component="li">
                                You can save and exit a section at any time. The section is complete once all the
                                required questions are answered.
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="body1" component="li">
                            Select the appropriate section for your question re-answer request.
                        </Typography>
                    )}
                </ul>
            </Grid>
            {/* MOBILE */}
            <Grid item sm={12} display={{ xs: 'block', md: 'none' }}>
                <Accordion elevation={0} square>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="instructions-content"
                        id="instructions-header"
                        classes={{
                            root: classes.mobileInstructionsWrapper,
                            content: classes.mobileInstructionsSummaryContent,
                            expanded: classes.mobileInstructionsWrapperExpanded,
                            expandIcon: classes.mobileInstructionsExpandButton,
                        }}
                    >
                        <Typography className={classes.mobileInstructionsSummaryText}>View Instructions</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.mobileInstructionsContent}>
                        <ul>
                            <Typography variant="body1" component="li">
                                You can start with any section. Once all 6 sections are complete, you can sign and
                                submit the form.
                            </Typography>
                            <Typography variant="body1" component="li">
                                You can save and exit a section at any time. The section is complete once all the
                                required questions are answered.
                            </Typography>
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </>
    );
};

SummaryHeader.propTypes = {
    isTester: PropTypes.bool,
    report: PropTypes.shape({
        testSite: PropTypes.shape({
            siteName: PropTypes.string.isRequired,
        }).isRequired,
        testDate: PropTypes.string.isRequired,
        status: PropTypes.oneOf(Object.values(REPORT_STATUS)).isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired,
};

SummaryHeader.defaultProps = {
    isTester: false,
};

export default SummaryHeader;
