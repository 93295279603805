import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { useThemeState } from './theme-context';

const ThemeWrapper = ({ children, theme }) => {
    const themeOverride = useThemeState();

    // override the theme with any changes made by the user with the DisplayOptionsControlPanel
    const updatedTheme = {
        ...theme,
        typography: {
            ...theme.typography,
            fontSize: themeOverride.fontSize,
            htmlFontSize: themeOverride.fontSize,
        },
    };

    if (themeOverride.background) {
        updatedTheme.palette = {
            ...updatedTheme.palette,
            background: {
                ...theme.palette.background,
                default: themeOverride.background,
            },
        };
    }

    if (themeOverride.palette.primary.main) {
        updatedTheme.palette = {
            ...updatedTheme.palette,
            ...themeOverride.palette,
        };
        updatedTheme.components = {
            ...updatedTheme.components,
            MuiRadio: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: themeOverride.palette.primary.main,
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: themeOverride.palette.primary.main,
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.primary,
                        lineHeight: 1.1875,
                        fontSize: '1rem',
                        '&[aria-selected=true]': {
                            color: theme.palette.primary.contrastText,
                            backgroundColor: `rgba(${themeOverride.palette.primary.mainRGB}, .2) !important`,
                        },
                        '&[role=option]': {
                            // select menu options
                            paddingTop: 12,
                            paddingBottom: 12,
                            paddingLeft: 22,
                            paddingRight: 22,
                        },
                        '&:hover, &:focus': {
                            backgroundColor: `rgba(${themeOverride.palette.primary.mainRGB}, .1)`,
                        },
                    },
                },
            },
        };
    }

    return <ThemeProvider theme={createTheme(updatedTheme)}>{children}</ThemeProvider>;
};

ThemeWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.object.isRequired,
};

export default ThemeWrapper;
