import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { withStyles } from 'tss-react/mui';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import Grid from '@mui/material/Grid';

import TablePaginationActions from '../../../components/table-pagination-actions';
import { fetchOrganizations } from '../../../redux/organizations';
import { getTotalOrganizations, getOrgizationsList } from '../../../redux/organizations/selectors';

import OrgActionMenu from './org-action-menu';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import InputStyles from '../../../styles/inputs';
import Styles from '../styles';
import DeleteOrgModal from './delete-org-modal';
import { clearUserSuccessMessage } from '../../../redux/user';
import NotificationSnackbar from '../../../components/notification-snackbar';
import { getUserSuccessMessage } from '../../../redux/user/selectors';
import ColorSchemes from '../../../wiring/color-schemes';

const ManageOrganizations = ({ classes }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [orgToDelete, setOrgToDelete] = useState({});
    const orgSuccessMessage = useSelector(getUserSuccessMessage);
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [fetchPayload, setFetchPaylod] = useState({
        page,
        pageSize: rowsPerPage,
        sortBy,
        sortOrder,
    });
    const organizationsTotal = useSelector(getTotalOrganizations);
    const organizations = useSelector(getOrgizationsList);

    // this is to support legacy code that stores reports in the redux store as a map instead of a list. eventually we should change the redux store to use a list instead
    const sortedOrganizations = useMemo(() => {
        let groomedValues = [...organizations];

        // remove null values from the groomedValues array
        groomedValues = groomedValues.filter((org) => org !== null);

        if (sortBy === 'name') {
            return orderBy(groomedValues, ['name'], [sortOrder]);
        }
        if (sortBy === 'adminCount') {
            return orderBy(groomedValues, ['adminCount'], [sortOrder]);
        }
        if (sortBy === 'colorScheme') {
            return orderBy(groomedValues, ['colorScheme'], [sortOrder]);
        }
        return orderBy(groomedValues, [sortBy], [sortOrder]);
    }, [organizations, sortBy, sortOrder]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const payload = {
            page,
            sortBy,
            sortOrder,
        };

        if (rowsPerPage > 1) {
            payload.pageSize = rowsPerPage;
        }

        // we sort by age in the UI, but it's DOB in the database so we need to flip the sort order
        if (sortBy === 'dob') {
            payload.sortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        } else if (sortBy === 'disability') {
            payload.sortBy = 'hasDisability';
        }

        if (!isEqual(payload, fetchPayload)) {
            setFetchPaylod(payload);
        }
    }, [page, rowsPerPage, sortBy, sortOrder, fetchPayload]);

    useEffect(() => {
        dispatch(fetchOrganizations(fetchPayload));
    }, [fetchPayload, dispatch]);

    const handleChangePage = useCallback(
        (event, newPage) => {
            setPage(newPage);
        },
        [setPage],
    );

    const handleCreateOrg = useCallback(() => {
        navigate('/dashboard/organizations/new');
    }, [navigate]);

    const handleDelete = (org) => {
        setOpenDeleteModal(true);
        setOrgToDelete(org);
    };

    const handleChangeRowsPerPage = useCallback(
        (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        },
        [setRowsPerPage, setPage],
    );

    const handleSort = (sortProperty) => () => {
        setSortBy(sortProperty);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            <NotificationSnackbar
                open={!!orgSuccessMessage}
                text={orgSuccessMessage}
                onClose={() => dispatch(clearUserSuccessMessage())}
            />
            <DeleteOrgModal
                org={orgToDelete}
                onClose={() => setOpenDeleteModal(false)}
                open={openDeleteModal}
            />
            <Grid item container xs={12}>
                <Grid item xs={6}>
                    <Typography id="testers-title" variant="h1" component="h2" className={classes.pageTitle}>
                        Manage Organizations
                    </Typography>
                    <Typography id="testers-count" variant="body1" className={classes.pageCount}>
                        {organizationsTotal} organizations
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            className={classes.primaryButton}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            style={{ minWidth: 'max-content' }}
                            onClick={handleCreateOrg}
                        >
                            Add New Organization
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Grid item container xs={12}>
                <Paper className={classes.tableWrapper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-labelledby="testers-title testers-count">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell
                                        sortDirection={sortBy === 'name' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'name'}
                                            direction={sortBy === 'name' ? sortOrder : 'asc'}
                                            onClick={handleSort('name')}
                                        >
                                            Name
                                            {sortBy === 'name' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'adminCount' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'adminCount'}
                                            direction={sortBy === 'adminCount' ? sortOrder : 'asc'}
                                            onClick={handleSort('adminCount')}
                                        >
                                            Admins
                                            {sortBy === 'adminCount' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'colorScheme' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'colorScheme'}
                                            direction={sortBy === 'colorScheme' ? sortOrder : 'asc'}
                                            onClick={handleSort('colorScheme')}
                                        >
                                            Color Scheme
                                            {sortBy === 'colorScheme' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {sortedOrganizations.map((org) => {
                                    const selectedColorScheme = ColorSchemes.find((colorScheme) => colorScheme.key === org.colorScheme);
                                    return (
                                        <TableRow key={org.id}>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body1" className={classes.testerName}>
                                                    {org.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body1">{org.adminCount}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div
                                                    style={{
                                                        height: 24,
                                                        width: 24,
                                                        borderRadius: 12,
                                                        backgroundColor: selectedColorScheme?.primary.main || 'white',
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div>
                                                    <OrgActionMenu
                                                        org={org}
                                                        onDelete={handleDelete}
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {organizationsTotal > 10 && (
                        <TablePagination
                            className={classes.tableFooter}
                            component="div"
                            count={organizationsTotal}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            classes={{
                                root: classes.paginationContainer,
                                toolbar: classes.paginationToolbar,
                                spacer: classes.paginationSpacer,
                            }}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

ManageOrganizations.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(ManageOrganizations, combinedStyles);
