import { createSelector } from '@reduxjs/toolkit';

const getSitesState = (state) => state.sites;

// eslint-disable-next-line import/prefer-default-export
export const getSites = createSelector(getSitesState, (sites) => sites.data || []);

export const getSitesTotal = createSelector(getSitesState, (sites) => sites.total || 0);

export const getActiveSites = createSelector(getSites, (sites) => sites.filter((site) => !site?.deletedAt));

export const getSiteSelector = createSelector(
    getActiveSites,
    (sites) => (siteId) => sites.find((site) => `${siteId}` === `${site.id}`),
);
