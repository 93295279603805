import SvgIcon from '@mui/material/SvgIcon';

const SubmitIcon = (props) => (
    <SvgIcon {...props}>
        <mask
            id="mask0_839_14783"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_839_14783)">
            <path
                d="M11 21.9498C9.73333 21.8165 8.55417 21.4706 7.4625 20.9123C6.37083 20.354 5.42083 19.629 4.6125 18.7373C3.80417 17.8456 3.16667 16.8248 2.7 15.6748C2.23333 14.5248 2 13.2998 2 11.9998C2 10.4831 2.30417 9.08314 2.9125 7.7998C3.52083 6.51647 4.35 5.41647 5.4 4.4998H3V2.4998H9V8.4998H7V5.7748C6.08333 6.50814 5.35417 7.4123 4.8125 8.4873C4.27083 9.5623 4 10.7331 4 11.9998C4 14.0498 4.67083 15.8206 6.0125 17.3123C7.35417 18.804 9.01667 19.6748 11 19.9248V21.9498ZM10.575 16.5998L6.35 12.3498L7.75 10.9498L10.575 13.7748L16.25 8.0998L17.65 9.5248L10.575 16.5998ZM15 21.4998V15.4998H17V18.2248C17.9167 17.4748 18.6458 16.5665 19.1875 15.4998C19.7292 14.4331 20 13.2665 20 11.9998C20 9.9498 19.3292 8.17897 17.9875 6.6873C16.6458 5.19564 14.9833 4.3248 13 4.0748V2.0498C15.5333 2.2998 17.6667 3.36647 19.4 5.2498C21.1333 7.13314 22 9.38314 22 11.9998C22 13.5165 21.6958 14.9165 21.0875 16.1998C20.4792 17.4831 19.65 18.5831 18.6 19.4998H21V21.4998H15Z"
                fill="#1C1B1F"
            />
        </g>
    </SvgIcon>
);

export default SubmitIcon;
