import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';

import TextField from '../../../../../components/inputs/textinput';
import ChipArray from '../chip-array';

// a component that renders the input fields for a supporting question
const SupportingQuestionInputs = ({ fieldName, question, register, control, errors, setValue }) => {
    if (question.type === 'radioButton' || question.type === 'checkBoxes') {
        return (
            <>
                <Grid item xs={12}>
                    <ChipArray
                        errors={errors}
                        name={`${fieldName}.options`}
                        label="Answer Options"
                        control={control}
                        setValue={setValue}
                        value={question.options || []}
                        rules={{ minLength: 1 }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name={`${fieldName}.required`}
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                {...field}
                                label="Make question required"
                                checked={field.value === true}
                                control={<Checkbox color="primary" />}
                            />
                        )}
                    />
                </Grid>
            </>
        );
    }
    if (question.type === 'repeatingGroup') {
        return (
            <>
                <Grid item xs={6}>
                    <TextField
                        errors={errors}
                        name={`${fieldName}.groupLabel`}
                        label="Group Label"
                        required
                        register={register}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name={`${fieldName}.required`}
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                {...field}
                                label="Make question required"
                                checked={field.value === true}
                                control={<Checkbox color="primary" />}
                            />
                        )}
                    />
                </Grid>
            </>
        );
    }
    if (question.type === 'instructions') {
        return (
            <Grid item xs={12}>
                <TextField
                    errors={errors}
                    name={`${fieldName}.instructions`}
                    label="Instructions"
                    required
                    register={register}
                    multiline
                    minRows={4}
                />
            </Grid>
        );
    }
    if (question.type === 'file') {
        return null;
    }
    return (
        <Grid item xs={12}>
            <Controller
                name={`${fieldName}.required`}
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        {...field}
                        label="Make question required"
                        checked={field.value === true}
                        control={<Checkbox color="primary" />}
                    />
                )}
            />
        </Grid>
    );
};

SupportingQuestionInputs.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
};

export default SupportingQuestionInputs;
