import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { getSections, getCurrentReportProgress } from '../../redux/current-report/selectors';
import { SECTION_PROGRESS } from '../../redux/current-report/constants';
import { submitReport, submitReopenRequest, createReportVersionSuccess, patchReportSuccess, patchReportError, patchReportVersionSuccess, REQUEST_STATUS, REPORT_STATUS } from '../../redux/reports';

import ReportBlockButton from './components/report-block';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';
import ReportError from '../../layouts/report/components/report-error';
import SummaryHeader from './components/summary-header';
import { getRole } from '../../redux/auth/selectors';
import { APIError } from '../../redux/app';
import { USER_ROLES } from '../../utils/constants';

const ReportOverview = ({ classes, report, currentVersion }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sections = useSelector(getSections);
    const currentReportProgress = useSelector(getCurrentReportProgress);
    const role = useSelector(getRole);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { id: reportId } = report;

    const isReportComplete = currentReportProgress === SECTION_PROGRESS.COMPLETED;

    const onComplete = () => {
        setIsSubmitting(false);
        navigate('/dashboard/reports');
    };

    const handleTesterSubmit = () => {
        setIsSubmitting(true);

        const onError = (error) => {
            // eslint-disable-next-line no-console
            console.warn('error', error);
            // TODO: why are we pretending things are fine?
            if (!error.response || error.response.status !== 401) {
                onComplete();
            }
            dispatch(APIError({ text: 'Error Submitting Report.' }));
            setIsSubmitting(false);
        };
        dispatch(submitReport({ reportId, onSuccess: onComplete, onError }));
    };

    const handleReopenSubmit = () => {
        const payload = {
            comment: currentVersion.comment,
            flaggedAnswers: currentVersion.flaggedAnswers,
            currentVersionId: currentVersion.id,
        };

        const onSuccess = (newVersion) => {
            onComplete();
            dispatch(patchReportVersionSuccess(
                reportId,
                currentVersion.id,
                currentVersion,
            ));
            dispatch(createReportVersionSuccess({ reportId, newVersion }));
            dispatch(patchReportSuccess(reportId, {
                status: REPORT_STATUS.REOPENED,
            }));
        };
        const onError = (error) => {
            if (error?.response?.status === 401) {
                dispatch(patchReportError(reportId, { message: 'Unable to save your changes. Your session may have expired, if the problem persists please log out and back in again.' }, REQUEST_STATUS.SESSION_EXPIRED));
            } else {
                dispatch(patchReportError(reportId, { message: 'Unable to save your changes.' }, REQUEST_STATUS.ERROR));
            }
            onComplete();
        };

        dispatch(submitReopenRequest({ reportId, payload, onComplete, onSuccess, onError }));
    };

    const handleTesterCommentChange = (event) => {
        const { value } = event.target;
        dispatch(patchReportVersionSuccess(reportId, currentVersion.id, { comment: value }));
    };

    const [defaultComment, setDefaultComment] = useState('');
    useEffect(() => {
        // get the comment value from that last instances in the report.versions array
        const lastVersion = report.versions[report.versions.length - 1];
        if (lastVersion) {
            setDefaultComment(lastVersion.comment);
        }
    }, [report.versions]);

    return (
        <Grid item container className={classes.container} spacing={2}>
            <Grid item container xs={12}>
                <SummaryHeader report={report} classes={classes} isTester={role === USER_ROLES.tester.value} />
            </Grid>
            <Grid item container xs={12} spacing={2}>
                {sections.map((section) => (
                    <Grid item key={section.id} xs={4}>
                        <ReportBlockButton section={section} />
                    </Grid>
                ))}
            </Grid>
            {role !== USER_ROLES.tester.value && (
                <Grid item xs={12}>
                    <FormLabel className={classes.inputLabel} style={{ display: 'block' }}>
                        Message to Tester (optional)
                    </FormLabel>
                    <TextareaAutosize
                        defaultValue={defaultComment}
                        className={classes.testerComment}
                        minRows={5}
                        maxRows={8}
                        onChange={handleTesterCommentChange}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                {role === USER_ROLES.tester.value ? (
                    <Button
                        className={`${classes.primaryButton} ${classes.fullWidth} ${!isReportComplete && classes.primaryButtonDisabled}`}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        disabled={!isReportComplete || isSubmitting}
                        onClick={handleTesterSubmit}
                    >
                        {isSubmitting ? <CircularProgress size={24} /> : 'Submit Report'}
                    </Button>
                ) : (
                    <Button
                        className={`${classes.primaryButton} ${classes.fullWidth}`}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        onClick={handleReopenSubmit}
                    >
                        {isSubmitting ? <CircularProgress size={24} /> : 'Submit Re-Answer Request'}
                    </Button>
                )}
            </Grid>
            <Grid item xs={12}>
                <ReportError currentReport={report} />
            </Grid>
        </Grid>
    );
};

ReportOverview.propTypes = {
    classes: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
    currentVersion: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(ReportOverview, combinedStyles);
