/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import Axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIError } from '../app';

const GOOGLE_API = 'https://www.googleapis.com/youtube/v3';
const LSEM_YOUTUBE_CHANNEL_ID = 'UCdtOCup-utY_N164TIfRj8Q';

const initialState = {
    currentRequestId: null,
    data: [],
    loading: 'idle',
    error: null,
};

const fetchVideos = createAsyncThunk('videos/fetchVideos', async (_, thunkApi) => {
    try {
        // first fetch details about the LSEM YouTube channel
        // so we can get the playlistId of the 'uploads' playlist
        const channelDataURL = `${GOOGLE_API}/channels?part=contentDetails&id=${LSEM_YOUTUBE_CHANNEL_ID}&key=${import.meta.env.VITE_YOUTUBE_API_KEY}`;
        const channelData = (await Axios.get(channelDataURL)).data;
        const uploadsPlaylistId = channelData.items[0].contentDetails.relatedPlaylists.uploads;

        // then fetch details about all of the videos in the 'uploads' playlist
        const playlistDataURL = `${GOOGLE_API}/playlistItems?part=snippet%2CcontentDetails&playlistId=${uploadsPlaylistId}&key=${import.meta.env.VITE_YOUTUBE_API_KEY}`;

        const playlistData = (await Axios.get(playlistDataURL)).data;

        let videos = playlistData.items;

        const totalPages = playlistData.pageInfo.totalResults / playlistData.pageInfo.resultsPerPage;

        // if there is more than one page of results continue to fetch the remaining pages of data
        if (totalPages > 1) {
            let { nextPageToken } = playlistData;
            while (nextPageToken) {
                const nextPagePlaylistDataURL = `${playlistDataURL}&pageToken=${nextPageToken}`;
                // eslint-disable-next-line no-await-in-loop
                const nextPagePlaylistData = (await Axios.get(nextPagePlaylistDataURL)).data;

                videos = videos.concat(nextPagePlaylistData.items);

                // eslint-disable-next-line prefer-destructuring
                nextPageToken = nextPagePlaylistDataURL.nextPageToken;
            }
        }

        return videos;
    } catch (error) {
        thunkApi.dispatch(APIError({ text: 'Error fetching videos.' }));
    }
});

const videosSlice = createSlice({
    name: 'videos',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchVideos.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending';
                    state.currentRequestId = action.meta.requestId;
                }
            })
            .addCase(fetchVideos.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.data = action.payload;
                    state.currentRequestId = undefined;
                }
            })
            .addCase(fetchVideos.rejected, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.error = action.error;
                    state.currentRequestId = undefined;
                }
            });
    },
});

export { fetchVideos };

const { reducer } = videosSlice;

export default reducer;
