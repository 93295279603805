import * as Sentry from '@sentry/react';

// List nabbed from https://github.com/hapijs/bounce
const systemErrors = [EvalError, RangeError, ReferenceError, SyntaxError, TypeError, URIError];

// eslint-disable-next-line padded-blocks, arrow-body-style
const isSystem = (err) => {
    return systemErrors.some(
        (system) =>
            err instanceof system || // sync errors
            err.name === system.name,
    ); // thunk errors (serialized)
};

const isThunkCrash = (action) => {
    const { error, meta } = action;

    // TODO meta.condition might not be necessary, since actions cancelled before execution don't appear to even be dispatched (no action even comes thru middleware here)
    return !!error && !meta.condition && !meta.aborted && isSystem(error);
};

const deserializeError = (actionError) => {
    const { name, message, stack } = actionError;

    const constructor = systemErrors.find((system) => system.name === name) || Error;

    const error = new constructor(message);
    if (stack) {
        error.stack = stack;
    }

    return error;
};

// Copied from https://redux.js.org/understanding/history-and-design/middleware
// eslint-disable-next-line no-unused-vars
const crashReporter = (store) => (next) => (action) => {
    // TODO Since Sentry catches sync action errors by default, consider
    // reporting only system errors, ignoring others. Seems safe to assume
    // that sync actions throwing will almost certainly be system errors,
    // unless someone's explicitly throwing a standard Error or doing something odd.

    const result = next(action);

    if (isThunkCrash(action)) {
        const err = deserializeError(action.error);
        Sentry.captureException(err);
        // Typically not using Sentry locally, so fire off to the console instead
        // As long as our thunk errors are reported loudly and not stashed away in redux
        if (import.meta.env.DEV) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }

    return result;
};

export default crashReporter;
