import WebClient from '../utils/web-client';

const sleep = (ms) =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

const request = async (netFn, opts = { log: false, latency: false, cancelToken: null }) => {
    try {
        if (opts.latency) {
            await sleep(Number.isNaN(opts.latency) ? 2000 : opts.latency);
        }
        const result = await netFn({ cancelToken: opts.cancelToken });

        if (opts.log) {
            // eslint-disable-next-line no-console
            console.log(result);
        }

        return result;
    } catch (err) {
        if (opts.log) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
        throw err;
    }
};

const API = {
    downloadReport: async (reportId) => {
        const { data } = await request(({ cancelToken }) => WebClient.get(`/reports/${reportId}/report`, { cancelToken }));

        return data.url;
    },
    checkToken: async (opts) => {
        await request(({ cancelToken }) => WebClient.get('/users/authenticated', { cancelToken }), opts);
    },
    fetchCurrentUser: async (opts) => {
        const { data } = await request(({ cancelToken }) => WebClient.get('/user', { cancelToken }), opts);

        return data;
    },
    login: async ({ email, password }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.post('/login', { email, password }, { cancelToken }),
            opts,
        );

        return data;
    },
    requestPasswordReset: async ({ email }, opts) => {
        await request(({ cancelToken }) => WebClient.post('/users/request-reset', { email }, { cancelToken }), opts);
    },
    resetPassword: async ({ email, newPassword, resetToken }, opts) => {
        await request(
            ({ cancelToken }) =>
                WebClient.post('/users/reset-password', { email, newPassword, resetToken }, { cancelToken }),
            opts,
        );
    },
    logout: async (opts) => {
        await request(({ cancelToken }) => WebClient.post('/logout', null, { cancelToken }), opts);
    },
    fetchInternalUsers: async (payload) => {
        const { data } = await request(({ cancelToken }) =>
            WebClient.post('/users/internal/list', payload, { cancelToken }),
        );

        return data;
    },
    updateInternalUser: async (id, payload) => {
        const { data } = await request(({ cancelToken }) => WebClient.patch(`/users/${id}`, payload, { cancelToken }));

        return data;
    },
    createInternalUser: async (payload) => {
        const { data } = await request(({ cancelToken }) => WebClient.post('/users', payload, { cancelToken }));

        return data;
    },
    inviteUser: async (id) => {
        const { data } = await request(({ cancelToken }) =>
            WebClient.post(`/users/${id}/resend-invite`, { id }, { cancelToken }),
        );

        return data;
    },
    fetchTesters: async () => {
        const { data } = await request(({ cancelToken }) => WebClient.get('/testers', {}, { cancelToken }));

        return data;
    },
    fetchTestersList: async (payload) => {
        const { data } = await request(({ cancelToken }) => WebClient.post('/testers/list', payload, { cancelToken }));

        return data;
    },
    createTester: async (payload) => {
        const { data } = await request(({ cancelToken }) => WebClient.post('/users/testers', payload, { cancelToken }));

        return data;
    },
    deleteUser: async (id) => {
        await request(({ cancelToken }) => WebClient.delete(`/users/${id}`, { cancelToken }));
    },
    fetchSites: async () => {
        const { data } = await request(({ cancelToken }) => WebClient.get('/testSites', {}, { cancelToken }));

        return data;
    },
    fetchSitesList: async (payload) => {
        const { data } = await request(({ cancelToken }) =>
            WebClient.post('/testSites/list', payload, { cancelToken }),
        );

        return data;
    },
    updateSite: async (id, payload) => {
        const { data } = await request(({ cancelToken }) =>
            WebClient.patch(`/testSites/${id}`, payload, { cancelToken }),
        );

        return data;
    },
    createSite: async (payload) => {
        const { data } = await request(({ cancelToken }) => WebClient.post('/testSites', payload, { cancelToken }));

        return data;
    },
    deleteSite: async (id) => {
        await request(({ cancelToken }) => WebClient.delete(`/testSites/${id}`, { cancelToken }));
    },
    fetchReports: async (payload) => {
        const { data } = await request(({ cancelToken }) => WebClient.post('/reports/list', payload, { cancelToken }));

        return data;
    },
    fetchReport: async (id) => {
        const { data } = await request(({ cancelToken }) =>
            WebClient.get(`/reports/${id}/report`, {}, { cancelToken }),
        );

        return data;
    },
    deleteReport: async (id) => {
        await request(({ cancelToken }) => WebClient.delete(`/reports/${id}`, { cancelToken }));
    },
    uploadReportOutcome: async (reportId, formData, config) => {
        const { data } = await request(({ cancelToken }) =>
            WebClient.patch(`/reports/${reportId}/outcome`, formData, config, { cancelToken }),
        );

        return data;
    },
    createReport: async (payload) => {
        const { data } = await request(({ cancelToken }) => WebClient.post('/reports', payload, { cancelToken }));

        return data;
    },

    submitReport: async (reportId, reportData) => {
        await WebClient.post(`/reports/${reportId}`, { answers: reportData });
    },
    patchReportVersion: async (versionId, comment, flaggedAnswers) => {
        await WebClient.patch(`/reportVersions/${versionId}`, {
            comment,
            flaggedAnswers,
        });
    },
    getCurrentTest: async () => {
        const { data } = await request(({ cancelToken }) => WebClient.get('/test', {}, { cancelToken }));

        return data;
    },
    submitReopenRequest: async (reportId, payload) => {
        const newVersion = await request(({ cancelToken }) => WebClient.post(`/reports/${reportId}/versions`, payload, { cancelToken }));
        return newVersion;
    },
    submitReportAnswers: async (id, payload) => {
        await request(({ cancelToken }) => WebClient.patch(`/reports/${id}/answers`, payload, { cancelToken }));
    },
    uploadReportAttachments: async (id, formData, config) => {
        await request(({ cancelToken }) =>
            WebClient.post(`/reports/${id}/attachments`, formData, config, { cancelToken }),
        );
    },
    deleteReportAttachments: async (id, payload) => {
        await request(({ cancelToken }) => WebClient.delete(`/reports/${id}/attachments`, payload, { cancelToken }));
    },
    // manage organizations
    // this paginated endpoint is used for the table
    // it looks different than the other paginated endpoints
    // because new developers were not familiar with the other pattern
    fetchOrganizations: async (payload) => {
        const { data } = await request(({ cancelToken }) => WebClient.post(`/organizations/${payload.page}/${payload.pageSize}`, { sortBy: payload.sortBy, sortOrder: payload.sortOrder }, { cancelToken }));

        return data;
    },
    createOrganization: async (formData, config) => {
        const { data } = await request(({ cancelToken }) => WebClient.post('/organizations', formData, config, { cancelToken }));

        return data;
    },
    updateOrganization: async (id, formData, config) => {
        const { data } = await request(({ cancelToken }) =>
            WebClient.patch(`/organizations/${id}`, formData, config, { cancelToken }),
        );

        return data;
    },
    deleteOrganization: async (id) => {
        await request(({ cancelToken }) => WebClient.delete(`/organizations/${id}`, { cancelToken }));
    },
};

export default API;

// the return value from this isn't passed through the reducer
// it's called directly from the component, so it doesn't need to be a thunk
export const fetchSingleOrganization = (id) => async () => {
        const { data } = await request(({ cancelToken }) => WebClient.get(`/organizations/${id}`, { cancelToken }));
        return data;
};
