import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PrimaryButton from '../../../../../components/buttons/primary-button';
import SecondaryButton from '../../../../../components/buttons/secondary-button';
import ModalWrapper from '../../../../../components/modal-parts/modal-wrapper';
import ModalContentWrapper from '../../../../../components/modal-parts/modal-content-wrapper';
import Colors from '../../../../../styles/colors';
import IconList from '../../../../../wiring/icon-map';
import ButtonStyles from '../../../../../styles/buttons';

const StyledButtonWrapper = styled('button')({
    borderRadius: 10,
    height: 130,
    width: 130,
    border: `2px solid ${Colors.greyLight}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
        border: `2px solid ${Colors.persianBlue}`,
    },
    '&.selected': {
        border: `2px solid ${Colors.persianBlue}`,
    },
});

const SectionIconModal = ({ open, handleClose, fieldName, setValue, defaultValue, classes }) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const handleSetIcon = () => {
        setValue(fieldName, selectedValue, { shouldDirty: true, shouldValidate: true });
        handleClose();
    };
    const handleClick = (icon) => {
        setSelectedValue(icon);
    };

    useEffect(() => {
        setSelectedValue(defaultValue);
    }, [defaultValue]);

    return (
        <ModalWrapper
            open={open}
            onClose={handleClose}
            aria-label="Select Section Icon"
            style={{ maxWidth: '80vw', margin: '0 auto' }}
        >
            <ModalContentWrapper>
                <Grid item container pb={2} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h2">
                            Select Section Icon
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container pb={10} pt={3} spacing={3}>
                    {IconList.map((icon) => (
                        <Grid item xs={2} key={icon.key}>
                            <StyledButtonWrapper
                                onClick={() => handleClick(icon.key)}
                                className={selectedValue === icon.key ? 'selected' : ''}
                            >
                                <img src={icon.value} alt="" />
                            </StyledButtonWrapper>
                        </Grid>
                    ))}
                </Grid>

                <Grid item container pt={2}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <SecondaryButton className={classes.secondaryButton} onClick={handleClose} sx={{ mr: 2 }}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={handleSetIcon}
                            disabled={selectedValue === null}
                            className={classes.primaryButton}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                        >
                            Save
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </ModalContentWrapper>
        </ModalWrapper>
    );
};

SectionIconModal.defaultProps = {
    fieldName: '',
    defaultValue: null,
};

SectionIconModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    fieldName: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

export default withStyles(SectionIconModal, ButtonStyles);
