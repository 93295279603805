import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import { deleteReport } from '../../../../redux/reports';

import HeaderIcon from './images/delete_report.png';
import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import DialogStyles from '../../../../styles/dialog';

import Styles from './styles';

const DeleteReportDialog = ({ classes, isDialogOpen, handleCloseDialog, reportDetails }) => {
    const { id, siteName, tester, testDate } = reportDetails;
    const dispatch = useDispatch();

    const handleDelete = useCallback(() => {
        dispatch(deleteReport({ id, onSuccess: handleCloseDialog }));
    }, [id, dispatch, handleCloseDialog]);

    return (
        <Dialog
            classes={{
                paper: classes.dialogContainer,
            }}
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="delete-dialog-title"
            fullWidth
        >
            <DialogTitle id="delete-dialog-title" className={classes.dialogTitle}>
                <Grid item container spacing={2} alignItems="center" direction="column" justifyContent="center">
                    <Grid item sm={12} container spacing={2} justifyContent="flex-end">
                        <IconButton aria-label="cancel" onClick={handleCloseDialog}>
                            <CloseIcon
                                classes={{
                                    root: classes.dialogCloseButton,
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item container sm={12} justifyContent="center">
                        <img src={HeaderIcon} alt="" style={{ width: 55 }} />
                    </Grid>
                    <Grid item container sm={12} justifyContent="center">
                        <Typography variant="h1" component="h2">
                            Are you sure you want to delete this report?
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <DialogContentText className={classes.dialogContentText}>
                    Warning: this cannot be undone.
                </DialogContentText>

                <Grid item container className={classes.detailsWrapper} spacing={2}>
                    <Grid item sm={4}>
                        <Typography variant="body1" className={classes.detailTitle}>
                            Site
                        </Typography>
                        <Typography variant="body1" className={classes.detailContent}>
                            {siteName}
                        </Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Typography variant="body1" className={classes.detailTitle}>
                            Tester
                        </Typography>
                        <Typography variant="body1" className={classes.detailContent}>
                            {tester}
                        </Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Typography variant="body1" className={classes.detailTitle}>
                            Date of Visit/Phone Test
                        </Typography>
                        <Typography variant="body1" className={classes.detailContent}>
                            {testDate}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    className={classes.outlineButton}
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.outlineButtonRippleChildPulsate,
                            ripplePulsate: classes.buttonRipplePulsate,
                        },
                    }}
                    onClick={handleCloseDialog}
                >
                    Cancel, keep this report
                </Button>
                <Button
                    className={`${classes.deleteButton}`}
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.primaryButtonRippleChildPulsate,
                            ripplePulsate: classes.buttonRipplePulsate,
                        },
                    }}
                    onClick={handleDelete}
                >
                    Yes, delete this report
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteReportDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    reportDetails: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, DialogStyles, Styles);
export default withStyles(DeleteReportDialog, combinedStyles);
