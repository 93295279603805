import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { withStyles } from 'tss-react/mui';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import Styles from '../styles';
import { getSites, getSitesTotal } from '../../../redux/sites/selectors';
import { fetchSites, getSitesForReportsFilters } from '../../../redux/sites';
import TablePaginationActions from '../../../components/table-pagination-actions';
import Pill from '../components/pill';
import SiteActionMenu from '../components/site-action-menu';
import DeleteSiteModal from './delete-site-modal';
import SitesFilter from './site-filter';
import getFormattedDate from '../../../utils/get-formatted-date';
import { USER_ROLES } from '../../../utils/constants';
import { UserRolesPropType } from '../../../utils/prop-types';
import { getRole } from '../../../redux/auth/selectors';

const MANAGE_SITE_ROLES = [USER_ROLES.admin.value, USER_ROLES.testCoordinator.value, USER_ROLES.orgAdmin.value];

const ManageSites = ({ classes, role }) => {
    const userRole = useSelector(getRole);
    const dispatch = useDispatch();
    const sites = useSelector(getSites);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const [sitesFilterOptions, setSitesFilterOptions] = useState([]);
    const totalSites = useSelector(getSitesTotal);
    const [municipalityFilterValues, setMunicipalityFilterValues] = useState([]);
    const [sortBy, setSortBy] = useState('siteName');
    const [sortOrder, setSortOrder] = useState('asc');
    const canManageSites = MANAGE_SITE_ROLES.includes(role);
    const [fetchPayload, setFetchPayload] = useState({
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy,
        sortOrder,
        active: true,
    });

    // site deletion
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [siteToDelete, setSiteToDelete] = useState({});
    const openDeleteModal = (site) => {
        setSiteToDelete(site);
        setIsDeleteModalOpen(true);
    };

    useEffect(() => {
        const onSiteSuccess = (siteList) => {
            setSitesFilterOptions(siteList);
        };
        dispatch(getSitesForReportsFilters(onSiteSuccess));
    }, [dispatch]);

    useEffect(() => {
        const payload = {
            pageNumber: page,
            sortBy,
            sortOrder,
            active: true,
        };

        if (rowsPerPage > 0) {
            payload.pageSize = rowsPerPage;
        }

        if (municipalityFilterValues.length) {
            payload.municipalities = municipalityFilterValues;
        }

        if (!isEqual(payload, fetchPayload)) {
            setFetchPayload(payload);
        }
    }, [page, rowsPerPage, sortBy, sortOrder, municipalityFilterValues]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(fetchSites(fetchPayload));
    }, [fetchPayload, dispatch]);

    const handleAddNewSite = () => {
        navigate('/dashboard/sites/new');
    };

    // pagination functions
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterChange = (filterValues) => {
        setMunicipalityFilterValues(filterValues);
        setPage(0);
    };

    const handleSort = (sortProperty) => () => {
        setSortBy(sortProperty);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    const getMostRecentVisitDate = (reports) => {
        if (reports && reports.length) {
            const sortedReports = orderBy(reports, 'testDate', 'desc');
            return sortedReports[0].testDate;
        }

        return null;
    };

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            <Grid item xs={12}>
                <Typography id="sites-title" variant="h1" component="h2" className={classes.pageTitle}>
                    Manage Sites
                </Typography>
                <Typography id="sites-count" variant="body1" className={classes.pageCount}>
                    {totalSites} sites
                </Typography>
            </Grid>

            <Grid item container xs={12}>
                <Grid item xs={5}>
                    <SitesFilter sites={sitesFilterOptions} onChange={handleFilterChange} />
                </Grid>
                {canManageSites && (
                    <Grid item xs={7}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                className={classes.primaryButton}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                style={{ minWidth: 'max-content' }}
                                onClick={handleAddNewSite}
                            >
                                Add New Site
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>

            <Grid item container xs={12}>
                <Paper className={classes.tableWrapper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-labelledby="sites-title sites-count">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell
                                        sortDirection={sortBy === 'siteName' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'siteName'}
                                            direction={sortBy === 'siteName' ? sortOrder : 'asc'}
                                            onClick={handleSort('siteName')}
                                        >
                                            Name
                                            {sortBy === 'siteName' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'municipality' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'municipality'}
                                            direction={sortBy === 'municipality' ? sortOrder : 'asc'}
                                            onClick={handleSort('municipality')}
                                        >
                                            Municipality
                                            {sortBy === 'municipality' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'phone' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'phone'}
                                            direction={sortBy === 'phone' ? sortOrder : 'asc'}
                                            onClick={handleSort('phone')}
                                        >
                                            Phone
                                            {sortBy === 'phone' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'lastVisited' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'lastVisited'}
                                            direction={sortBy === 'lastVisited' ? sortOrder : 'asc'}
                                            onClick={handleSort('lastVisited')}
                                        >
                                            Last Visited
                                            {sortBy === 'lastVisited' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {sites.map((site) => (
                                    <TableRow key={site.id}>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1">
                                                <strong>{site.siteName}</strong>
                                            </Typography>
                                            <div>
                                                {site.address}, {site.city}, {site.state} {site.zipcode}
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1">{site.municipality}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1">{site.phone}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1">
                                                {getFormattedDate(getMostRecentVisitDate(site.reports))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {site.isInLitigation && <Pill type="alarm" text="In Litigation" />}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {canManageSites && (
                                                <div>
                                                    <SiteActionMenu
                                                        site={site}
                                                        onDeleteSite={openDeleteModal}
                                                        role={userRole}
                                                    />
                                                </div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalSites > 10 && (
                        <TablePagination
                            className={classes.tableFooter}
                            component="div"
                            count={totalSites}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            classes={{
                                root: classes.paginationContainer,
                                toolbar: classes.paginationToolbar,
                                spacer: classes.paginationSpacer,
                            }}
                        />
                    )}
                </Paper>
            </Grid>
            <DeleteSiteModal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} site={siteToDelete} />
        </Grid>
    );
};

ManageSites.propTypes = {
    classes: PropTypes.object.isRequired,
    role: UserRolesPropType.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(ManageSites, combinedStyles);
