import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';

import FormHelperText from '@mui/material/FormHelperText';
import Collapse from '@mui/material/Collapse';
// eslint-disable-next-line import/no-unresolved
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import { FormLabel } from '@mui/material';

const TimePickerComponent = ({
    name,
    label,
    value,
    placeholder,
    format,
    onChange,
    helperText,
    errors,
    showError,
    required,
    inputRef,
    inputProps,
    hiddenLabel,
    showRequired,
    disabled,
    disablePast,
    disableFuture,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, [setIsFocused]);

    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, [setIsFocused]);

    const isInvalid = !!errors[name];
    let describedBy = helperText ? `${name}-helper` : null;
    if (showError && isInvalid) {
        describedBy = describedBy ? `${describedBy} ${name}-error` : `${name}-error`;
    }

    const elementProps = {
        ...inputProps,
        'aria-labelledby': `${name}-label`,
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        onFocus: handleFocus,
        onBlur: handleBlur,
    };

    return (
        <FormControl component="fieldset" error={isInvalid} fullWidth required={required}>
            {!hiddenLabel && (
                <FormLabel
                    htmlFor={name}
                    required={showRequired && required}
                    disabled={disabled}
                    error={isInvalid}
                    style={{ fontWeight: 600 }}
                >
                    {label}
                </FormLabel>
            )}

            <TimePicker
                disabled={disabled}
                disablePast={disablePast}
                disableFuture={disableFuture}
                name={name}
                value={value}
                inputFormat={format}
                placeholder={placeholder}
                // onChange includes invalid changes
                // onAccept only fires when valid
                // onAccept={(date) => onChange(date, name)}
                onChange={(date) => onChange(date, name)}
                inputRef={inputRef}
                required={required}
                error={isInvalid}
                inputProps={elementProps}
                timeSteps={{ minutes: 1 }}
            />

            {helperText && (
                <Collapse in={isFocused}>
                    <FormHelperText id={`${name}-helper`}>{helperText}</FormHelperText>
                </Collapse>
            )}

            {showError && (
                <FormHelperText id={`${name}-error`} role="alert" aria-live="polite">
                    <ErrorMessage name={name} errors={errors} />
                </FormHelperText>
            )}
        </FormControl>
    );
};

TimePickerComponent.defaultProps = {
    value: null,
    format: 'hh:mm aa',
    placeholder: null,
    errors: {},
    showError: true,
    helperText: null,
    required: false,
    inputRef: null,
    inputProps: {},
    hiddenLabel: false,
    showRequired: false,
    disabled: false,
    disablePast: false,
    disableFuture: false,
};

TimePickerComponent.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    format: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    helperText: PropTypes.string,
    errors: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    inputProps: PropTypes.object,
    hiddenLabel: PropTypes.bool,
    showRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool,
};

const TimePickerWrapper = React.forwardRef(({ control, rules, inputRef, ...pickerProps }, ref) => {
    if (control) {
        return (
            <Controller
                control={control}
                rules={rules}
                name={pickerProps.name}
                render={({ field: { onChange, onBlur, value } }) => (
                    <TimePicker
                        onAccept={onChange}
                        onBlur={onBlur}
                        selected={value}
                        renderInput={(params) => (
                            <TextField
                                required={pickerProps.showRequired && pickerProps.required}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...params}
                                label="Enter Date"
                            />
                        )}
                        {...pickerProps}
                    />
                )}
            />
        );
    }
    return <TimePickerComponent inputRef={ref} {...pickerProps} />;
});

TimePickerWrapper.defaultProps = {
    value: null,
    format: 'hh:mm aa',
    placeholder: null,
    onChange: null,
    helperText: null,
    errors: {},
    showError: true,
    required: false,
    inputRef: null,
    inputProps: {},
    control: null,
    rules: null,
};

TimePickerWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    format: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func, // required if control not provided
    helperText: PropTypes.string,
    errors: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    inputProps: PropTypes.object,
    control: PropTypes.object,
    rules: PropTypes.object,
};

export default TimePickerWrapper;
