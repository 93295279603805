/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { format } from 'date-fns';
import noop from 'lodash/noop';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { USER_STATUS, inviteUser } from '../user';
import API from '../../api';
import { APIError, resetRequestState } from '../app';

export const REQUEST_STATUS = {
    ACTIVE: 'ACTIVE',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

const parseFetchedTesters = (testers) => {
    const parsedTesters = {};

    // transform the fetched testers data into the shape and formats we need
    testers.forEach((tester) => {
        const { id, ...testerData } = tester;
        parsedTesters[id] = {
            ...testerData,
            id,
        };
    });

    return parsedTesters;
};

const getTestersForReportsFilters = createAsyncThunk(
    'testers/getTestersForReportsFilters',
    async (onSuccess = noop) => {
        const response = await API.fetchTesters();

        const preparedData = response.map((tester) => ({
            label: tester.fullName,
            value: tester.id,
        }));
        const sortedByName = preparedData.sort((a, b) => a.label.localeCompare(b.label));

        if (onSuccess) onSuccess(sortedByName);
    },
);

const getTesters = createAsyncThunk('testers/getTesters', async (payload) => {
    const data = await API.fetchTestersList(payload);

    return data;
});

const createTester = createAsyncThunk(
    'testers/createNewTester',
    async ({ tester, onSuccess = noop, onError = noop }, thunkAPI) => {
        try {
            const payload = {
                ...tester,
                dob: format(tester.dob, 'MM-dd-yyyy'),
            };
            const { data } = await API.createTester(payload);

            onSuccess();
            return data;
        } catch (error) {
            onError();
            thunkAPI.dispatch(APIError({ text: 'Error creating tester.' }));
        }
    },
);

const updateTester = createAsyncThunk(
    'testers/updateTester',
    async ({ testerId, testerData, onSuccess = noop, onError = noop }, thunkApi) => {
        try {
            const payload = testerData;

            if (payload.dob) {
                payload.dob = format(payload.dob, 'MM-dd-yyyy');
            }

            // Set on the back-end
            delete payload.fullName;
            delete payload.age;
            delete payload.lastInvitedOn;
            delete payload.id;
            delete payload.requestStatus;

            const result = await API.updateInternalUser(testerId, payload);

            onSuccess();
            return result;
        } catch (error) {
            onError();
            thunkApi.dispatch(APIError({ text: 'Error updating tester.' }));
        }
    },
);

const activateTester = (testerId, onSuccess, onError) => async (dispatch) => {
    const patchData = {
        status: USER_STATUS.ACTIVE,
    };
    await updateTester({ testerId, testerData: patchData, onSuccess, onError })(dispatch);
};

const deactivateTester = (testerId, onSuccess, onError) => async (dispatch) => {
    const patchData = {
        status: USER_STATUS.INACTIVE,
    };
    await updateTester({ testerId, testerData: patchData, onSuccess, onError })(dispatch);
};

const deleteTester = createAsyncThunk(
    'testers/deleteTester',
    async ({ id, onSuccess = noop, onError = noop }, thunkApi) => {
        try {
            await API.deleteUser(id);
            onSuccess();
        } catch (error) {
            thunkApi.dispatch(APIError({ text: 'Error deleting user.' }));
            onError();
        }
    },
);

const initialState = {
    data: {},
    requestStatus: null,
    currentRequestId: null,
    loading: 'idle',
    errorMsg: null,
    total: 0,
};

const testersSlice = createSlice({
    name: 'testers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetRequestState, (state) => {
                state.errorMsg = initialState.errorMsg;
                state.loading = initialState.loading;
                state.currentRequestId = initialState.currentRequestId;
                state.requestStatus = initialState.requestStatus;
            })
            .addCase(getTesters.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = parseFetchedTesters(action.payload.results);
                    state.total = action.payload.total;
                }
            })
            .addCase(updateTester.fulfilled, (state, action) => {
                const {
                    requestId,
                    arg: { testerId },
                } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = {
                        ...state.data,
                        [testerId]: {
                            ...action.payload,
                            requestStatus: REQUEST_STATUS.SUCCESS,
                        },
                    };
                }
            })
            .addCase(updateTester.rejected, (state, action) => {
                const {
                    requestId,
                    arg: { testerId },
                } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = {
                        ...state.data,
                        [testerId]: {
                            ...state.data[testerId],
                            requestStatus: REQUEST_STATUS.ERROR,
                            errorMsg: action.payload.errorMsg,
                        },
                    };
                }
            })
            .addCase(deleteTester.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    const data = { ...state.data };
                    delete data[action.meta.arg.id];
                    state.data = { ...data };
                }
            })
            .addCase(createTester.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.data = {
                        ...state.data,
                        [action.payload.testerId]: {
                            ...action.payload.data,
                            status: USER_STATUS.INVITED,
                            requestStatus: REQUEST_STATUS.SUCCESS,
                        },
                    };
                }
            })
            .addCase(inviteUser.fulfilled, (state, action) => {
                if (state.data[action.payload.userId]) {
                    state = {
                        ...state,
                        data: {
                            ...state.data,
                            [action.payload.userId]: {
                                ...state.data[action.payload.userId],
                                lastInvitedOn: new Date(),
                            },
                        },
                    };
                }
            })
            .addMatcher(
                isAnyOf(updateTester.pending, deleteTester.pending, createTester.pending, getTesters.pending),
                (state, action) => {
                    if (state.loading === 'idle') {
                        state.loading = 'pending';
                        state.currentRequestId = action.meta.requestId;
                    }
                },
            )
            .addMatcher(isAnyOf(deleteTester.rejected, createTester.rejected, getTesters.rejected), (state, action) => {
                const { requestId } = action.meta;
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle';
                    state.error = action.error;
                    state.currentRequestId = undefined;
                }
            });
    },
});

const { reducer } = testersSlice;

export {
    getTestersForReportsFilters,
    getTesters,
    createTester,
    updateTester,
    deleteTester,
    activateTester,
    deactivateTester,
};

export default reducer;
