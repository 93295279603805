const styles = (theme) => ({
    sectionIcon: {
        '& svg': {
            width: 50,
            height: 46,
        },
    },
    sectionTitle: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 9,
        },
    },
    sectionDescription: {
        paddingTop: 30,

        [theme.breakpoints.down('sm')]: {
            paddingTop: 1,
            paddingBottom: 30,
        },
    },
});

export default styles;
