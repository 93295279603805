import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const CheckboxGroup = ({ labelledBy, values, onChange, inputRef, inputProps, children }) => {
    const [checkedState, setCheckedState] = useState([]);

    useEffect(() => {
        const checkedStateMap = {};
        React.Children.forEach(children, (child) => {
            checkedStateMap[child.props.value] = values ? values.includes(child.props.value) : false;
        });

        setCheckedState(checkedStateMap);
    }, [children, values]);

    const handleChange = useCallback(
        (event) => {
            const updatedState = {
                ...checkedState,
                [event.target.name]: event.target.checked,
            };
            setCheckedState(updatedState);

            const selectedInputs = Object.entries(updatedState)
                .filter(([value, isChecked]) => isChecked) // eslint-disable-line no-unused-vars
                .map(([value, isChecked]) => value); // eslint-disable-line no-unused-vars

            // send updates to the parent component
            onChange(selectedInputs);
        },
        [checkedState, setCheckedState, onChange],
    );

    return (
        <FormGroup aria-labelledby={labelledBy}>
            {React.Children.map(children, (child) => (
                <>
                    {React.createElement(child.type, {
                        ...{
                            ...child.props,
                            isChecked: checkedState[child.props.value],
                            inputRef,
                            inputProps,
                            onChange: handleChange,
                        },
                    })}
                </>
            ))}
        </FormGroup>
    );
};

CheckboxGroup.defaultProps = {
    values: [],
    inputRef: null,
    inputProps: null,
};

CheckboxGroup.propTypes = {
    values: PropTypes.array,
    labelledBy: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    inputRef: PropTypes.func,
    inputProps: PropTypes.object,
    children: PropTypes.node.isRequired,
};

const ControlledCheckboxGroup = ({
    name,
    label,
    labelledBy,
    errors,
    showError,
    required,
    control,
    rules,
    inputRef,
    disabled,
    children,
}) => {
    const isInvalid = !!errors[name];
    const describedBy = showError && isInvalid ? `${name}-error` : null;

    const elementProps = {
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        required,
    };

    return (
        <FormControl component="fieldset" error={isInvalid} fullWidth required={required} disabled={disabled}>
            {label && (
                <FormLabel component="legend" id={`${name}-label`} disabled={disabled}>
                    {label}
                </FormLabel>
            )}

            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => {
                    // we store all test/report values as strings in the db
                    // so we need to convert them to arrays here
                    let { value } = field;
                    if (typeof field.value === 'string') {
                        // comma separated string to array
                        value = field.value?.split(',');
                    }
                    return (
                        <CheckboxGroup
                            labelledBy={labelledBy || `${name}-label`}
                            values={value}
                            onChange={field.onChange}
                            inputRef={inputRef}
                            inputProps={elementProps}
                        >
                            {children}
                        </CheckboxGroup>
                    );
                }}
            />

            {showError && (
                <FormHelperText id={`${name}-error`} role="alert" aria-live="polite">
                    <ErrorMessage name={name} errors={errors} />
                </FormHelperText>
            )}
        </FormControl>
    );
};

ControlledCheckboxGroup.defaultProps = {
    label: null,
    labelledBy: null,
    errors: {},
    showError: true,
    required: false,
    rules: {},
    inputRef: null,
    disabled: false,
};

ControlledCheckboxGroup.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelledBy: (props, propName, componentName) => {
        if (!props.label && (!props[propName] || typeof props[propName] !== 'string')) {
            return new Error(`One of props: label or labelledBy supplied to ${componentName} is required.`);
        }
        return undefined;
    },
    errors: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    control: PropTypes.object.isRequired,
    rules: PropTypes.object,
    inputRef: PropTypes.func,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
};

export default ControlledCheckboxGroup;
