import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import useReportDialog from '../../utils/use-report-dialog';
import NewReportDialog from '../create-edit-report-dialog';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import Styles from './styles';

const DashboardHeader = ({ classes }) => {
    const { handleOpenDialog, dialogProps } = useReportDialog();

    return (
        <>
            <Grid item container xs={12} className={classes.wrapper}>
                <Grid item xs={3}>
                    <Typography variant="h2">My Reports</Typography>
                </Grid>
                <Grid item xs={9} container justifyContent="flex-end">
                    <Button
                        className={`${classes.primaryButton}`}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        onClick={() => handleOpenDialog()}
                    >
                        New Report
                    </Button>
                </Grid>
            </Grid>

            <NewReportDialog {...dialogProps} />
        </>
    );
};

DashboardHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(DashboardHeader, combinedStyles);
