import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { withStyles } from 'tss-react/mui';

import TablePaginationActions from '../../../components/table-pagination-actions';

import Pill from '../components/pill';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import Styles from '../styles';
import { USER_ROLES } from '../../../utils/constants';
import UserActionMenu from '../components/user-action-menu';
import { clearUserSuccessMessage, fetchInternalUsers } from '../../../redux/user';
import {
    getInternalUsers,
    getInternalUsersTotal,
    getUserId,
    getUserSuccessMessage,
} from '../../../redux/user/selectors';
import Tooltip from '../../../components/tooltip';
import getFormattedDate from '../../../utils/get-formatted-date';
import Colors from '../../../styles/colors';
import NotificationSnackbar from '../../../components/notification-snackbar';

const STATUS_MAP = {
    INVITED: { type: 'italic', text: 'Invited' },
    ACTIVE: { type: 'primary', text: 'Active' },
    INACTIVE: { type: 'secondary', text: 'Inactive' },
};

const ManageUsers = ({ classes }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('status');
    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedUser, setSelectedUser] = useState(null);
    const [tooltipAnchor, setTooltipAnchor] = useState(null);
    const userSuccessMessage = useSelector(getUserSuccessMessage);

    const currentUserId = useSelector(getUserId);
    const users = useSelector(getInternalUsers);
    const totalUsers = useSelector(getInternalUsersTotal);

    const handleUserHover = (user) => (event) => {
        setSelectedUser(user);
        setTooltipAnchor(event.currentTarget);
    };

    const handleCloseTooltip = () => {
        setSelectedUser(null);
        setTooltipAnchor(null);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const payload = {
            pageNumber: page,
            sortBy,
            sortOrder,
        };

        if (rowsPerPage > 0) {
            payload.pageSize = rowsPerPage;
        }

        dispatch(fetchInternalUsers(payload));
    }, [page, sortBy, sortOrder, rowsPerPage, dispatch]);

    const handleChangePage = useCallback(
        (event, newPage) => {
            setPage(newPage);
        },
        [setPage],
    );

    const handleChangeRowsPerPage = useCallback(
        (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        },
        [setRowsPerPage, setPage],
    );

    const handleInviteNewUser = useCallback(() => {
        navigate('/dashboard/user');
    }, [navigate]);

    const handleSort = (sortProperty) => () => {
        setSortBy(sortProperty);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            <Grid item container xs={12}>
                <Grid item xs={4}>
                    <Typography id="users-title" variant="h1" component="h2" className={classes.pageTitle}>
                        Manage Internal Users
                    </Typography>
                    <Typography id="users-count" variant="body1" className={classes.pageCount}>
                        {totalUsers} users
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            className={classes.primaryButton}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            style={{ minWidth: 'max-content' }}
                            onClick={handleInviteNewUser}
                        >
                            Invite New Internal User
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Grid item container xs={12}>
                <Paper className={classes.tableWrapper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-labelledby="users-title users-count">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell
                                        sortDirection={sortBy === 'fullName' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'fullName'}
                                            direction={sortBy === 'fullName' ? sortOrder : 'asc'}
                                            onClick={handleSort('fullName')}
                                        >
                                            Name
                                            {sortBy === 'fullName' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'role' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'role'}
                                            direction={sortBy === 'role' ? sortOrder : 'asc'}
                                            onClick={handleSort('role')}
                                        >
                                            Role
                                            {sortBy === 'role' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'status' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'status'}
                                            direction={sortBy === 'status' ? sortOrder : 'asc'}
                                            onClick={handleSort('status')}
                                        >
                                            Status
                                            {sortBy === 'status' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell
                                        sortDirection={sortBy === 'createdAt' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'createdAt'}
                                            direction={sortBy === 'createdAt' ? sortOrder : 'asc'}
                                            onClick={handleSort('createdAt')}
                                        >
                                            User Created On
                                            {sortBy === 'createdAt' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {users.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1" className={classes.userName}>
                                                {user.fullName}
                                            </Typography>
                                            <Typography variant="body1" className={classes.userEmail}>
                                                {user.email}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography
                                                variant="body1"
                                                style={{ paddingBottom: 5 }}
                                                onMouseEnter={handleUserHover(user)}
                                                onMouseLeave={handleCloseTooltip}
                                            >
                                                {USER_ROLES[user.role].label}{' '}
                                                <InfoOutlinedIcon
                                                    fontSize="small"
                                                    style={{ fontSize: 13, color: Colors.darkElectricBlue }}
                                                />
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Pill {...STATUS_MAP[user.status]} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1" style={{ paddingBottom: 5 }}>
                                                {getFormattedDate(user.createdAt)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {user.id !== currentUserId && (
                                                <div>
                                                    <UserActionMenu userId={user.id} status={user.status} />
                                                </div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalUsers > 10 && (
                        <TablePagination
                            className={classes.tableFooter}
                            component="div"
                            count={totalUsers}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            classes={{
                                root: classes.paginationContainer,
                                toolbar: classes.paginationToolbar,
                                spacer: classes.paginationSpacer,
                            }}
                        />
                    )}
                </Paper>
            </Grid>
            <Tooltip anchorEl={tooltipAnchor} arrow>
                <ul>
                    {USER_ROLES[selectedUser?.role]?.permissions?.map((permission) => (
                        <li key={permission}>{permission}</li>
                    ))}
                </ul>
            </Tooltip>
            <NotificationSnackbar
                open={!!userSuccessMessage}
                text={userSuccessMessage}
                onClose={() => dispatch(clearUserSuccessMessage())}
            />
        </Grid>
    );
};

ManageUsers.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(ManageUsers, combinedStyles);
