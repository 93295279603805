import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from 'tss-react/mui';
import styles from './styles';
import combineStyles from '../../../../../utils/combine-styles';
import buttonStyles from '../../../../../styles/buttons';
import useSnackbar from '../../../../../utils/use-snackbar';
import NotificationSnackbar from '../../../../../components/notification-snackbar';
import { deleteTester } from '../../../../../redux/testers';
import colors from '../../../../../styles/colors';

export const DeleteUserModal = ({ user, open, onClose, classes, onSuccess }) => {
    const { fullName, id } = user;
    const dispatch = useDispatch();
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();

    const handleSuccess = () => {
        onClose();
        onSuccess();
    };

    const handleDelete = () => {
        dispatch(
            deleteTester({
                id,
                onSuccess: handleSuccess,
                onError: () => {
                    handleOpenSnackbar({ text: 'An unknown error occurred. Please try again later.', type: 'error' });
                },
            }),
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="Do you want to delete this user?"
            aria-describedby={`Confirmation modal for deleting ${fullName}.`}
        >
            <Paper className={classes.modalContent}>
                <Box className={classes.modalHeader}>
                    <Button aria-label="Close Modal" onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </Box>
                <RoomOutlinedIcon style={{ fontSize: 70 }} />
                <Typography variant="h2" className={classes.modalTitle}>
                    Are you sure you want to delete this user?
                </Typography>
                <Box className={classes.siteInfo}>
                    <Typography variant="body1" component="div">
                        <strong>{fullName}</strong>
                    </Typography>
                </Box>
                <Typography style={{ color: colors.error, marginTop: '1rem' }}>
                    This will also delete all reports and attachments submitted by this user!
                </Typography>
                <Box className={classes.modalFooter}>
                    <Button onClick={onClose} className={classes.outlineButton}>
                        Cancel, keep this user
                    </Button>
                    &nbsp;
                    <Button onClick={handleDelete} className={classes.deleteButton}>
                        Yes, delete the user
                    </Button>
                </Box>
                <NotificationSnackbar {...snackbarProps} />
            </Paper>
        </Modal>
    );
};

DeleteUserModal.propTypes = {
    user: PropTypes.shape({
        fullName: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

DeleteUserModal.defaultProps = {
    open: false,
};

const combinedStyles = combineStyles(styles, buttonStyles);

export default withStyles(DeleteUserModal, combinedStyles);
