import Icon1 from './images/reports/setup-arrival';
import Icon2 from './images/reports/agent-employees';
import Icon3 from './images/reports/tour';
import Icon4 from './images/reports/rental-details';
import Icon5 from './images/reports/service-treatment';
import Icon6 from './images/reports/narrative';

export const ICON_MAP = {
    'icon-1': Icon1,
    'icon-2': Icon2,
    'icon-3': Icon3,
    'icon-4': Icon4,
    'icon-5': Icon5,
    'icon-6': Icon6,
    'icon-7': Icon6,
    'icon-8': Icon6,
    'icon-9': Icon6,
};

export const SECTION_PROGRESS = {
    NOT_STARTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    FLAGGED: 'Changes Required',
};
