import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MuiRadioGroup from '@mui/material/RadioGroup';
import { Controller } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { ErrorMessage } from '@hookform/error-message';

const RadioGroup = ({
    name,
    label,
    errors,
    showError,
    required,
    control,
    rules,
    row,
    hiddenLabel,
    children,
    disabled,
    ...otherProps // pass through any Material UI RadioGroup API props
}) => {
    const isInvalid = !!errors[name];
    const describedBy = showError && isInvalid ? `${name}-error` : null;

    const elementProps = {
        'aria-labelledby': !hiddenLabel ? `${name}-label` : null,
        'aria-label': hiddenLabel ? label : null,
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        required,
    };

    return (
        <FormControl component="fieldset" error={isInvalid} fullWidth required={required} disabled={disabled}>
            {!hiddenLabel && (
                <FormLabel component="legend" id={`${name}-label`}>
                    {label}
                </FormLabel>
            )}

            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <MuiRadioGroup row={row} {...elementProps} {...field} value={field.value || ''} {...otherProps}>
                        {React.Children.map(children, (child) => (
                            <>
                                {React.createElement(child.type, {
                                    ...{
                                        disabled,
                                        ...child.props,
                                        // this is read in the theme file
                                        // eslint-disable-next-line react/prop-types
                                        isChecked: field.value === child.props.value,
                                    },
                                })}
                            </>
                        ))}
                    </MuiRadioGroup>
                )}
            />

            {showError && (
                <FormHelperText id={`${name}-error`} role="alert" aria-live="polite">
                    <ErrorMessage name={name} errors={errors} />
                </FormHelperText>
            )}
        </FormControl>
    );
};

RadioGroup.defaultProps = {
    errors: {},
    showError: true,
    required: false,
    rules: {},
    row: false,
    hiddenLabel: false,
    disabled: false,
};

RadioGroup.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    errors: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    control: PropTypes.object.isRequired,
    rules: PropTypes.object,
    row: PropTypes.bool,
    hiddenLabel: PropTypes.bool,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
};

export default RadioGroup;
