import { useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { withStyles } from 'tss-react/mui';

import InputStyles from '../../styles/inputs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
            width: 300,
            borderRadius: '4px',
        },
    },
};

/* eslint-disable react/prop-types */
export const WrappedInput = forwardRef((props, ref) => {
    const { classes, ...otherProps } = props;
    return (
        <InputBase
            variant="outlined"
            inputRef={ref}
            classes={{
                root: classes.selectInputRoot,
                input: classes.selectInputBackground,
                focused: classes.selectInputBackgroundFocused,
                error: classes.selectInputError,
            }}
            {...otherProps}
        />
    );
});
export const StyledInput = withStyles(WrappedInput, InputStyles);
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const WrappedMenuItem = forwardRef((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { classes, children, ...otherProps } = props;
    return (
        <MenuItem
            ref={ref}
            classes={{
                root: classes.menuItemRoot,
                selected: classes.selectedMenuItem,
            }}
            {...otherProps}
        >
            <div>{children}</div>
        </MenuItem>
    );
});
export const StyledMenuItem = withStyles(WrappedMenuItem, InputStyles);
/* eslint-enable react/prop-types */

const getStyles = (option, selectedValues) => ({
    fontWeight: selectedValues.indexOf(option) === -1 ? 'normal' : 'bold',
});

const MultiSelect = ({ classes, label, defaultValue, options, onChange }) => {
    const [selectedValues, setSelectedValues] = useState([]);

    const handleChange = useCallback(
        (event) => {
            if (event.target.value.includes(defaultValue)) {
                setSelectedValues([]);
            } else {
                setSelectedValues(event.target.value);
            }
        },
        [setSelectedValues, defaultValue],
    );

    const handleClose = useCallback(() => {
        onChange(selectedValues);
    }, [onChange, selectedValues]);

    return (
        <Select
            variant="outlined"
            classes={{
                outlined: classes.selectOutlined,
                iconOutlined: classes.selectIcon,
                iconOpen: classes.selectIconOpen,
            }}
            displayEmpty
            multiple
            value={selectedValues}
            input={<StyledInput />}
            MenuProps={MenuProps}
            onChange={handleChange}
            onClose={handleClose}
            inputProps={{ 'aria-label': label }}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return defaultValue;
                }

                if (typeof options[0] === 'object') {
                    return selected.map((value) => options.find((option) => option.value === value).label).join(', ');
                }

                return selected.join(', ');
            }}
        >
            <StyledMenuItem value={defaultValue}>{defaultValue}</StyledMenuItem>
            {options.map((option) => (
                <StyledMenuItem
                    key={option.value || option}
                    value={option.value || option}
                    style={getStyles(option.value || option, selectedValues)}
                >
                    {option.label || option}
                </StyledMenuItem>
            ))}
        </Select>
    );
};

MultiSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default withStyles(MultiSelect, InputStyles);
