import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from 'tss-react/mui';
import styles from './styles';
import combineStyles from '../../../../utils/combine-styles';
import buttonStyles from '../../../../styles/buttons';
import useSnackbar from '../../../../utils/use-snackbar';
import NotificationSnackbar from '../../../../components/notification-snackbar';
import { deleteOrganization } from '../../../../redux/organizations';
// import colors from '../../../../styles/colors';

export const DeleteOrganizationsModal = ({ org, open, onClose, classes }) => {
    const { name, id } = org;
    const dispatch = useDispatch();
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();

    const handleDelete = () => {
        dispatch(
            deleteOrganization({
                id,
                onSuccess: onClose,
                onError: () => {
                    handleOpenSnackbar({ text: 'An unknown error occurred. Please try again later.', type: 'error' });
                },
            }),
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="Do you want to delete this organization?"
            aria-describedby={`Confirmation modal for deleting ${name}.`}
        >
            <Paper className={classes.modalContent}>
                <Box className={classes.modalHeader}>
                    <Button aria-label="Close Modal" onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </Box>
                <Typography variant="h2" className={classes.modalTitle}>
                    Delete Organization
                </Typography>
                <Box className={classes.siteInfo}>
                    <Typography variant="body1">
                        <strong>Name</strong>
                        <br />
                        {name}
                    </Typography>
                </Box>
                <Typography style={{ marginTop: '1rem' }}>
                    Are you sure you want to delete this organization? All users, testers, and reports will also be deleted.
                </Typography>
                <Typography style={{ fontWeight: 700, marginTop: '1rem' }}>
                    This cannot be undone.
                </Typography>
                <Box className={classes.modalFooter}>
                    <Button onClick={onClose} className={classes.outlineButton}>
                        Cancel
                    </Button>
                    &nbsp;
                    <Button onClick={handleDelete} className={classes.deleteButton}>
                        Delete
                    </Button>
                </Box>
                <NotificationSnackbar {...snackbarProps} />
            </Paper>
        </Modal>
    );
};

DeleteOrganizationsModal.propTypes = {
    org: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

DeleteOrganizationsModal.defaultProps = {
    open: false,
};

const combinedStyles = combineStyles(styles, buttonStyles);

export default withStyles(DeleteOrganizationsModal, combinedStyles);
