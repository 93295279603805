/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
    apiStatus: {
        error: false,
        errorMsg: null,
        completed: false,
        successMsg: null,
        autoHideDisabled: null,
    },
};

export const resetRequestState = createAction('resetRequestState');

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        resetRequestState: (state) => {
            state.error = initialState.error;
            state.errorMsg = initialState.errorMsg;
            state.successMsg = initialState.errorMsg;
            state.loading = initialState.loading;
        },
        APIError: (state, action) => {
            state.apiStatus = {
                error: true,
                errorMsg: action.payload?.text,
                autoHideDisabled: action.payload?.autoHideDisabled,
                completed: true,
                successMsg: null,
            };
        },
        APISuccess: (state, action) => {
            state.apiStatus = {
                error: false,
                errorMsg: null,
                autoHideDisabled: action.payload?.autoHideDisabled,
                completed: true,
                successMsg: action.payload?.text,
            };
        },
        APIReset: (state) => {
            state.apiStatus = initialState.apiStatus;
        },
    },
});

const { reducer, actions } = appSlice;

export const { APIError, APISuccess, APIReset } = actions;

export default reducer;
