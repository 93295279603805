import CssBaseline from '@mui/material/CssBaseline';
import { ThemeContextProvider } from './theme-context';
import ThemeWrapper from './theme-wrapper';

function withRoot(Component, theme) {
    const WithRoot = (props) => (
        <ThemeContextProvider>
            <ThemeWrapper theme={theme}>
                <CssBaseline />
                <Component {...props} />
            </ThemeWrapper>
        </ThemeContextProvider>
    );

    return WithRoot;
}

export default withRoot;
