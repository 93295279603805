import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const ProgressSpinner = ({ id, isLoading, ariaLabel, ariaLabelledBy, valueText }) => (
    <Backdrop sx={{ zIndex: 99999 }} open={isLoading}>
        <CircularProgress id={id} aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} aria-valuetext={valueText} />
    </Backdrop>
);

ProgressSpinner.defaultProps = {
    ariaLabel: null,
    ariaLabelledBy: null,
    valueText: null,
};

ProgressSpinner.propTypes = {
    id: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    ariaLabel: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    valueText: PropTypes.string,
};

export default ProgressSpinner;
