import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ActionMenu from '../../../../components/action-menu';
import { activateTester, deactivateTester } from '../../../../redux/testers';
import { USER_STATUS, inviteUser } from '../../../../redux/user';

const TesterActionMenu = ({ orgId, user, status, onDelete, refetchOrgData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleResendInvite = useCallback(() => {
        dispatch(inviteUser({ id: user.id }));
    }, [dispatch, user.id]);

    const handleEditTester = useCallback(() => {
        navigate(`/dashboard/organizations/${orgId}/edit-admin/${user.id}`);
    }, [navigate, user.id]);

    const handleActivateTester = useCallback(() => {
        const onSuccess = () => {
            refetchOrgData();
        };
        dispatch(activateTester(user.id, onSuccess));
    }, [dispatch, user.id]);

    const handleDeactivateTester = useCallback(() => {
        const onSuccess = () => {
            refetchOrgData();
        };
        dispatch(deactivateTester(user.id, onSuccess));
    }, [dispatch, user.id]);

    const handleDelete = useCallback(() => {
        onDelete({ ...user, id: user.id });
    }, [user, onDelete, user.id]);

    const actions = [{ label: 'Edit', onClick: handleEditTester }];

    if (status === USER_STATUS.INVITED) {
        actions.push({ label: 'Resend Invitation', onClick: handleResendInvite });
    }

    actions.push({ label: 'Delete', onClick: handleDelete });

    if (status === 'INACTIVE') {
        actions.push({ label: 'Make Active', onClick: handleActivateTester });
    } else {
        actions.push({ label: 'Make Inactive', onClick: handleDeactivateTester });
    }

    const defaultAction =
        status === USER_STATUS.INVITED
            ? {
                  label: 'Resend Invitation',
                  onClick: handleResendInvite,
              }
            : {
                  label: 'Edit',
                  onClick: handleEditTester,
              };

    return <ActionMenu id={`${user.id}-actions`} defaultAction={defaultAction} actions={actions} />;
};

TesterActionMenu.propTypes = {
    refetchOrgData: PropTypes.func.isRequired,
    orgId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default TesterActionMenu;
