export default (renderLogic, parentFieldValue) => {
    const { condition, value } = renderLogic;

    switch (condition) {
        case 'isEmpty':
            return parentFieldValue === null || parentFieldValue === undefined || parentFieldValue === '';
        case 'isNotEmpty':
            return parentFieldValue !== null && parentFieldValue !== undefined && parentFieldValue !== '';
        case 'notEquals':
            return parentFieldValue !== value && parentFieldValue !== undefined && parentFieldValue !== null;
        case 'equals':
            return parentFieldValue === value && parentFieldValue !== undefined && parentFieldValue !== null;
        case 'includes':
            if (Array.isArray(parentFieldValue)) {
                return parentFieldValue.some((v) => value.includes(v));
            }
            return value.includes(parentFieldValue);
        case 'excludes':
            if (Array.isArray(parentFieldValue)) {
                return parentFieldValue.every((v) => !value.includes(v));
            }
            return !value.includes(parentFieldValue);
        default:
            // default to true if no condition is provided
            // this means there is no conditional logic
            // however, this function is typically only called on L2 and L3 questions
            // so if there is no condition, then it's parent is likely a repeatingGroup
            return true;
    }
};
