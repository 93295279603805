import Colors from '../../../../styles/colors';

const styles = () => ({
    backLink: {
        paddingBottom: 30,

        '& a': {
            color: Colors.persianBlue,
            fontSize: '0.875rem',
            fontWeight: 'bold',
            textDecoration: 'none',
        },

        '& a:hover': {
            textDecoration: 'underline',
        },
    },
    icon: {
        paddingBottom: 20,

        '& img': {
            width: 140,
            height: 120,
        },
    },
    description: {
        paddingTop: 10,
    },
    note: {
        width: 220,

        '& p': {
            fontSize: '0.875rem',
        },
    },
    siteInfo: {
        background: Colors.lavenderGray,
        padding: '1rem',
        borderRadius: '6px',
        textAlign: 'left',
    },
});

export default styles;
