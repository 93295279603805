import Colors from '../../../styles/colors';

const styles = (hideContainer, noBorder) => ({
    paddingLeft: hideContainer ? 'inherit' : '30px',
    paddingBottom: hideContainer ? 'inherit' : '20px',
    borderBottom: hideContainer ? 'inherit' : `1px solid ${Colors.lavenderGray}`,
    border: noBorder ? 0 : '',
});

export default styles;
