import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';

import TextInput from '../../../components/inputs/textinput';
import Select from '../../../components/inputs/select';
import Styles from './styles';
import DashboardStyles from '../styles';
import { USER_ROLES } from '../../../utils/constants';
import { createInternalUser, updateInternalUser } from '../../../redux/user';
import { getInternalUser } from '../../../redux/user/selectors';
import validationSchema from '../../../validation/validation-schema-internal-user';
import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import InputStyles from '../../../styles/inputs';
import useSnackbar from '../../../utils/use-snackbar';
import NotificationSnackbar from '../../../components/notification-snackbar';

const InviteEditUser = ({ classes }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();
    const { userId } = useParams();
    const getUserById = useSelector(getInternalUser);
    const user = useMemo(() => getUserById(Number(userId)), [getUserById, userId]);
    const isEditing = !!user;

    const { control, register, handleSubmit, reset, watch, formState } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            role: user?.role || USER_ROLES.attorney.value,
        },
    });

    const { errors } = formState;

    const selectedRole = watch('role');

    const onSubmit = (data) => {
        const onError = () => {
            handleOpenSnackbar({ text: 'An unknown issue occurred.  Please try again later.', type: 'error' });
        };

        if (isEditing) {
            const onSuccess = () => {
                navigate('/dashboard/users');
            };
            dispatch(
                updateInternalUser({
                    userId,
                    data,
                    onSuccess,
                    onError,
                }),
            );
        } else {
            const onSuccess = () => {
                reset(
                    {
                        firstName: '',
                        lastName: '',
                        email: '',
                        role: USER_ROLES.attorney.value,
                    },
                    { submitCount: true },
                );
                handleOpenSnackbar({ text: 'Your invitation has been sent!', type: 'success' });
            };
            dispatch(createInternalUser({ data, onSuccess, onError }));
        }
    };

    const determineRenderValue = (value) => {
        if (value === USER_ROLES.attorney.value) {
            return USER_ROLES.attorney.label;
        }
        if (value === USER_ROLES.orgAdmin.value) {
            return USER_ROLES.orgAdmin.label;
        }
        if (value === USER_ROLES.testCoordinator.value) {
            return USER_ROLES.testCoordinator.label;
        }
        return '';
    };

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            <Grid item xs={12} className={classes.backLink}>
                <Link to="/dashboard/users">Back to Manage Internal Users</Link>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h1">{isEditing ? 'Edit ' : 'Invite New '} Internal User</Typography>
            </Grid>
            <Grid item xs={12} className={classes.description}>
                <Typography variant="body1">All questions are required</Typography>
            </Grid>

            <Grid item container xs={4}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid item container spacing={2}>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <TextInput
                                    name="firstName"
                                    label="First Name"
                                    errors={errors}
                                    required
                                    register={register}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    name="lastName"
                                    label="Last Name"
                                    errors={errors}
                                    required
                                    register={register}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.inputWrapper}>
                            <TextInput
                                name="email"
                                label="Email"
                                errors={errors}
                                required
                                register={register}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <div className={classes.inputWrapper}>
                                <Select
                                    name="role"
                                    label="Role"
                                    errors={errors}
                                    required
                                    control={control}
                                    displayEmpty
                                    renderValue={(value) => determineRenderValue(value)}
                                >
                                    <MenuItem value={USER_ROLES.attorney.value}>{USER_ROLES.attorney.label}</MenuItem>
                                    <MenuItem value={USER_ROLES.orgAdmin.value}>{USER_ROLES.orgAdmin.label}</MenuItem>
                                    <MenuItem value={USER_ROLES.testCoordinator.value}>
                                        {USER_ROLES.testCoordinator.label}
                                    </MenuItem>
                                </Select>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            {selectedRole && (
                                <Box>
                                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                                        {USER_ROLES[selectedRole].pluralLabel} can:
                                    </Typography>
                                    <ul>
                                        {USER_ROLES[selectedRole].permissions.map((permission) => (
                                            <li key={permission}>{permission}</li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                className={classes.primaryButton}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                aria-label={isEditing ? 'Save updates to user' : null}
                                type="submit"
                            >
                                {isEditing ? 'Update' : 'Send Invitation'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <NotificationSnackbar {...snackbarProps} />
        </Grid>
    );
};

InviteEditUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(DashboardStyles, ButtonStyles, InputStyles, Styles);
export default withStyles(InviteEditUser, combinedStyles);
