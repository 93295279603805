import PropTypes from 'prop-types';
import TextMask from '../text-mask';

const ALPHANUMERIC_REGEX = /[A-Za-z0-9]/;

const TextMaskAlphanumeric = ({ name, control, ...otherProps }) => (
    <TextMask
        name={name}
        control={control}
        mask={ALPHANUMERIC_REGEX}
        inputProps={{
            maxLength: 10,
        }}
        {...otherProps}
    />
);

TextMaskAlphanumeric.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
};

export default TextMaskAlphanumeric;
