import { useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import { NavLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

import { logout } from '../../../../redux/auth';
import LogoImg from '../../../../images/logo_on_blue_bg_mobile.png';
import LSCLogo from '../../../../images/LSC Logo-White-Horizontal.png';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import Styles from './styles';
import { ADMINISTRATIVE_ROLES, USER_ROLES } from '../../../../utils/constants';
import { UserRolesPropType } from '../../../../utils/prop-types';
import { getUserOrganization } from '../../../../redux/auth/selectors';

const LinkComponent = forwardRef((props, ref) => {
    const { route, ...rest } = props;
    return <NavLink to={`/dashboard/${route}`} {...ref} {...rest} />;
});

LinkComponent.propTypes = {
    route: PropTypes.string.isRequired,
};

const MobileNav = ({ classes, role }) => {
    const dispatch = useDispatch();
    const userOrganization = useSelector(getUserOrganization);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const showAdmin = ADMINISTRATIVE_ROLES.includes(role);
    const canEdit = role === USER_ROLES.admin.value || role === USER_ROLES.testCoordinator.value;

    return (
        <>
            <Drawer
                elevation={0}
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                style={{ top: 60 }}
                classes={{
                    root: classes.hideDrawer,
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    BackdropProps: {
                        classes: {
                            root: classes.drawerModalRoot,
                        },
                    },
                }}
            >
                <nav>
                    {showAdmin ? (
                        <>
                            <Button
                                component={LinkComponent}
                                route="reports"
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                Reports
                            </Button>
                            <Button
                                component={LinkComponent}
                                route="testers"
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                {canEdit ? 'Manage Testers' : 'Testers'}
                            </Button>
                            {(role === USER_ROLES.admin.value || role === USER_ROLES.orgAdmin.value) && (
                                <Button
                                    component={LinkComponent}
                                    route="users"
                                    className={classes.navButton}
                                    fullWidth
                                    role="link"
                                >
                                    Manage Internal Users
                                </Button>
                            )}
                            {role === USER_ROLES.admin.value && (
                                <Button
                                    component={LinkComponent}
                                    route="organizations"
                                    className={classes.navButton}
                                    fullWidth
                                    role="link"
                                >
                                    Manage Organizations
                                </Button>
                            )}
                            {(role === USER_ROLES.admin.value || role === USER_ROLES.orgAdmin.value) && (
                                <Button
                                    component={LinkComponent}
                                    route="test-builder"
                                    className={classes.navButton}
                                    fullWidth
                                    role="link"
                                >
                                    Test Builder
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            <Button
                                component={LinkComponent}
                                route="reports"
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                My Reports
                            </Button>
                            <Button
                                component={LinkComponent}
                                route="training"
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                Training Videos
                            </Button>
                        </>
                    )}

                    <Button onClick={() => dispatch(logout())} className={classes.navButton} fullWidth role="link">
                        Log Out
                    </Button>
                </nav>
            </Drawer>
            <Grid item container className={classes.wrapper} spacing={0}>
                <h1 className="offscreen">{userOrganization?.name || ''}</h1>
                <Grid item container xs={5} className={classes.imgContainer}>
                    {userOrganization?.logo && (
                        <img className={classes.logo} src={userOrganization.logo} alt="" />
                    )}
                    {!userOrganization?.logo && (
                        <img className={classes.logo} src={LogoImg} alt="" />
                    )}
                </Grid>
                <Grid item container xs={6} className={classes.imgContainer}>
                    <img className={classes.lscLogo} src={LSCLogo} alt="" />
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        color="inherit"
                        aria-label={drawerOpen ? 'Close Navigation' : 'Open Navigation'}
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        className={classes.drawerIcon}
                    >
                        {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};

MobileNav.propTypes = {
    classes: PropTypes.object.isRequired,
    role: UserRolesPropType.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(MobileNav, combinedStyles);
