const styles = (theme) => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'start',
        paddingTop: 38,

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },

        '& button': {
            marginRight: 16,
        },
    },
});

export default styles;
