const styles = () => ({
    detailsWrapper: {
        backgroundColor: '#d8e0e5',
        borderRadius: 6,
        minHeight: 65,
        alignItems: 'center',
    },
    detailTitle: {
        fontSize: '0.8125rem',
        fontWeight: 800,
    },
    detailContent: {
        fontSize: '1.0625rem',
    },
    list: {
        fontWeight: 700,
    },
    listItem: {
        fontWeight: 'inherit',
        padding: 10,
    },
});

export default styles;
