import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import { fetchSingleOrganization } from '../../../../api';

import getFormattedDate from '../../../../utils/get-formatted-date';
import OrgAdminActionMenu from '../org-admin-action-menu';

import Pill from '../../components/pill';
import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import InputStyles from '../../../../styles/inputs';
import Styles from '../../styles';
import { clearUserSuccessMessage } from '../../../../redux/user';
import NotificationSnackbar from '../../../../components/notification-snackbar';
import { getUserSuccessMessage } from '../../../../redux/user/selectors';
import getStatusLabel from '../../utils/get-status-label';
import DeleteUserModal from './delete-user-modal';

const ViewOrganizationAdmins = ({ classes }) => {
    const { orgId } = useParams();
    const orgSuccessMessage = useSelector(getUserSuccessMessage);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedUser, setSelectedUser] = useState(null);
    const [orgData, setOrgData] = useState(null);
    const [orgAdmins, setOrgAdmins] = useState([]);

    const fetchData = async () => {
        const data = await dispatch(fetchSingleOrganization(orgId));
        if (data) {
            const { users } = data;
            setOrgData(data);

            // filter out only the orgAdmins from the users
           const admins = users.filter((user) => user.role === 'orgAdmin');
           setOrgAdmins(admins);
        }
    };

    useEffect(() => {
         if (orgId) {
             fetchData();
         }
     }, [orgId]);

    const handleCreateAdmin = () => {
        navigate(`/dashboard/organizations/${orgId}/invite-admin`);
    };
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleDelete = (tester) => {
        setOpenDeleteModal(true);
        setSelectedUser(tester);
    };

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            {selectedUser && (
                <DeleteUserModal
                    user={selectedUser}
                    onClose={() => setOpenDeleteModal(false)}
                    open={openDeleteModal}
                    onSuccess={fetchData}
                />
            )}
            <NotificationSnackbar
                open={!!orgSuccessMessage}
                text={orgSuccessMessage}
                onClose={() => dispatch(clearUserSuccessMessage())}
            />
            <Grid item container xs={12}>
                <Grid item xs={12} className={classes.backLink}>
                    <Link to="/dashboard/organizations">Back to Organizations</Link>
                </Grid>
                <Grid item xs={6}>
                    <Typography id="testers-title" variant="h1" component="h2" className={classes.pageTitle}>
                        {orgData?.name}
                    </Typography>
                    <Typography id="testers-count" variant="body1" className={classes.pageCount}>
                        {orgData?.adminCount} Admins
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            className={classes.primaryButton}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            style={{ minWidth: 'max-content' }}
                            onClick={handleCreateAdmin}
                        >
                            Add New Admin
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Grid item container xs={12}>
                <Paper className={classes.tableWrapper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-labelledby="testers-title testers-count">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        Name
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Status
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        User Created On
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>User Created By</TableCell>
                                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {orgAdmins.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1" className={classes.testerName}>
                                                {user.fullName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Pill {...getStatusLabel(user)} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1">
                                                {getFormattedDate(user.createdAt)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1">
                                                {user.createdBy?.fullName || '--'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div>
                                                <OrgAdminActionMenu
                                                    orgId={orgId}
                                                    user={user}
                                                    onDelete={handleDelete}
                                                    status={user.status}
                                                    refetchOrgData={() => {
                                                        dispatch(fetchData);
                                                    }}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
};

ViewOrganizationAdmins.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(ViewOrganizationAdmins, combinedStyles);
