import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { getInProgressReports, getCompletedReports, getReopenedReports } from '../../redux/reports/selectors';
import { getReportsForTester } from '../../redux/reports';
import { setCurrentReportId } from '../../redux/current-report';
import getFormattedDate from '../../utils/get-formatted-date';

import PropertyBlock from './components/property-block';
import DashboardHeader from './components/header';
import DeleteReportDialog from './components/delete-report-dialog';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';
import useReportDialog from './utils/use-report-dialog';
import { getUserName } from '../../redux/user/selectors';
import { getCurrentUser } from '../../redux/auth/selectors';

const TesterDashboard = ({ classes }) => {
    const [selectedTab, setSelectedTab] = useState('inprogress');
    const currentUser = useSelector(getCurrentUser);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleOpenDialog, dialogProps } = useReportDialog();

    const inProgressReports = useSelector(getInProgressReports);
    const inProgressCount = Object.keys(inProgressReports).length;
    const completedReports = useSelector(getCompletedReports);
    const completedCount = Object.keys(completedReports).length;
    const reopenedReports = useSelector(getReopenedReports);
    const reopenedCount = Object.keys(reopenedReports).length;
    const testerName = useSelector(getUserName);

    useEffect(() => {
        dispatch(getReportsForTester(currentUser.id));
    }, [currentUser]);

    const handleEditReport = useCallback(
        (reportId) => {
            const intReportId = Number(reportId);
            dispatch(setCurrentReportId(intReportId));
            navigate('/report');
        },
        [dispatch, navigate],
    );

    const handleChange = useCallback(
        (event, newValue) => {
            setSelectedTab(newValue);
        },
        [setSelectedTab],
    );

    return (
        <div className={classes.wrapper}>
            <DashboardHeader />
            <DeleteReportDialog {...dialogProps} />

            <TabContext value={selectedTab}>
                <TabList
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="My Reports Selector"
                    classes={{
                        indicator: classes.tabsIndicator,
                    }}
                >
                    <Tab
                        label={`In Progress ${inProgressCount}`}
                        disabled={inProgressCount === 0}
                        classes={{
                            root: classes.tabRoot,
                            selected: classes.tabSelected,
                        }}
                        value="inprogress"
                    />
                    <Tab
                        label={`Reopened ${reopenedCount}`}
                        disabled={reopenedCount === 0}
                        classes={{
                            root: classes.tabRoot,
                            selected: classes.tabSelected,
                        }}
                        value="reopened"
                    />
                    <Tab
                        label={`Completed ${completedCount}`}
                        disabled={completedCount === 0}
                        classes={{
                            root: classes.tabRoot,
                            selected: classes.tabSelected,
                        }}
                        value="completed"
                    />
                </TabList>
                <hr className={classes.greyHr} />

                <TabPanel value="inprogress">
                    <Grid item spacing={2} container xs={12}>
                        {Object.entries(inProgressReports).map(([reportId, report]) => (
                            <Grid item xs={4} key={reportId}>
                                <PropertyBlock
                                    onEditClick={() => handleEditReport(reportId)}
                                    progress="inprogress"
                                    title={`${report.percentComplete}% complete`}
                                    property={report.testSite.siteName}
                                    address={report.testSite.address}
                                    visitDate={getFormattedDate(report.testDate)}
                                    onDeleteClick={() =>
                                        handleOpenDialog({
                                            id: reportId,
                                            siteName: report.testSite.siteName,
                                            tester: testerName,
                                            testDate: getFormattedDate(report.testDate),
                                        })
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="reopened">
                    <Grid item spacing={2} container xs={12}>
                        {Object.entries(reopenedReports).map(([reportId, report]) => (
                            <Grid item xs={4} key={reportId}>
                                <PropertyBlock
                                    onEditClick={() => handleEditReport(reportId)}
                                    progress="reopened"
                                    title="Reopened"
                                    property={report.testSite.siteName}
                                    address={report.testSite.address}
                                    visitDate={getFormattedDate(report.testDate)}
                                    onDeleteClick={() =>
                                        handleOpenDialog({
                                            id: reportId,
                                            siteName: report.testSite.siteName,
                                            tester: testerName,
                                            testDate: getFormattedDate(report.testDate),
                                        })
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="completed">
                    <Grid item spacing={2} container xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                To edit a completed report, please contact your test coordinator.
                            </Typography>
                        </Grid>
                        {Object.entries(completedReports).map(([reportId, report]) => (
                            <Grid item xs={4} key={reportId}>
                                <PropertyBlock
                                    progress="completed"
                                    title={`Completed on ${getFormattedDate(report.submittedDate)}`}
                                    property={report.testSite.siteName}
                                    address={report.testSite.address}
                                    visitDate={getFormattedDate(report.testDate)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>
            </TabContext>
        </div>
    );
};
TesterDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(TesterDashboard, combinedStyles);
